var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.controller.carregando)?_c('div',[_c('div',{staticClass:"d-flex flex-column justify-center align-center font-weight-bold headline",staticStyle:{"height":"calc(100vh - 100px)"}},[_c('div',[_vm._v("Buscando informações, isso pode demorar um pouco...")]),_c('dev-lottie',{staticClass:"mt-10",attrs:{"options":_vm.controller.animacao,"height":300,"width":400}})],1)]):_c('div',[_c('v-row',{staticClass:"d-flex justify-center align-center"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Filtrar por:","items":_vm.controller.opcoesFiltro,"outlined":"","dense":""},on:{"change":function($event){return _vm.controller.filtrarItens()}},model:{value:(_vm.controller.filtroSelecionado),callback:function ($$v) {_vm.$set(_vm.controller, "filtroSelecionado", $$v)},expression:"controller.filtroSelecionado"}})],1),(_vm.controller.filtroSelecionado === 'clientes')?_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"label":"Selecionar Cliente","items":_vm.controller.clientes.itens,"item-text":"nome","item-value":"id","outlined":"","dense":""},on:{"change":function($event){return _vm.controller.filtrarItens()}},model:{value:(_vm.controller.clienteSelecionado),callback:function ($$v) {_vm.$set(_vm.controller, "clienteSelecionado", $$v)},expression:"controller.clienteSelecionado"}})],1):_vm._e(),(_vm.controller.filtroSelecionado === 'aplicativos')?_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"label":"Selecionar Aplicativo","items":_vm.controller.projetos.itens,"item-text":"nome","item-value":"id","outlined":"","dense":""},on:{"change":function($event){return _vm.controller.filtrarItens()}},model:{value:(_vm.controller.aplicativoSelecionado),callback:function ($$v) {_vm.$set(_vm.controller, "aplicativoSelecionado", $$v)},expression:"controller.aplicativoSelecionado"}})],1):_vm._e()],1),(
      _vm.controller.filtroSelecionado === 'clientes' &&
      _vm.controller.clienteSelecionado
    )?_c('dev-tabela',{attrs:{"mostra-paginacao":false,"nome-tabela":"Aplicativos por Cliente","colunas":_vm.controller.colunasClientes,"linhas":_vm.controller.itens,"carregar":_vm.controller.carregando,"deletar":false,"alterar":false,"altura":"calc(100vh - 240px)"},scopedSlots:_vm._u([{key:"ativo",fn:function({ item }){return [_c('v-chip',{attrs:{"color":item.ativo === true ? 'green' : 'red',"small":""}},[_vm._v(" "+_vm._s(item.ativo === true ? "Sim" : "Não")+" ")])]}}],null,false,1218761809)}):_vm._e(),(
      _vm.controller.filtroSelecionado === 'aplicativos' &&
      _vm.controller.aplicativoSelecionado
    )?_c('dev-tabela',{attrs:{"mostra-paginacao":false,"nome-tabela":"Clientes por Aplicativo","colunas":_vm.controller.colunasAplicativos,"linhas":_vm.controller.itens,"carregar":_vm.controller.carregando,"deletar":false,"alterar":false,"altura":"calc(100vh - 240px)"},scopedSlots:_vm._u([{key:"ativo",fn:function({ item }){return [_c('v-chip',{attrs:{"color":item.ativo === true ? 'green' : 'red',"small":""}},[_vm._v(" "+_vm._s(item.ativo === true ? "Sim" : "Não")+" ")])]}}],null,false,1218761809)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }