import Handler from "@/core/domain/usecase/handler"

const excluiCertificadoDigitalRepository = (axios) => async (id) => {
    try {
        await axios.delete(`/certificado-digital/${id}`)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default excluiCertificadoDigitalRepository