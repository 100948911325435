import { defaultPaginacao } from "@/core/domain/model/paginacao";
import { defaultUsuario } from "../domain/model/usuario";
import { colunas } from "../const/colunas";
import _ from 'lodash'
import { snackbar } from "@/core/const/devSnackbar";
import { alert } from "@/core/const/devAlert";

class UsuarioController {
  colunas = colunas
  dialog = false
  exibirSenha = false
  usuariosPaginacao = defaultPaginacao()
  grupoUsuarioPaginacao = defaultPaginacao()
  usuario = defaultUsuario()
  paginacao = {}
  carregando = false


  constructor(
    context,
    buscaUsuariosUseCase,
    buscaGrupoUsuarioUseCase,
    criaUsuarioUseCase,
    alteraUsuarioUseCase,
    excluiUsuarioUseCase,
  ) {
    this.context = context
    this.buscaUsuariosUseCase = buscaUsuariosUseCase
    this.buscaGrupoUsuarioUseCase = buscaGrupoUsuarioUseCase
    this.criaUsuarioUseCase = criaUsuarioUseCase
    this.alteraUsuarioUseCase = alteraUsuarioUseCase
    this.excluiUsuarioUseCase = excluiUsuarioUseCase
  }

  async mounted(paginacao) {
    try {
      this.carregando = true
      this.paginacao = paginacao
      this.usuariosPaginacao = await this.buscaUsuariosUseCase({ ...paginacao })
    } finally {
      this.carregando = false
    }
  }

  async atualizar() {
    try {
      this.carregando = true;
      this.usuariosPaginacao = await this.buscaUsuariosUseCase(this.paginacao);
    } finally {
      this.carregando = false
    }
  }

  async adicionarRegistro() {
    try {
      await Promise.all([this.buscaGrupoUsuario(null)])
      this.dialog = true
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  async alterarRegistro(item) {
    try {
      await Promise.all([this.buscaGrupoUsuario(null)])
      this.usuario = { ...item }
      this.dialog = true
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  cancelar() {
    this.usuario = defaultUsuario()
    this.context.$refs.form.resetValidation()
    this.dialog = false
  }

  buscaGrupoUsuario = _.debounce(async (search) => {
    this.grupoUsuarioPaginacao = await this.buscaGrupoUsuarioUseCase({ itemsPerPage: 10, page: 1, search: search, sortBy: "nome", searchFields: "nome" })
  }, 500)

  async salvar() {
    try {
      if (this.context.$refs.form.validate()) {
        if (this.usuario.id === 0) {
          await this.criaUsuarioUseCase(this.usuario)
        }

        if (this.usuario.id > 0) {
          await this.alteraUsuarioUseCase(this.usuario)
        }

        snackbar.show({
          message: "Registro salvo com sucesso!",
          color: 'green',
          timeout: 30000
        })

        this.cancelar()
        this.mounted(this.paginacao)
      }
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  async excluirRegistro(item) {
    try {

      const deletar = await alert.confirm({
        message: "Deseja realmente excluir o registro?",
        color: 'red'
      })

      if (deletar) {
        await this.excluiUsuarioUseCase(item.id)
      }

      this.mounted(this.paginacao)
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

}

export default UsuarioController