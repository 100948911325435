import Paginacao from "@/core/domain/model/paginacao";
import Handler from "@/core/domain/usecase/handler";
import LogsDesktop from "../../domain/model/logsDesktop";

const buscaLogsDesktopRepository = (axios) => async (paginacao) => {
  try {
    const response = await axios.get('/desktop-log', {
      params: paginacao
    })

    return new Paginacao(response.data, LogsDesktop)
  } catch (error) {
    throw new Handler().trataErro(error)
  }
}

export default buscaLogsDesktopRepository