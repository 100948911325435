import BancoDadosController from "../controller/bancoDadosController";
import { axiosInstance } from "@/plugins/axios";

import buscaBancosDadosRepository from "../data/repository/buscaBancosDadosRepository";
import buscaBancosDadosUseCase from "../domain/usecase/buscaBancosDadosUseCase";

import criaBancoDadosRepository from "../data/repository/criaBancoDadosRepository";
import criaBancoDadosUseCase from "../domain/usecase/criaBancoDadosUseCase";

import alteraBancoDadosRepository from "../data/repository/alteraBancoDadosRepository";
import alteraBancoDadosUseCase from "../domain/usecase/alteraBancoDadosUseCase";

import deletaBancoDadosRepository from "../data/repository/deletaBancoDadosRepository";
import deletaBancoDadosUseCase from "../domain/usecase/deletaBancoDadosUseCase";

const instance = axiosInstance;

const buscaBancosDadosRepositoryImpl = buscaBancosDadosRepository(instance);
const buscaBancosDadosUseCaseImpl = buscaBancosDadosUseCase(
  buscaBancosDadosRepositoryImpl
);

const criaBancoDadosRepositoryImpl = criaBancoDadosRepository(instance);
const criaBancoDadosUseCaseImpl = criaBancoDadosUseCase(
  criaBancoDadosRepositoryImpl
);

const alteraBancoDadosRepositoryImpl = alteraBancoDadosRepository(instance);
const alteraBancoDadosUseCaseImpl = alteraBancoDadosUseCase(
  alteraBancoDadosRepositoryImpl
);

const deletaBancoDadosRepositoryImpl = deletaBancoDadosRepository(instance);
const deletaBancoDadosUseCaseImpl = deletaBancoDadosUseCase(
  deletaBancoDadosRepositoryImpl
);

export const bancoDadosController = (context) =>
  new BancoDadosController(
    context,
    buscaBancosDadosUseCaseImpl,
    criaBancoDadosUseCaseImpl,
    alteraBancoDadosUseCaseImpl,
    deletaBancoDadosUseCaseImpl
  );
