import { getUser, clearUserToken } from "../model/jwtAuthToken"

const alterarSenhaUseCase = (repository) => async (senha) => {
  try {
    const usuario = getUser()
    usuario['senha'] = senha

    await repository(usuario)
    clearUserToken()
  } catch (error) {
    throw error
  }
}

export default alterarSenhaUseCase