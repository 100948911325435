import { snackbar } from "@/core/const/devSnackbar";
import { formatBytes } from "@/core/const/formatByteToMB";

class DashboardController {
  buckets = ["sqlpostgresbkp", "reportsbkp"]
  postgres = {}
  report = {}
  loading = true
  
  constructor(context, buscaInfoBackupsUseCase) {
    this.context = context
    this.buscaInfoBackupsUseCase = buscaInfoBackupsUseCase
  }

  async mounted() {
    try {
      this.loading = true
      const [postgres, report] = await Promise.all(this.buckets.map((bucket) => this.buscaInfoBackupsUseCase(bucket)))
      this.postgres = postgres
      this.postgres.tamanho = formatBytes(this.postgres.tamanho)
      this.report = report
      this.report.tamanho = formatBytes(this.report.tamanho)
    } catch (error) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }
}

export default DashboardController;