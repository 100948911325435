import Handler from "@/core/domain/usecase/handler"

const buscaInfoBackupsRepository = (axios) => async (bucket) => {
    try {
        const response = await axios.get(`/storage/${bucket}/info`)
        return response.data
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default buscaInfoBackupsRepository