<template>
  <div>
    <dev-tabela
      nome-tabela="Bancos de Dados"
      mostra-pesquisa
      mostra-acoes
      alterar
      deletar
      :linhas="controller.linhas"
      :colunas="controller.colunas"
      :total-itens="controller.total"
      :carregar="controller.carregando"
      @paginando="controller.paginando($event)"
      @deletar-item="controller.deletar($event)"
      @alterar-item="controller.alterar($event)"
    >
      <template v-slot:botoes>
        <v-row>
          <v-col>
            <div class="mr-2">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="controller.carregando"
                    max-height="36"
                    max-width="36"
                    v-on="on"
                    v-bind="attrs"
                    icon
                  >
                    <v-icon @click="controller.paginando()">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Recarregar</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col>
            <dev-btn
              incluir
              :desabilita-incluir="controller.carregando"
              @click="controller.incluir()"
            />
          </v-col>
        </v-row>
      </template>
    </dev-tabela>
  </div>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
