<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" lg="4">
        <v-card class="rounded-xl" elevation="0">
          <v-card-title>
            Menu geral consultors
            <v-spacer></v-spacer>
            <dev-btn
              salvar
              v-if="
                hadPermission(clientePermissionId, createActionId) &&
                hadPermission(clientePermissionId, alterActionId)
              "
              @click="controller.salvar()"
            ></dev-btn>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              label="Cliente"
              multiple
              dense
              :items="controller.clientePaginacao.itens"
              rounded
              outlined
              item-text="nome"
              item-value="id"
              :rules="[(v) => !!v || 'Preencha o campo']"
              v-model="controller.clientes"
              chips
              small-chips
              @change="controller.buscaPrograsClienteSelecionado()"
            ></v-autocomplete>
            <div
              class="red--text font-weight-bold pb-5 text-center"
              v-if="controller.clientes.length > 1"
            >
              Os programas selecionados abaixo somente serão adicionados aos
              clientes, não havendo opção de remover programas. Para isso
              selecione somente um único cliente.
            </div>
            <v-treeview
              v-if="controller.clientes.length > 0"
              selectable
              selected-color="primary"
              :items="controller.itens"
              item-text="nomeComposto"
              v-model="controller.programas"
              item-key="codMenu"
            ></v-treeview>
          </v-card-text>
        </v-card>
        <div
          v-if="controller.clientes.length === 0"
          class="d-flex flex-column justify-center align-center font-weight-bold headline"
          style="height: calc(100vh - 260px)"
        >
          <div>Selecione um ou mais clientes</div>
          <dev-lottie
            class="mt-10"
            :options="controller.animacao"
            :height="300"
            :width="400"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { moduloController } from "@/di/di";

export default {
  data: (context) => ({
    controller: moduloController(context),
  }),
  async mounted() {
    this.controller.mounted();
  },
};
</script>
