const buscaProgramasClienteUseCase = (repository) => async (id) => {
  try {
    const response = await repository(id);
    return response.programas;
  } catch (error) {
    throw error;
  }
};

export default buscaProgramasClienteUseCase;
