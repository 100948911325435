<template>
  <dev-dialog
    titulo="Cadastro"
    :dialog="controller.dialogForm"
    :desabilita-cancelar="controller.carregando"
    :carregando-salvar="controller.carregando"
    @cancelar="controller.cancelar()"
    @salvar="controller.salvar()"
  >
    <template v-slot:form>
      <v-form ref="cadastro" :disabled="controller.carregando" onsubmit="return false;">
        <v-container fluid class="pa-0">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Nome"
                maxlength="20"
                :rules="[(v) => !!v || 'Preencha o campo']"
                v-model="controller.bancoDados.nome"
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </dev-dialog>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
