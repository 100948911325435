import { listToTree } from "@/core/const/util";
import Handler from "@/core/domain/usecase/handler";

const buscaMenuConsultorsUseCase = (repository) => async () => {
  try {
    const payload = new Handler().getDefaultPayload({
      usuario: "webspeed",
      senha: "cgi123",
      programa: "geral/WebTelaMenu.p",
      metodo: "leWebTelaMenu",
    });

    const programas = await repository(payload);

    return listToTree(programas, "codMenu", "codMenuPai", "descricao");
  } catch (error) {
    throw error;
  }
};

export default buscaMenuConsultorsUseCase;
