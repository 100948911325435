const buscaProxiesUseCase = (repository) => async (paginacao) => {
    try {
        paginacao['sort'] = paginacao?.sortBy?.join(",") ?? null
        paginacao['descending'] = paginacao?.sortDesc?.join(",") ?? null
        const proxies = await repository(paginacao)
        return proxies
    } catch (error) {
        throw error
    }
}

export default buscaProxiesUseCase