const buscaProdutosUseCase = (repository) => async () => {
  const payload = {
    page: 1,
    itemsPerPage: 30,
    sortBy: ["nome"],
    sortDesc: [false],
  };

  return await repository(payload);
};

export default buscaProdutosUseCase;
