const buscaDeltasClientesUseCase = (repository) => async (delta, filtro) => {
  const payload = {
    page: 1,
    itemsPerPage: 1000,
    idProduto: delta.idProduto,
    idBancoDeDados: delta.idBancoDeDados,
    status: filtro?.status?.value ?? null,
  };

  const response = await repository(payload);

  return response.map((item) => ({
    ...item,
  }));
};

export default buscaDeltasClientesUseCase;
