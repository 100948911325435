import Paginacao from "@/core/domain/model/paginacao";
import Handler from "@/core/domain/usecase/handler";
import Auditoria from "../../domain/model/auditoria";

const buscaAuditoriaRepository = (axios) => async (paginacao) => {
  try {
    const response = await axios.get('/auditoria', {
      params: paginacao
    })

    return new Paginacao(response.data, Auditoria)
  } catch (error) {
    throw new Handler().trataErro(error)
  }
}

export default buscaAuditoriaRepository