class Paginacao {
    constructor({ itens = [], total = 0, items = [] }, tipo) {
        this.itens = itens?.map((proxy) => new tipo(proxy)) ?? []
        this.total = total
        this.items = items?.map((proxy) => new tipo(proxy)) ?? []
    }
}

export const defaultPaginacao = () => ({
    itens: [],
    total: 0,
    items: []
})

export default Paginacao