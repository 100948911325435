import { menuItens } from '@/core/const/menuItens'
import { clearUserToken, getUser } from '@/core/domain/model/jwtAuthToken'
import { defaultNavigator } from '@/core/domain/model/navigate'
import Dashboard from '@/module/dashboard/view/Dashboard'
import Configuracoes from '@/module/configuracoes/view/Configuracoes'
import Vue from 'vue'
class HomeController {

  component = Dashboard
  componentConfigPerfil = Configuracoes
  drawer = false
  group = null
  navItens = menuItens
  usuario = getUser()
  menuUsuario = false
  tabMenu = this.navItens[0]
  loading = false

  constructor(context, buscarPermissoesUseCase) {
    this.context = context
    this.buscarPermissoesUseCase = buscarPermissoesUseCase
  }

  async mounted() {
    this.loading = true
    Vue.prototype.navigator = this
    await this.getPermissions()
    this.loading = false
  }

  navigate(screen) {
    this.drawer = false
    if (!screen) {
      this.component = Dashboard
      return
    }
    
    this.component = screen
  }

  logout() {
    clearUserToken()

    defaultNavigator('login')
  }

  async getPermissions() {
    try {
      const permissoes = await this.buscarPermissoesUseCase(this.usuario.idGrupoUsuario)

      Vue.prototype.acl.permissions = permissoes
    } catch (error) {
      // snackbar.show({message: error})
    }
  }
}

export default HomeController