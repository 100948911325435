import Handler from '@/core/domain/usecase/handler';

const buscarPermissoesRepository = (axios) => async (groupId) => {
  try {
    const response = await axios.get(`/grupo-usuario/${groupId}/permissao`)

    return response.data
  } catch (err) {
    throw new Handler().trataErro(err)
  }
}

export default buscarPermissoesRepository