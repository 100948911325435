import Handler from "@/core/domain/usecase/handler"

const alteraAcessoAppRepository = (axios) => async (acessoApp) => {
    try {
        await axios.put(`/acesso-app/${acessoApp.id}`, acessoApp)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default alteraAcessoAppRepository