<template>
  <v-card
    class="rounded-xl my-4"
    elevation="0"
  >
    <div class="d-flex flex-no-wrap justify-space-between align-center">
      <div>
        <v-card-title class="text-h5">
          {{titulo}}
        </v-card-title>

        <v-card-subtitle>Atualizado em {{ dataCriacao }}</v-card-subtitle>

        <v-card-actions>
          <v-btn
            class="ml-2 mt-5"
            outlined
            rounded
            small
            :href="`${url}/storage/${bucket}?path=${caminho}&apiToken=$2a$08$DpbjnHfNWTBNdVnrLI/BMehWLmn38dqrdMrN2hfVyM4M.WzQrlUoG`"
            target="_blank"
          >
            Baixar backup
          </v-btn>
        </v-card-actions>
      </div>
      <div>
        <v-card
          width="200"
          height="200"
          :color="cor"
          elevation="0"
          class="rounded-xl d-flex justify-center align-center"
        >
          <div class="white--text font-weight-bold text-h5">{{ tamanho }}</div>
        </v-card>
      </div>

    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      required: true,
    },
    "data-criacao": {
      type: String,
      required: true,
    },
    tamanho: {
      type: String,
      required: true,
    },
    cor: {
      type: String,
      required: true,
    },
    bucket: {
      type: String,
      required: true,
    },
    caminho: {
      type: String,
      required: true,
    },
  },
  computed: {
    url() {
      return process.env.VUE_APP_API_BASE_URL
    }
  }
};
</script>

<style>
</style>