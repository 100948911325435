import { snackbar } from "@/core/const/devSnackbar"
import { trocarSenha } from "@/core/domain/model/jwtAuthToken"
import { defaultNavigator } from "@/core/domain/model/navigate"

class LoginController {
  loading = false
  usuario = null
  senha = null
  trocarSenha = false
  exibirSenha = false
  emailRules = [
    v => !!v || 'Informe o E-mail',
    v => /.+@.+/.test(v) || 'E-mail inválido',
  ]

  constructor(context, loginUseCase, alterarSenhaUseCase) {
    this.context = context
    this.loginUseCase = loginUseCase
    this.alterarSenhaUseCase = alterarSenhaUseCase
  }

  async login() {
    try {
      if (this.context.$refs.formLogin.validate()) {
        this.loading = true
        await this.loginUseCase(this.usuario, this.senha)

        this.trocarSenha = trocarSenha()

        if (!this.trocarSenha) {
          defaultNavigator('home')
        }

        this.resetaForm()
      }
    } catch (e) {
      snackbar.show({message: e.toString()})
    } finally {
      this.loading = false
    }
  }

  resetaForm() {
    this.usuario = null
    this.senha = null

    this.context.$refs?.formAlterarSenha?.resetValidation()
    this.context.$refs?.formLogin?.resetValidation()
  }

  async alterarSenha() {
    if (this.context.$refs.formAlterarSenha.validate()) {
      await this.alterarSenhaUseCase(this.senha)

      this.trocarSenha = false

      this.resetaForm()
    }

  }

  toFacebook() {
    window.open("https://www.facebook.com/CGISoftware", "_blank");
  }
  
  toInstagram() {
    window.open("https://www.instagram.com/cgisoftware/", "_blank");
  }

  toLinkedin() {
    window.open(
      "https://www.linkedin.com/company/cgisoftware/about/",
      "_blank"
    );
  }

  toTwitter() {
    window.open("https://twitter.com/CgiSoftware", "_blank");
  }
}

export default LoginController