import animationData from "../../../assets/search.json";
import { snackbar } from "@/core/const/devSnackbar";
import { filtro } from "../const/filtro";

class StatusServidorController {
  consultors = [];
  admrh = [];
  tab = 0;
  carregando = false;
  animacao = { animationData: animationData };
  filtro = filtro;
  buscandoStatusServidor = null;
  dialog = false;
  textoLogs = "";

  constructor(
    context,
    buscaProxiesUseCase,
    buscaStatusServidoresUseCase,
    buscaStatusServidorUseCase
  ) {
    this.context = context;
    this.buscaProxiesUseCase = buscaProxiesUseCase;
    this.buscaStatusServidoresUseCase = buscaStatusServidoresUseCase;
    this.buscaStatusServidorUseCase = buscaStatusServidorUseCase;
  }

  async mounted() {
    try {
      this.carregando = true;

      const servidores = await this.buscaStatusServidoresUseCase({
        sortBy: ["nome_cliente"],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 1000,
      });

      const servidoresMapeado = servidores.map((servidor) => ({
        ...servidor,
        cor: this.getCorStatus(servidor.status_code),
      }));

      this.consultors = servidoresMapeado.filter(
        (servidor) => servidor.id_produto === 1
      );

      this.admrh = servidoresMapeado.filter(
        (servidor) => servidor.id_produto === 2
      );

      this.carregando = false;
    } catch (error) {
      snackbar.show({ message: error });
    }
  }

  async buscaStatusServidor({ id, id_proxy, id_produto }) {
    try {
      this.buscandoStatusServidor = id;

      const consultors = this.context?.consultors;
      const admrh = this.context?.admrh;

      const { observacao, status_code, data_criacao } =
        await this.buscaStatusServidorUseCase(id_proxy);

      const produto = {
        1: consultors,
        2: admrh,
      };

      const getIndex = (item) => item.id === id;

      const index = produto[id_produto].findIndex(getIndex);

      const newObj = {
        ...produto[id_produto][index],
        observacao,
        status_code,
        data_criacao,
        cor: this.getCorStatus(status_code),
      };

      produto[id_produto].splice(index, 1, newObj);

      if (id_produto === 1) {
        this.consultors.splice(this.consultors.findIndex(getIndex), 1, newObj);
      }

      if (id_produto === 2) {
        this.admrh.splice(this.admrh.findIndex(getIndex), 1, newObj);
      }

      this.buscandoStatusServidor = null;
    } catch (error) {
      snackbar.show({ message: error });
    }
  }

  getCorStatus(statusCode) {
    return statusCode > 299 ? "red" : "green";
  }

  filtroAtual() {
    return Object.keys(this.filtro).filter((key) => this.filtro[key]);
  }

  filtrar(param) {
    const filtroAtual = this.filtroAtual();

    if (filtroAtual.length < 2 && filtroAtual.includes(param)) {
      return;
    }

    this.filtro[param] = !this.filtro[param];
  }

  infoStatus(item) {
    this.textoLogs = item.observacao;
    this.dialog = true;
  }

  cancelar() {
    this.dialog = false;
  }
}

export default StatusServidorController;
