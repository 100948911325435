<template>
  <v-row class="justify-center">
    <v-col cols="12" lg="6">
      <v-card class="rounded-xl mt-5" elevation="0">
        <v-card-text>
          <h3 class="font-weight-bold">Perfil</h3>
          <p class="text-sm">Atualize suas informações pessoais aqui.</p>
          <v-divider></v-divider>
          <v-form ref="form">
            <v-row>
              <v-col cols="4">
                <h3 class="pt-4">Sigla</h3>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  placeholder="Sigla"
                  class="pt-4"
                  dense
                  outlined
                  single-line
                  rounded
                  :rules="[(v) => !!v || 'Preencha o campo']"
                  v-model="controller.usuario.login"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="4">
                <h3 class="pt-4">E-mail</h3>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  placeholder="E-mail"
                  type="email"
                  class="pt-4"
                  dense
                  outlined
                  rounded
                  :rules="[(v) => !!v || 'Preencha o campo']"
                  v-model="controller.usuario.email"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="4">
                <h3 class="pt-4">Senha</h3>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  placeholder="Senha"
                  class="pt-4"
                  dense
                  outlined
                  rounded
                  v-model="controller.usuario.senha"
                  @click:append="
                    controller.exibirSenha = !controller.exibirSenha
                  "
                  :append-icon="
                    controller.exibirSenha ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  :type="controller.exibirSenha ? 'text' : 'password'"
                  :rules="
                    controller.usuario.id === 0
                      ? [(v) => !!v || 'Preencha o campo']
                      : []
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <dev-btn salvar @click="controller.salvar()"></dev-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
