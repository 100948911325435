import { axiosInstance } from "@/plugins/axios";
import buscaClientesPorAppRepository from "../data/repository/buscaClientesPorAppRepository";
import buscaProjetosRepository from "../data/repository/buscaProjetosRepository";

import buscaClientesPorAppUseCase from "../domain/usecase/buscaClientesPorAppUseCase";
import buscaProjetosUseCase from "../domain/usecase/buscaProjetosUseCase";

import buscaClientesRepository from "../data/repository/buscaClientesRepository";
import buscaClientesUseCase from "../domain/usecase/buscaClientesUseCase";

import ClientesPorAppController from "../controller/clientesPorAppController";

const buscaClientesPorAppRepositoryImpl =
  buscaClientesPorAppRepository(axiosInstance);
const buscaClientesPorAppUseCaseImpl = buscaClientesPorAppUseCase(
  buscaClientesPorAppRepositoryImpl
);

const buscaProjetosRepositoryImpl = buscaProjetosRepository(axiosInstance);
const buscaProjetosUseCaseImpl = buscaProjetosUseCase(
  buscaProjetosRepositoryImpl
);

const buscaClientesRepositoryImpl = buscaClientesRepository(axiosInstance);
const buscaClientesUseCaseImpl = buscaClientesUseCase(
  buscaClientesRepositoryImpl
);

export const clientesPorAppController = (context) =>
  new ClientesPorAppController(
    context,
    buscaClientesPorAppUseCaseImpl,
    buscaProjetosUseCaseImpl,
    buscaClientesUseCaseImpl
  );
