import { testaAtivoString } from "@/core/const/util";

class ClienteFirebase {
  constructor({
    id = "",
    ativo = "Sim",
    black_list = "0",
    cod_cli = 0,
    dias_autenticacao = 0,
    dt_validade = "",
    endereco_pacific = "",
    nome_cli = "",
    num_devices = 0,
    num_devices_vendedor = 0,
    senhaP = "",
    usuarioP = "",
    versao_minima = 0,
    versao_servico = 0,
    modulo_mais_negocios = "Não",
    modulo_avaliacao_usados = "Não",
    modulo_mais_pedidos = "Não",
    modulo_mais_usados = "Não",
    modulo_visitas = "Não",
    modulo_documentos_do_vendedor = "Não",
    modulo_autorizacao_de_faturamento = "Não",
  }) {
    this.id = id;
    this.versao_servico = versao_servico;
    this.ativo = testaAtivoString(ativo);
    this.black_list = black_list;
    this.cod_cli = cod_cli;
    this.dias_autenticacao = dias_autenticacao;
    this.dt_validade = dt_validade;
    this.endereco_pacific = endereco_pacific;
    this.nome_cli = nome_cli;
    this.num_devices = num_devices;
    this.num_devices_vendedor = num_devices_vendedor;
    this.senhaP = senhaP;
    this.usuarioP = usuarioP;
    this.versao_minima = versao_minima;
    this.modulo_mais_negocios = testaAtivoString(modulo_mais_negocios);
    this.modulo_avaliacao_usados = testaAtivoString(modulo_avaliacao_usados);
    this.modulo_mais_pedidos = testaAtivoString(modulo_mais_pedidos);
    this.modulo_mais_usados = testaAtivoString(modulo_mais_usados);
    this.modulo_documentos_do_vendedor = testaAtivoString(
      modulo_documentos_do_vendedor
    );
    this.modulo_autorizacao_de_faturamento = testaAtivoString(
      modulo_autorizacao_de_faturamento
    );
    this.modulo_visitas = modulo_visitas;
  }
}

export const defaultClienteFirebase = () =>
  new ClienteFirebase({
    id: "",
    ativo: "Sim",
    black_list: "0",
    cod_cli: 0,
    dias_autenticacao: 0,
    dt_validade: "",
    endereco_pacific: "",
    nome_cli: "",
    num_devices: "",
    num_devices_vendedor: "",
    senhaP: "",
    usuarioP: "",
    versao_minima: "",
    modulo_mais_negocios: false,
    modulo_avaliacao_usados: false,
    modulo_mais_pedidos: false,
    modulo_mais_usados: false,
    modulo_visitas: false,
    modulo_autorizacao_de_faturamento: false,
    modulo_documentos_do_vendedor: false,
  });

export default ClienteFirebase;
