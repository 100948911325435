class AcessoApp {
  constructor({
    ativo = true,
    id = 0,
    usuario = null,
    senha = null,
    idProjeto = null,
    idProxy = null,
    idCliente = null,
    qtdDevices = 0,
    qtdDevicesPorUsuario = 0,
    versaoMinima = 0,
    versaoServico = 0,
    apiToken = null,
    nomeCliente = null,
    proxyURL = null,
    moduloAvaliacaoUsados = false,
    moduloMaisNegocios = false,
    moduloMaisPedidos = false,
    moduloMaisUsados = false,
    moduloVisitas = false,
    moduloDocumentos = false,
    nomeProjeto = null
  }) {
    this.ativo = ativo
    this.id = id,
    this.usuario = usuario
    this.senha = senha
    this.idProjeto = idProjeto
    this.idProxy = idProxy
    this.idCliente = idCliente
    this.qtdDevices = qtdDevices
    this.qtdDevicesPorUsuario = qtdDevicesPorUsuario
    this.versaoMinima = versaoMinima
    this.versaoServico = versaoServico
    this.apiToken = apiToken
    this.nomeCliente = nomeCliente
    this.proxyURL = proxyURL
    this.moduloAvaliacaoUsados = moduloAvaliacaoUsados
    this.moduloMaisNegocios = moduloMaisNegocios
    this.moduloMaisPedidos = moduloMaisPedidos
    this.moduloMaisUsados = moduloMaisUsados
    this.moduloVisitas = moduloVisitas
    this.nomeProjeto = nomeProjeto
    this.moduloDocumentos = moduloDocumentos
  }
}

export const defaultAcessoApp = () => new AcessoApp({})

export default AcessoApp