<template>
  <v-dialog
    v-if="controller.dialogDetalhes"
    v-model="controller.dialogDetalhes"
    content-class="rounded-xl"
    width="1500"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar flat dense>
          Cliente {{ controller.clienteSelecionado.nome }}

          <template v-slot:extension>
            <v-tabs dense flat v-model="controller.tabDetalhes">
              <v-tabs-slider> </v-tabs-slider>
              <v-tab v-for="tab in tabs" :key="tab.nome">
                {{ tab.nome }}
              </v-tab>
            </v-tabs>
          </template>

          <v-spacer></v-spacer>

          <v-btn icon @click="controller.fecharDetalhes()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="pa-5 dialog-detalhes-bg">
        <v-tabs-items v-model="controller.tabDetalhes">
          <v-tab-item
            v-for="(tab, index) in tabs"
            :key="tab.nome"
            :value="index"
          >
            <component :is="tab.component" :controller="controller" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { tabs } from "../const/tabs";

export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    tabs,
  }),
};
</script>
