import ClienteController from "../controller/clienteController";
import { axiosInstance } from "@/plugins/axios";

import buscaClientesRepository from "../data/repository/buscaClientesRepository";
import buscaClientesUseCase from "../domain/usecase/buscaClienteUseCase";

import criaClienteRepository from "../data/repository/criaClienteRepository";
import criaClienteUseCase from "../domain/usecase/criaClienteUseCase";

import alteraClienteRepository from "../data/repository/alteraClienteRepository";
import alteraClienteUseCase from "../domain/usecase/alteraClienteUseCase";

import excluiClienteRepository from "../data/repository/excluiClienteRepository";
import excluiClienteUseCase from "../domain/usecase/excluiClienteUseCase";

import criaBucketRepository from "../data/repository/criaBucketRepository";
import criaBucketUseCase from "../domain/usecase/criaBucketUseCase";

import buscaProdutosRepository from "@/module/produto/data/repository/buscaProdutosRepository";
import buscaProdutosUseCase from "@/module/produto/domain/usecase/buscaProdutoUseCase";

import buscaProxiesRepository from "@/module/proxy/data/repository/buscaProxiesRepository";
import buscaProxiesUseCase from "@/module/proxy/domain/usecase/buscaProxiesUseCase";

import criaProxyRepository from "@/module/proxy/data/repository/criaProxyRepository";
import criaProxyUseCase from "@/module/proxy/domain/usecase/criaProxyUseCase";

import alteraProxyRepository from "@/module/proxy/data/repository/alteraProxyRepository";
import alteraProxyUseCase from "@/module/proxy/domain/usecase/alteraProxyUseCase";

import excluiProxyRepository from "@/module/proxy/data/repository/excluiProxyRepository";
import excluiProxyUseCase from "@/module/proxy/domain/usecase/excluiProxyUseCase";

import buscaChavesAcessosRepository from "@/module/acessoApp/data/repository/buscaAcessosAppsRepository";
import buscaChavesAcessosUseCase from "@/module/acessoApp/domain/usecase/buscaAcessosAppsUseCase";

import buscaProjetosFirebaseRepository from "@/module/projetoFirebase/data/repository/buscaProjetoFirebaseRepository";
import buscaProjetosFirebaseUseCase from "@/module/projetoFirebase/domain/usecase/buscaProjetoFirebaseUseCase";

import criaAcessoAppRepository from "@/module/acessoApp/data/repository/criaAcessoAppRepository";
import criaAcessoAppUseCase from "@/module/acessoApp/domain/usecase/criaAcessoAppUseCase";

import alteraAcessoAppRepository from "@/module/acessoApp/data/repository/alteraAcessoAppRepository";
import alteraAcessoAppUseCase from "@/module/acessoApp/domain/usecase/alteraAcessoAppUseCase";

import excluiAcessoAppRepository from "@/module/acessoApp/data/repository/excluiAcessoAppRepository";
import excluiAcessoAppUseCase from "@/module/acessoApp/domain/usecase/excluiAcessoAppUseCase";

import buscaMenuConsultorsRepository from "@/module/modulo/data/repository/buscaMenuConsultorsRepository";
import buscaMenuConsultorsUseCase from "@/module/modulo/domain/usecase/buscaMenuConsultorsUseCase";

import buscaProgramasClienteRepository from "@/module/modulo/data/repository/buscaProgramasClienteRepository";
import buscaProgramasClienteUseCase from "@/module/modulo/domain/usecase/buscaProgramasClienteUseCase";

import criaProgramasClienteRepository from "@/module/modulo/data/repository/criaProgramasClienteRepository";
import criaProgramasClienteUseCase from "@/module/modulo/domain/usecase/criaProgramasClienteUseCase";

import criaProgramasClienteMultiploRepository from "@/module/modulo/data/repository/criaProgramasClienteMultiploRepository";
import criaProgramasClienteMultiploUseCase from "@/module/modulo/domain/usecase/criaProgramasClienteMultiploUseCase";

import buscaCertificadoDigitalRepository from "@/module/certificadoDigital/data/repository/buscaCertificadoDigitalRepository";
import buscaCertificadoDigitalUseCase from "@/module/certificadoDigital/domain/usecase/buscaCertificadoDigitalUseCase";

import criaCertificadoDigitalRepository from "@/module/certificadoDigital/data/repository/criaCertificadoDigitalRepository";
import criaCertificadoDigitalUseCase from "@/module/certificadoDigital/domain/usecase/criaCertificadoDigitalCase";

import alteraCertificadoDigitalRepository from "@/module/certificadoDigital/data/repository/alteraCertificaoDigitalRepository";
import alteraCertificadoDigitalUseCase from "@/module/certificadoDigital/domain/usecase/alteraCertificadoDigitalUseCase";

import excluiCertificadoDigitalRepository from "@/module/certificadoDigital/data/repository/excluiCertificadoDigitalRepository";
import excluiCertificadoDigitalUseCase from "@/module/certificadoDigital/domain/usecase/excluiCertificadoDigitalUseCase";

import buscaBancosDadosClienteRepository from "../data/repository/buscaBancosDadosClienteRepository";
import buscaBancosdadosClienteUseCase from "../domain/usecase/buscaBancosDadosClienteUseCase";

import buscaBancosDadosRepository from "@/module/bancoDados/data/repository/buscaBancosDadosRepository";
import buscaBancosDadosUseCase from "@/module/bancoDados/domain/usecase/buscaBancosDadosUseCase";

import criaBancoDadosClienteRepository from "../data/repository/criaBancoDadosClienteRepository";
import criaBancoDadosClienteUseCase from "../domain/usecase/criaBancoDadosClienteUseCase";

import alteraBancoDadosClienteRepository from "../data/repository/alteraBancoDadosClienteRepository";
import alteraBancoDadosClienteUseCase from "../domain/usecase/alteraBancoDadosClienteUseCase";

import deletaBancoDadosClienteRepository from "../data/repository/deletaBancoDadosClienteRepository";
import deletaBancoDadosClienteUseCase from "../domain/usecase/deletaBancoDadosClienteUseCase";

const instance = axiosInstance;

const buscaClientesRepositoryImpl = buscaClientesRepository(instance);
const buscaClientesUseCaseImpl = buscaClientesUseCase(
  buscaClientesRepositoryImpl
);

const criaClienteRepositoryImpl = criaClienteRepository(instance);
const criaClienteUseCaseImpl = criaClienteUseCase(criaClienteRepositoryImpl);

const alteraClienteRepositoryImpl = alteraClienteRepository(instance);
const alteraClienteUseCaseImpl = alteraClienteUseCase(
  alteraClienteRepositoryImpl
);

const excluiClienteRepositoryImpl = excluiClienteRepository(instance);
const excluiClienteUseCaseImpl = excluiClienteUseCase(
  excluiClienteRepositoryImpl
);

const criaBucketRepositoryImpl = criaBucketRepository(instance);
const criaBucketUseCaseImpl = criaBucketUseCase(criaBucketRepositoryImpl);

const buscaProdutosRepositoryImpl = buscaProdutosRepository(instance);
const buscaProdutosUseCaseImpl = buscaProdutosUseCase(
  buscaProdutosRepositoryImpl
);

const buscaProxiesRepositoryImpl = buscaProxiesRepository(instance);
const buscaProxiesUseCaseImpl = buscaProxiesUseCase(buscaProxiesRepositoryImpl);

const criaProxyRepositoryImpl = criaProxyRepository(instance);
const criaProxyUseCaseImpl = criaProxyUseCase(criaProxyRepositoryImpl);

const alteraProxyRepositoryImpl = alteraProxyRepository(instance);
const alteraProxyUseCaseImpl = alteraProxyUseCase(alteraProxyRepositoryImpl);

const excluiProxyRepositoryImpl = excluiProxyRepository(instance);
const excluiProxyUseCaseImpl = excluiProxyUseCase(excluiProxyRepositoryImpl);

const buscaChavesAcessosRepositoryImpl = buscaChavesAcessosRepository(instance);
const buscaChavesAcessosUseCaseImpl = buscaChavesAcessosUseCase(
  buscaChavesAcessosRepositoryImpl
);

const buscaProjetosFirebaseRepositoryImpl =
  buscaProjetosFirebaseRepository(instance);
const buscaProjetosFirebaseUseCaseImpl = buscaProjetosFirebaseUseCase(
  buscaProjetosFirebaseRepositoryImpl
);

const criaAcessoAppRepositoryImpl = criaAcessoAppRepository(instance);
const criaAcessoAppUseCaseImpl = criaAcessoAppUseCase(
  criaAcessoAppRepositoryImpl
);

const alteraAcessoAppRepositoryImpl = alteraAcessoAppRepository(instance);
const alteraAcessoAppUseCaseImpl = alteraAcessoAppUseCase(
  alteraAcessoAppRepositoryImpl
);

const excluiAcessoAppRepositoryImpl = excluiAcessoAppRepository(instance);
const excluiAcessoAppUseCaseImpl = excluiAcessoAppUseCase(
  excluiAcessoAppRepositoryImpl
);

const buscaMenuConsultorsRepositoryImpl = buscaMenuConsultorsRepository();
const buscaMenuConsultorsUseCaseImpl = buscaMenuConsultorsUseCase(
  buscaMenuConsultorsRepositoryImpl
);

const buscaProgramasClienteRepositoryImpl =
  buscaProgramasClienteRepository(instance);
const buscaProgramasClienteUseCaseImpl = buscaProgramasClienteUseCase(
  buscaProgramasClienteRepositoryImpl
);

const criaProgramasClienteRepositoryImpl =
  criaProgramasClienteRepository(instance);
const criaProgramasClienteUseCaseImpl = criaProgramasClienteUseCase(
  criaProgramasClienteRepositoryImpl
);

const criaProgramasClienteMultiploRepositoryImpl =
  criaProgramasClienteMultiploRepository(instance);
const criaProgramasClienteMultiploUseCaseImpl =
  criaProgramasClienteMultiploUseCase(
    criaProgramasClienteMultiploRepositoryImpl
  );

const buscaCertificadoDigitalRepositoryImpl =
  buscaCertificadoDigitalRepository(instance);
const buscaCertificadoDigitalUseCaseImpl = buscaCertificadoDigitalUseCase(
  buscaCertificadoDigitalRepositoryImpl
);

const criaCertificadoDigitalRepositoryImpl =
  criaCertificadoDigitalRepository(instance);
const criaCertificadoDigitalUseCaseImpl = criaCertificadoDigitalUseCase(
  criaCertificadoDigitalRepositoryImpl
);

const alteraCertificadoDigitalRepositoryImpl =
  alteraCertificadoDigitalRepository(instance);
const alteraCertificadoDigitalUseCaseImpl = alteraCertificadoDigitalUseCase(
  alteraCertificadoDigitalRepositoryImpl
);

const excluiCertificadoDigitalRepositoryImpl =
  excluiCertificadoDigitalRepository(instance);
const excluiCertificadoDigitalUseCaseImpl = excluiCertificadoDigitalUseCase(
  excluiCertificadoDigitalRepositoryImpl
);

const buscaBancosDadosClienteRepositoryImpl =
  buscaBancosDadosClienteRepository(instance);
const buscaBancosDadosClienteUseCaseImpl = buscaBancosdadosClienteUseCase(
  buscaBancosDadosClienteRepositoryImpl
);

const buscaBancosDadosRepositoryImpl = buscaBancosDadosRepository(instance);
const buscaBancosDadosUseCaseImpl = buscaBancosDadosUseCase(
  buscaBancosDadosRepositoryImpl
);

const criaBancoDadosClienteRepositoryImpl =
  criaBancoDadosClienteRepository(instance);
const criaBancoDadosClienteUseCaseImpl = criaBancoDadosClienteUseCase(
  criaBancoDadosClienteRepositoryImpl
);

const alteraBancoDadosClienteRepositoryImpl =
  alteraBancoDadosClienteRepository(instance);
const alteraBancoDadosClienteUseCaseImpl = alteraBancoDadosClienteUseCase(
  alteraBancoDadosClienteRepositoryImpl
);

const deletaBancoDadosClienteRepositoryImpl =
  deletaBancoDadosClienteRepository(instance);
const deletaBancoDadosClienteUseCaseImpl = deletaBancoDadosClienteUseCase(
  deletaBancoDadosClienteRepositoryImpl
);

export const clienteController = (context) =>
  new ClienteController(
    context,
    buscaClientesUseCaseImpl,
    criaClienteUseCaseImpl,
    alteraClienteUseCaseImpl,
    excluiClienteUseCaseImpl,
    criaBucketUseCaseImpl,
    buscaProdutosUseCaseImpl,
    buscaProxiesUseCaseImpl,
    criaProxyUseCaseImpl,
    alteraProxyUseCaseImpl,
    excluiProxyUseCaseImpl,
    buscaChavesAcessosUseCaseImpl,
    buscaProjetosFirebaseUseCaseImpl,
    criaAcessoAppUseCaseImpl,
    alteraAcessoAppUseCaseImpl,
    excluiAcessoAppUseCaseImpl,
    buscaMenuConsultorsUseCaseImpl,
    buscaProgramasClienteUseCaseImpl,
    criaProgramasClienteUseCaseImpl,
    criaProgramasClienteMultiploUseCaseImpl,
    buscaCertificadoDigitalUseCaseImpl,
    criaCertificadoDigitalUseCaseImpl,
    alteraCertificadoDigitalUseCaseImpl,
    excluiCertificadoDigitalUseCaseImpl,
    buscaBancosDadosClienteUseCaseImpl,
    buscaBancosDadosUseCaseImpl,
    criaBancoDadosClienteUseCaseImpl,
    alteraBancoDadosClienteUseCaseImpl,
    deletaBancoDadosClienteUseCaseImpl
  );
