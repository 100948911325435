import Handler from "@/core/domain/usecase/handler"

const excluiAcessoAppRepository = (axios) => async (id) => {
    try {
        await axios.delete(`/acesso-app/${id}`)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default excluiAcessoAppRepository