<template>
  <v-container fluid class="dialog-detalhes-bg">
    <v-row dense>
      <v-col class="d-flex justify-end">
        <dev-btn
          incluir
          v-if="hadPermission(acessoAppPermissionId, createActionId)"
          @click="controller.adicionarAcesso()"
        ></dev-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        lg="6"
        sm="12"
        v-for="(acesso, key) in controller.acessos"
        :key="key"
      >
        <dev-cards-list
          :titulo="`Projeto: ${key}`"
          :items="acesso"
          :configuracao="controller.detalhes.configuracaoAcessos"
          @alterar-item="controller.alterarAcesso($event)"
          @deletar-item="controller.excluirAcesso($event)"
          @copiar-token="controller.copiarTokenAcesso($event)"
          :alterar="hadPermission(acessoAppPermissionId, alterActionId)"
          :deletar="hadPermission(acessoAppPermissionId, deleteActionId)"
          :carregar="controller.carregandoAcesso"
          mostra-acoes
          copiar-token
        >
          <template v-slot:legenda="{ item }">
            <div>
              <v-chip
                :color="item.ativo ? 'green lighten-2' : 'red lighten-2'"
                style="width: fit-content"
                class="mr-2"
                small
              >
                {{ item.ativo ? "Ativo" : "Desativado" }}
              </v-chip>
            </div>
          </template>
        </dev-cards-list>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div
          v-if="!Object.keys(controller.acessos).length"
          class="d-flex flex-column justify-center align-center font-weight-bold headline"
        >
          <div>Nenhum acesso</div>
          <dev-lottie
            class="mt-10"
            :options="controller.animacao"
            :height="300"
            :width="400"
          />
        </div>
      </v-col>
    </v-row>

    <dev-dialog
      titulo="Cadastro"
      :dialog="controller.dialogAcesso"
      @cancelar="controller.cancelarAcesso($refs.form)"
      @salvar="controller.salvarAcesso($refs.form)"
    >
      <template v-slot:form>
        <v-form ref="form" :disabled="controller.carregandoAcesso">
          <v-switch
            inset
            label="Ativo"
            dense
            hide-details
            v-model="controller.acessoApp.ativo"
            class="ma-2 mb-4"
          />
          <v-text-field
            outlined
            rounded
            dense
            label="Usuário"
            v-model="controller.acessoApp.usuario"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Senha"
            v-model="controller.acessoApp.senha"
          ></v-text-field>
          <v-autocomplete
            rounded
            outlined
            label="Proxy"
            :items="[
              ...controller.proxies.admrh.items,
              ...controller.proxies.consultors.items,
            ]"
            item-text="url"
            item-value="id"
            :rules="[controller.rules.obrigatorio]"
            v-model="controller.acessoApp.idProxy"
            dense
          />
          <v-autocomplete
            rounded
            outlined
            label="Projeto"
            :items="controller.projetoPaginacao.itens"
            item-text="nome"
            item-value="id"
            @update:search-input="controller.buscaProjetosDebounce($event)"
            @change="controller.validaCamposExtrasMaisVendas($event)"
            :rules="[controller.rules.obrigatorio]"
            dense
            v-model="controller.acessoApp.idProjeto"
          />
          <template
            v-if="
              controller.acessoApp.idProjeto && controller.exibeCamposMaisVendas
            "
          >
            <v-row class="pb-10 pa-2">
              <v-col cols="6">
                <v-switch
                  inset
                  label="Avaliação de usados"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloAvaliacaoUsados"
                ></v-switch>
                <v-switch
                  inset
                  label="Mais negócios"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloMaisNegocios"
                ></v-switch>
                <v-switch
                  inset
                  label="Mais pedidos"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloMaisPedidos"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                  inset
                  label="Mais usados"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloMaisUsados"
                ></v-switch>
                <v-switch
                  inset
                  label="Visitas"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloVisitas"
                ></v-switch>
                <v-switch
                  class="pb-4"
                  inset
                  label="Documentos"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloDocumentos"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
          <v-text-field
            outlined
            rounded
            dense
            type="number"
            label="Número de devices por usuário"
            :rules="[
              controller.rules.numberRule0And999,
              controller.rules.obrigatorio,
            ]"
            v-model.number="controller.acessoApp.qtdDevicesPorUsuario"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Número de devices"
            :rules="[
              controller.rules.numberRule0And999,
              controller.rules.obrigatorio,
            ]"
            v-model.number="controller.acessoApp.qtdDevices"
            type="number"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Versão minima"
            :rules="[
              controller.rules.numberRule0And999,
              controller.rules.obrigatorio,
            ]"
            v-model.number="controller.acessoApp.versaoMinima"
            type="number"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Versão serviço"
            :rules="[
              controller.rules.numberRule0And999,
              controller.rules.obrigatorio,
            ]"
            v-model.number="controller.acessoApp.versaoServico"
            type="number"
          ></v-text-field>
        </v-form>
      </template>
    </dev-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
