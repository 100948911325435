import { snackbar } from "@/core/const/devSnackbar";
import { defaultUsuario } from "../domain/model/usuario";
import { defaultPaginacao } from "@/core/domain/model/paginacao";
import { getUser } from '@/core/domain/model/jwtAuthToken'
import _ from 'lodash'

class ConfiguracoesController {
  usuario = defaultUsuario()
  grupoUsuarioPaginacao = defaultPaginacao()
  tabs = ["Perfil"]
  tab = 0
  exibirSenha = false
  paginacao = {}

  constructor(
    context,
    alteraUsuarioUseCase,
    buscaGrupoUsuarioUseCase,
    refreshUseCase,
  ) {
    this.context = context,
      this.alteraUsuarioUseCase = alteraUsuarioUseCase
    this.buscaGrupoUsuarioUseCase = buscaGrupoUsuarioUseCase
    this.refreshUseCase = refreshUseCase
  }

  async mounted() {
    try {
      await Promise.all([this.buscaGrupoUsuario(null)])
      this.usuario = getUser()

      console.log(this.usuario)
    } catch (error) {
      snackbar.show({ message: error })
    }
  }

  buscaGrupoUsuario = _.debounce(async (search) => {
    this.grupoUsuarioPaginacao = await this.buscaGrupoUsuarioUseCase({ itemsPerPage: 10, page: 1, search: search, sortBy: "nome", searchFields: "nome" })
  }, 500)

  async salvar() {
    try {
      if (this.usuario.id > 0) {
        await this.alteraUsuarioUseCase(this.usuario)
      }

      snackbar.show({
        message: "Registro salvo com sucesso!",
        color: 'green',
        timeout: 30000
      })

    } catch (error) {
      snackbar.show({ message: error })
    } finally {
      this.refreshUseCase()
    }
  }

  mudaTabs(tab) {
    this.tab = tab ? tab : 0
  }
}

export default ConfiguracoesController