import { clearUserToken, getToken } from "@/core/domain/model/jwtAuthToken";
import { baseUrl, baseUrlDesktopLogs } from "@/constants";
import axios from "axios";
import refreshRepostory from "@/core/data/repository/refreshRepository";
import refreshUseCase from "@/core/domain/usecase/refreshUseCase";
import { defaultNavigator } from "@/core/domain/model/navigate";
import { snackbar } from "@/core/const/devSnackbar";

const refreshRepositoryImpl = refreshRepostory(baseUrl);
const refreshUseCaseImpl = refreshUseCase(refreshRepositoryImpl);

const retries = {
  maxRetries: 10,
  attempt: 1,
};

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

const axiosInstanceDesktopLogs = axios.create({
  baseURL: baseUrlDesktopLogs,
  headers: {
    Authorization:
      "$2a$12$iQDV/cKnv2ZXx2AvYSZ3UuxLUgl8HS8b2617NsiX9xnAjSQmXvne6",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const jwtAuthToken = getToken();

    if (jwtAuthToken.token) {
      config.headers = {
        authorization: `Bearer ${jwtAuthToken.token}`,
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    retries.attempt = 1;
    return response;
  },
  async (err) => {
    const originalRequest = err.config;
    const status = err.response ? err.response.status : null;

    if (status >= 500 && status <= 599) {
      snackbar.show({ message: err.response.data?.message });
      return Promise.reject();
    }

    if (status === 401) {
      if (retries.attempt < retries.maxRetries) {
        retries.attempt++;

        const refreshed = await refreshUseCaseImpl();

        if (refreshed) {
          const jwtAuthToken = getToken();
          originalRequest.headers["Authorization"] =
            "Bearer " + jwtAuthToken.token;

          return axiosInstance(originalRequest);
        }
      }
      clearUserToken();

      defaultNavigator("login");

      snackbar.show({
        message:
          "Você foi desconectado pois o recurso não está liberado para o seu usuário",
      });
      return Promise.reject();
    }

    if (status === 422) {
      const fields = err?.response?.data?.fields ?? [];

      if (fields.length) {
        const message = fields.reduce((prev, curr) => {
          return (prev += `${curr.message} <br/>`);
        }, "");

        snackbar.show({ message });

        return Promise.reject();
      }

      snackbar.show({ message: err.response.data?.message });
      return Promise.reject();
    }

    return Promise.reject(err);
  }
);

export { axiosInstance, axiosInstanceDesktopLogs, refreshUseCaseImpl };
