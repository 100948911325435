import _ from "lodash";
import Prism from "prismjs";
import { alert } from "@/core/const/devAlert";

import Delta from "../domain/model/delta";
import JsonDelta from "../domain/model/jsonDelta";
import { colunas } from "../const/headers";
import { defaultPaginacao } from "@/core/domain/model/paginacao";
import { filtro } from "../const/filtro";

class CadastroDeltaController {
  carregando = false;
  menuFiltro = false;
  dialogForm = false;
  dialogPreviewDelta = false;
  linhas = [];
  colunas = colunas;
  total = 0;
  paginacao = {};
  filtro = filtro;
  delta = new Delta({});
  rules = {
    obrigatorio: (v) => !!v || "Preencha o campo",
    tipoArquivoDf: (v) => {
      if (!v) return true;

      return (
        this.getFileExtension(v) === "df" ||
        "Carregue um arquivo com extensão .df"
      );
    },
  };
  bancosDados = defaultPaginacao();
  produtos = defaultPaginacao();
  arquivoDelta = null;
  deltaSelecionado = null;
  textoArquivoDf = null;

  constructor(
    context,
    buscaDeltasUseCase,
    criaDeltaUseCase,
    alteraDeltaUseCase,
    deletaDeltaUseCase,
    buscaProdutosUseCase,
    buscaBancosDadosUseCase
  ) {
    this.context = context;
    this.buscaDeltasUseCase = buscaDeltasUseCase;
    this.criaDeltaUseCase = criaDeltaUseCase;
    this.alteraDeltaUseCase = alteraDeltaUseCase;
    this.deletaDeltaUseCase = deletaDeltaUseCase;
    this.buscaProdutosUseCase = buscaProdutosUseCase;
    this.buscaBancosDadosUseCase = buscaBancosDadosUseCase;
  }

  async paginando(paginacao) {
    try {
      this.carregando = true;
      this.paginacao = paginacao ?? this.paginacao;

      const { itens, total } = await this.buscaDeltasUseCase(
        this.paginacao,
        this.filtro
      );

      this.linhas = itens;
      this.total = total;
    } finally {
      this.carregando = false;
    }
  }

  async salvar() {
    try {
      if (!this.validaFormulario()) {
        return;
      }

      this.carregando = true;

      if (this.delta.id) {
        await this.alteraDeltaUseCase(this.delta.toSave());
      } else {
        await this.criaDeltaUseCase(this.delta.toSave());
      }

      this.cancelar();
      await this.paginando();
    } finally {
      this.carregando = false;
    }
  }

  async deletar({ id }) {
    try {
      const confirma = await alert.confirm({
        message: "Deseja realmente excluír o delta?",
        color: "red",
      });

      if (confirma) {
        this.carregando = true;

        await this.deletaDeltaUseCase(id);
        await this.paginando();
      }
    } finally {
      this.carregando = false;
    }
  }

  async buscaBancosDados(search) {
    this.bancosDados = await this.buscaBancosDadosUseCase({
      itemsPerPage: 30,
      page: 1,
      search: search,
      sortBy: ["nome"],
      sortDesc: [false],
      searchFields: "nome",
    });
  }

  async buscaProdutos(search) {
    this.produtos = await this.buscaProdutosUseCase({
      itemsPerPage: 30,
      page: 1,
      search: search,
      sortBy: ["nome"],
      sortDesc: [false],
      searchFields: "nome",
    });
  }

  async buscaBancoDados(nome) {
    try {
      this.carregando = true;

      const response = await this.buscaBancosDadosUseCase({
        itemsPerPage: 1,
        page: 1,
        search: nome,
        searchFields: "nome",
      });

      return response?.itens?.[0] ?? {};
    } finally {
      this.carregando = false;
    }
  }

  async filtrar() {
    await this.paginando();
  }

  async resetaValidacaoFormulario() {
    await this.context.$nextTick();
    this.context.$refs.form.$refs.cadastro.resetValidation();
  }

  async leArquivoDelta(file) {
    if (!file || this.getFileExtension(file) !== "df") return;

    await this.populaCampos(file.name);

    const reader = new FileReader();

    reader.readAsText(file, "ascii");
    reader.onload = (event) => {
      this.delta.textoDoArquivoDF = event.target.result;
    };
  }

  async populaCampos(fileName) {
    const splitted = fileName.replace(".df", "").split("_");
    const bancoDados = await this.buscaBancoDados(splitted[1]);
    const numDelta = splitted[2];
    const numPatch = splitted[3];

    this.delta.idBancoDeDados = bancoDados?.id ?? null;
    this.delta.numDelta = numDelta;
    this.delta.numPatch = numPatch;
  }

  validaFormulario() {
    return this.context.$refs.form.$refs.cadastro.validate();
  }

  cancelar() {
    this.dialogForm = false;
    this.delta = new Delta({});
    this.arquivoDelta = null;
    this.resetaValidacaoFormulario();
  }

  incluir() {
    this.dialogForm = true;
    this.delta = new Delta({});
    this.resetaValidacaoFormulario();
  }

  alterar(item) {
    this.dialogForm = true;
    this.delta = new Delta(item);
    this.resetaValidacaoFormulario();
  }

  limpaArquivoDelta() {
    this.delta = new Delta({});
  }

  getFileExtension(file) {
    return file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  linhaSelecionada(item) {
    this.deltaSelecionado = { ...item[0] };
    this.textoArquivoDf = Prism.highlight(
      this.deltaSelecionado.textoDoArquivoDF,
      Prism.languages.txt
    );
  }

  downloadJson() {
    const json = new JsonDelta(this.deltaSelecionado).toDownload();
    const numDelta = this.padStart(this.deltaSelecionado.numDelta, 3);
    const numPatch = this.padStart(this.deltaSelecionado.numPatch, 4);
    const nomeBancoDeDados = this.deltaSelecionado.nomeBancoDeDados;
    const nomeArquivo = `do_${nomeBancoDeDados}_${numDelta}_${numPatch}`;

    this.createDownloadJson(json, nomeArquivo);
  }

  createDownloadJson(json, nomeArquivo) {
    const downloadElement = document.createElement("a");
    const jsonString = JSON.stringify(json, null, 4);
    const blob = new Blob([jsonString], { type: "application/json" });

    document.body.appendChild(downloadElement);
    downloadElement.href = window.URL.createObjectURL(blob);
    downloadElement.download = nomeArquivo;
    downloadElement.click();
  }

  padStart(value, length, fillStr = "0") {
    return String(value).padStart(length, fillStr);
  }

  cancelarFiltro() {
    this.menuFiltro = false;
  }

  limparFiltro() {
    this.filtro.idProduto = null;
    this.filtro.idBancoDeDados = null;
  }

  buscaBancosDadosDebounce = _.debounce(async (search) => {
    this.buscaBancosDados(search);
  }, 500);

  buscaProdutosDebounce = _.debounce(async (search) => {
    this.buscaProdutos(search);
  }, 500);
}

export default CadastroDeltaController;
