class LogsDesktop {
  constructor({
    codCliCgi = null,
    dataCriacao = null,
    descricao = null,
    id = 0,
    idCliente = 0,
    idProduto = 0,
    modulo = null,
    nomeCliente = null,
    processo = null,
    programa = null,
    versao = null,
  }) {
    this.codCliCgi = codCliCgi
    this.dataCriacao = dataCriacao
    this.descricao = descricao
    this.id = id
    this.idCliente = idCliente
    this.idProduto = idProduto
    this.modulo = modulo
    this.nomeCliente = nomeCliente
    this.processo = processo
    this.programa = programa
    this.versao = versao
  }
}

export const defaultLogsDesktop = () => new LogsDesktop({})

export default LogsDesktop