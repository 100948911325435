<template>
  <div>
    <dev-tabela
      nome-tabela="Chaves de Acesso (clientes)"
      :linhas="controller.chavesAcessoPaginacao.itens"
      :total-itens="controller.chavesAcessoPaginacao.total"
      :colunas="controller.colunas"
      @paginando="controller.mounted($event)"
      agrupar-por="nomeProjeto"
      :deletar="false"
      :alterar="false"
      :carregar="controller.carregando"
      mostra-pesquisa
    >
      <template v-slot:botoes>
        <v-row>
          <v-col>
            <div class="mr-2">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="controller.carregando"
                    max-height="36"
                    max-width="36"
                    v-on="on"
                    v-bind="attrs"
                    icon
                  >
                    <v-icon @click="controller.atualizar()">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Recarregar</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:apiToken="{ item }">
        <v-btn
          text
          icon
          color="primary"
          @click="controller.copiarToken(item.apiToken)"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">
                mdi-content-copy
              </v-icon>
            </template>
            <span>Copiar Token</span>
          </v-tooltip>
        </v-btn>
      </template>
      <template v-slot:ativo="{ item }">
        <div class="d-flex justify-center">
          <v-checkbox
            dense
            hide-details
            v-model="item.ativo"
            readonly
          ></v-checkbox>
        </div>
      </template>
    </dev-tabela>

    <dev-dialog
      :dialog="controller.dialog"
      titulo="Cadastro"
      @cancelar="controller.cancelar()"
      @salvar="controller.salvar()"
    >
      <template v-slot:form>
        <v-form ref="form">
          <v-switch
            inset
            label="Ativo"
            dense
            hide-details
            v-model="controller.acessoApp.ativo"
            class="ma-2 mb-4"
          />
          <v-text-field
            outlined
            rounded
            dense
            label="Usuário"
            v-model="controller.acessoApp.usuario"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Senha"
            v-model="controller.acessoApp.senha"
          ></v-text-field>
          <v-autocomplete
            label="Cliente"
            dense
            :items="controller.clientePaginacao.itens"
            rounded
            outlined
            item-text="nome"
            item-value="id"
            @update:search-input="controller.buscaClientesDebounce($event)"
            @change="controller.buscaProxies($event)"
            :rules="[(v) => !!v?.id || 'Preencha o campo']"
            return-object
            v-model="controller.cliente"
          />
          <v-autocomplete
            rounded
            outlined
            :disabled="!controller.acessoApp.idCliente"
            label="Proxy"
            :items="controller.proxyPaginacao.itens"
            item-text="url"
            item-value="id"
            :rules="[(v) => !!v || 'Preencha o campo']"
            dense
            v-model="controller.acessoApp.idProxy"
          />
          <v-autocomplete
            rounded
            outlined
            label="Projeto"
            :items="controller.projetoPaginacao.itens"
            item-text="nome"
            item-value="id"
            @update:search-input="controller.buscaProjetosDebounce($event)"
            @change="controller.validaCamposExtrasMaisVendas($event)"
            :rules="[(v) => !!v || 'Preencha o campo']"
            dense
            v-model="controller.acessoApp.idProjeto"
          />
          <template
            v-if="
              controller.acessoApp.idProjeto && controller.exibeCamposMaisVendas
            "
          >
            <v-row class="pb-10 pa-2">
              <v-col cols="6">
                <v-switch
                  inset
                  label="Avaliação de usados"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloAvaliacaoUsados"
                ></v-switch>
                <v-switch
                  inset
                  label="Mais negócios"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloMaisNegocios"
                ></v-switch>
                <v-switch
                  inset
                  label="Mais pedidos"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloMaisPedidos"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                  inset
                  label="Mais usados"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloMaisUsados"
                ></v-switch>
                <v-switch
                  inset
                  label="Visitas"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloVisitas"
                ></v-switch>
                <v-switch
                  class="pb-4"
                  inset
                  label="Documentos"
                  dense
                  hide-details
                  v-model="controller.acessoApp.moduloDocumentos"
                ></v-switch>
              </v-col>
            </v-row>
          </template>
          <v-text-field
            outlined
            rounded
            dense
            type="number"
            label="Número de devices por usuário"
            :rules="[controller.numberRule0And999, controller.required]"
            v-model.number="controller.acessoApp.qtdDevicesPorUsuario"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Número de devices"
            :rules="[controller.numberRule0And999, controller.required]"
            v-model.number="controller.acessoApp.qtdDevices"
            type="number"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Versão minima"
            :rules="[controller.numberRule0And999, controller.required]"
            v-model.number="controller.acessoApp.versaoMinima"
            type="number"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Versão serviço"
            :rules="[controller.numberRule0And999, controller.required]"
            v-model.number="controller.acessoApp.versaoServico"
            type="number"
          ></v-text-field>
        </v-form>
      </template>
    </dev-dialog>
  </div>
</template>

<script>
import { acessoAppController } from "@/di/di";
export default {
  name: "acessoApp",
  data: (context) => ({
    controller: acessoAppController(context),
  }),
};
</script>
