import { getUser } from "@/core/domain/model/jwtAuthToken";
import { Buffer } from 'buffer';

class JsonDelta {
  constructor({
    numDelta = null,
    numPatch = null,
    nomeBancoDeDados = null,
    textoDoArquivoDF = "",
  }) {
    this.num_delta = numDelta;
    this.num_patch = numPatch;
    this.db_name = nomeBancoDeDados;
    this.usuario = getUser()?.login;
    this.df_base64 = Buffer.from(textoDoArquivoDF).toString("base64");
  }

  toDownload() {
    const payload = { ...this };

    return { dSet: { ttDeltaOnline: [payload] } };
  }
}

export default JsonDelta;
