<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          fab
          icon
          x-small
          v-if="incluir"
          v-on="on"
          :elevation="0"
          class="white--text font-weight-bold"
          @click="$emit('click')"
          :disabled="desabilitaIncluir"
          style="
            background: linear-gradient(to right, #ffa64d, #fd6400);
            color: #121934;
          "
        >
          <v-icon small> mdi-plus </v-icon>
        </v-btn>
      </template>
      <span>Incluir registro</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          rounded
          v-if="salvar"
          v-on="on"
          :elevation="0"
          class="white--text font-weight-bold"
          @click="$emit('click')"
          :disabled="desabilitaSalvar"
          :loading="carregandoSalvar"
          style="
            background: linear-gradient(to right, #ffa64d, #fd6400);
            color: #121934;
          "
        >
          <v-icon left small> mdi-content-save </v-icon> Salvar
        </v-btn>
      </template>
      <span>Salvar registro</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          rounded
          v-if="cancelar"
          v-on="on"
          :elevation="0"
          outlined
          color="red"
          @click="$emit('click')"
          :disabled="desabilitaCancelar"
          class="font-weight-bold"
        >
          <v-icon left>mdi-close</v-icon> cancelar
        </v-btn>
      </template>
      <span>Cancelar</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          rounded
          v-if="limpar"
          v-on="on"
          :elevation="0"
          outlined
          color="primary"
          @click="$emit('click')"
          :disabled="desabilitaLimpar"
          class="font-weight-bold"
        >
          <v-icon left>mdi-broom</v-icon> Limpar
        </v-btn>
      </template>
      <span>Limpar</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    incluir: {
      type: Boolean,
      default: () => false,
    },
    salvar: {
      type: Boolean,
      default: () => false,
    },
    cancelar: {
      type: Boolean,
      default: () => false,
    },
    limpar: {
      type: Boolean,
      default: () => false,
    },
    "desabilita-incluir": {
      type: Boolean,
      default: () => false,
    },
    "desabilita-salvar": {
      type: Boolean,
      default: () => false,
    },
    "desabilita-cancelar": {
      type: Boolean,
      default: () => false,
    },
    "desabilita-limpar": {
      type: Boolean,
      default: () => false,
    },
    "carregando-salvar": {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
