const buscaDeltaClienteUseCase =
  (repository) => async (delta, filtro, idCliente) => {
    const payload = {
      page: 1,
      itemsPerPage: 1,
      idProduto: delta.idProduto,
      idBancoDeDados: delta.idBancoDeDados,
      status: filtro?.status?.value ?? null,
      idCliente,
    };

    const response = await repository(payload);

    return {
      ...response,
    };
  };

export default buscaDeltaClienteUseCase;
