<template>
  <v-form ref="cadastro">
    <v-container fluid class="pa-0">
      <v-row dense justify="center">
        <v-col cols="12">
          <v-card class="d-flex flex-column rounded-xl" elevation="0">
            <v-card-title> Aplicação de Delta </v-card-title>

            <v-card-text class="py-5">
              <v-container fluid class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      label="Produto"
                      item-text="nome"
                      item-value="id"
                      v-model="controller.delta.idProduto"
                      :items="controller.produtos.itens"
                      :disabled="controller.carregando"
                      :rules="[controller.rules.obrigatorio]"
                      @change="controller.desabilitaBancoDeDados = false"
                      outlined
                      rounded
                      dense
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      label="Banco de Dados"
                      item-text="nome"
                      item-value="id"
                      v-model="controller.delta.idBancoDeDados"
                      :items="controller.bancosDados.itens"
                      :disabled="
                        controller.carregando ||
                        controller.desabilitaBancoDeDados
                      "
                      :rules="[controller.rules.obrigatorio]"
                      @change="controller.buscaDeltasEDeltasClientes()"
                      outlined
                      rounded
                      dense
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      label="Delta para aplicação"
                      item-text="nome"
                      item-value="id"
                      v-model="controller.delta.idDelta"
                      :items="controller.deltas.itens"
                      :disabled="
                        controller.carregando ||
                        controller.desabilitaDeltaAplicacao
                      "
                      :rules="[controller.rules.obrigatorio]"
                      @change="controller.limparSelecao()"
                      outlined
                      rounded
                      dense
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12">
                    <tabela :controller="controller" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="d-flex align-center justify-end mt-auto">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="white--text font-weight-bold save-btn"
                    style="
                      background: linear-gradient(to right, #ffa64d, #fd6400);
                      color: #121934;
                    "
                    elevation="0"
                    v-on="on"
                    :loading="controller.carregando"
                    @click="controller.salvar()"
                    rounded
                  >
                    <v-icon left small> mdi-content-save </v-icon> Aplicar delta
                  </v-btn>
                </template>
                <span>Aplicar delta</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Tabela from "./tabela.vue";

export default {
  components: { Tabela },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
