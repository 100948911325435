<template>
  <div>
    <dev-tabela
      nome-tabela="Cadastros de Delta"
      mostra-pesquisa
      mostra-acoes
      alterar
      deletar
      :linhas="controller.linhas"
      :colunas="controller.colunas"
      :total-itens="controller.total"
      :carregar="controller.carregando"
      :sort-by="['nomeProduto', 'nomeBancoDeDados', 'numDelta', 'numPatch']"
      :sort-desc="[false, false, true, true]"
      @paginando="controller.paginando($event)"
      @deletar-item="controller.deletar($event)"
      @alterar-item="controller.alterar($event)"
      @linhas-selecionadas="controller.linhaSelecionada($event)"
    >
      <template v-slot:botoes>
        <div class="d-flex align-center">
          <v-row>
            <v-col>
              <div class="mr-2">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :disabled="controller.carregando"
                      max-height="36"
                      max-width="36"
                      v-on="on"
                      v-bind="attrs"
                      icon
                    >
                      <v-icon @click="controller.paginando()">
                        mdi-refresh
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Recarregar</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
          <v-tooltip bottom v-if="controller.deltaSelecionado">
            <template v-slot:activator="{ on }">
              <v-btn
                color="grey green--text lighten-4"
                class="mx-2 px-2"
                elevation="0"
                @click="controller.downloadJson()"
                v-on="on"
                x-small
                fab
              >
                <v-icon small> mdi-file-download </v-icon>
              </v-btn>
            </template>
            <span>Fazer download do arquivo JSON</span>
          </v-tooltip>

          <v-tooltip bottom v-if="controller.deltaSelecionado">
            <template v-slot:activator="{ on }">
              <v-btn
                color="grey primary--text lighten-4"
                class="mx-2 px-2"
                elevation="0"
                v-on="on"
                @click="controller.dialogPreviewDelta = true"
                x-small
                fab
              >
                <v-icon small> mdi-file-chart </v-icon>
              </v-btn>
            </template>
            <span>Visualizar texto do arquivo de delta</span>
          </v-tooltip>

          <dev-btn
            incluir
            :desabilita-incluir="controller.carregando"
            @click="controller.incluir()"
          />

          <v-menu
            v-model="controller.menuFiltro"
            :close-on-content-click="false"
            :close-on-click="false"
            :nudge-width="200"
            offset-x
            offset-y
          >
            <template v-slot:activator="{ on: onMenu, attrs: attrMenu }">
              <v-tooltip bottom>
                <template
                  v-slot:activator="{ on: onTooltip, attrs: attrTooltip }"
                >
                  <v-btn
                    class="white--text font-weight-bold mx-2 px-2"
                    style="
                      background: linear-gradient(to right, #ffa64d, #fd6400);
                      color: #121934;
                    "
                    elevation="0"
                    v-bind="{ ...attrMenu, ...attrTooltip }"
                    v-on="{ ...onMenu, ...onTooltip }"
                    :disabled="controller.carregando"
                    x-small
                    fab
                  >
                    <v-icon small>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <span>Filtrar registros</span>
              </v-tooltip>
            </template>

            <filtro :controller="controller" />
          </v-menu>
        </div>
      </template>

      <template v-slot:numDelta="{ item }">
        <td>{{ controller.padStart(item.numDelta, 3) }}</td>
      </template>

      <template v-slot:numPatch="{ item }">
        <td>{{ controller.padStart(item.numPatch, 4) }}</td>
      </template>
    </dev-tabela>
  </div>
</template>

<script>
import Filtro from "./filtro.vue";

export default {
  components: { Filtro },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
