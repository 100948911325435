import { testaAtivoBoolean } from "@/core/const/util"

const criaClienteFirebaseUseCase = (repository) => async (cliente, projeto, hash) => {
    try {
        cliente.ativo = testaAtivoBoolean(cliente.ativo)

        if (projeto === 'mais-vendas' || projeto === 'flutter-mais-vendas') {
            cliente.modulo_avaliacao_usados = testaAtivoBoolean(cliente.modulo_avaliacao_usados)
            cliente.modulo_mais_negocios = testaAtivoBoolean(cliente.modulo_mais_negocios)
            cliente.modulo_mais_pedidos = testaAtivoBoolean(cliente.modulo_mais_pedidos)
            cliente.modulo_mais_usados = testaAtivoBoolean(cliente.modulo_mais_usados)
            cliente.modulo_visitas = testaAtivoBoolean(cliente.modulo_visitas)
            cliente.modulo_documentos_do_vendedor = testaAtivoBoolean(cliente.modulo_documentos_do_vendedor)
            cliente.modulo_autorizacao_de_faturamento = testaAtivoBoolean(cliente.modulo_autorizacao_de_faturamento)
        }


        if (projeto !== 'mais-vendas' && projeto !== 'flutter-mais-vendas') {
            delete cliente.modulo_avaliacao_usados
            delete cliente.modulo_mais_negocios
            delete cliente.modulo_mais_pedidos
            delete cliente.modulo_mais_usados
            delete cliente.modulo_visitas
            delete cliente.modulo_documentos_do_vendedor
            delete cliente.modulo_autorizacao_de_faturamento
        }

        await repository(cliente, projeto, hash)
    } catch (error) {
        throw error
    }
}

export default criaClienteFirebaseUseCase