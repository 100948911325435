<template>
  <div>
    <v-toolbar flat dense color="transparent">
      <v-toolbar-title> Status servidores </v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="d-flex align-end">
        <div class="mr-2">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="controller.carregando"
                max-height="36"
                max-width="36"
                v-on="on"
                v-bind="attrs"
                icon
              >
                <v-icon @click="controller.mounted()"> mdi-refresh </v-icon>
              </v-btn>
            </template>
            <span>Recarregar servidores</span>
          </v-tooltip>
        </div>

        <div class="d-flex justify-end align-center flex-column">
          <div>Legendas:</div>
          <div class="font-weight-bold">
            <v-chip
              :color="controller.filtro.funcionando ? 'green' : 'gray'"
              :disabled="controller.carregando"
              @click="controller.filtrar('funcionando')"
              class="mx-1"
            >
              Funcionando
            </v-chip>
            <v-chip
              :color="controller.filtro.parado ? 'red' : 'gray'"
              :disabled="controller.carregando"
              @click="controller.filtrar('parado')"
              class="mx-1"
            >
              Parado
            </v-chip>
          </div>
        </div>
      </div>
    </v-toolbar>

    <v-tabs
      class="mt-5"
      right
      background-color="transparent"
      color="primary"
      v-model="controller.tab"
    >
      <v-tab :disabled="controller.carregando"> ADMRH </v-tab>
      <v-tab :disabled="controller.carregando"> CONSULTORS </v-tab>
    </v-tabs>

    <div v-if="!controller.carregando">
      <v-tabs-items v-model="controller.tab">
        <v-tab-item>
          <v-card elevation="0" color="#F4F5FA" class="py-10">
            <v-row>
              <v-col
                cols="12"
                xl="2"
                lg="3"
                md="3"
                sm="6"
                v-for="(item, index) in admrh"
                :key="index"
              >
                    <v-card
                      elevation="0"
                      :color="item.cor"
                      height="250"
                      class="d-flex justify-space-around align-center flex-column rounded-xl pa-3"
                    >
                      <div class="d-flex align-center justify-space-between" style="width: 100%;">
                        <v-tooltip
                          bottom
                          :disabled="
                            controller.buscandoStatusServidor !== item.id
                          "
                        >
                          <template #activator="{ on, attrs }">
                            <v-btn
                              v-show="
                                controller.buscandoStatusServidor !== item.id
                              "
                              v-on="on"
                              v-bind="attrs"
                              icon
                            >
                              <v-icon
                                @click="controller.buscaStatusServidor(item)"
                              >
                                mdi-refresh
                              </v-icon>
                            </v-btn>

                            <v-progress-circular
                              v-show="
                                controller.buscandoStatusServidor === item.id
                              "
                              indeterminate
                              :size="22"
                              :width="2"
                              style="margin: 7px"
                            ></v-progress-circular>
                          </template>
                          <span> Atualizar status do servidor </span>
                        </v-tooltip>

                        <v-btn
                          v-show="item.observacao"
                          icon
                        >
                          <v-icon @click="controller.infoStatus(item)">
                            mdi-information
                          </v-icon>
                        </v-btn>
                      </div>

                      <div class="font-weight-bold text-center">
                        {{ item.nome_cliente }}
                      </div>

                      <div class="font-weight-bold">
                        <v-checkbox
                          hide-details
                          dense
                          v-model="item.autenticado"
                          readonly
                          color="white"
                          inset
                          :label="
                            item.autenticado ? 'Autenticado' : 'Não Autenticado'
                          "
                        ></v-checkbox>
                      </div>
                      <div
                        class="d-flex justify-center align-center flex-column font-weight-bold"
                        style="font-size: 12px"
                      >
                      <a :href="item.pacific" target="_blank" rel="noopener noreferrer">{{ item.pacific }}</a>
                        <a class="text-center" style="width: 90%" :href="item.url" target="_blank" rel="noopener noreferrer">
                          {{ item.url }}
                        </a>
                      </div>
                      <div>
                        <div class="text-caption" :key="item.data_criacao">
                          Última consulta:
                          {{
                            dayjs
                              .utc(item.data_criacao)
                              .format("DD/MM/YYYY HH:mm")
                          }}
                        </div>
                      </div>
                    </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="0" color="#F4F5FA" class="py-10">
            <v-row>
              <v-col
                cols="12"
                xl="2"
                lg="3"
                md="3"
                sm="6"
                v-for="(item, index) in consultors"
                :key="index"
              >
                    <v-card
                      elevation="0"
                      :color="item.cor"
                      height="250"
                      class="d-flex justify-space-around align-center flex-column rounded-xl pa-3"
                    >
                      <div class="d-flex align-center justify-space-between" style="width: 100%;">
                        <v-tooltip
                          bottom
                          :disabled="
                            controller.buscandoStatusServidor !== item.id
                          "
                        >
                          <template #activator="{ on, attrs }">
                            <v-btn
                              v-show="
                                controller.buscandoStatusServidor !== item.id
                              "
                              v-on="on"
                              v-bind="attrs"
                              icon
                            >
                              <v-icon
                                @click="controller.buscaStatusServidor(item)"
                              >
                                mdi-refresh
                              </v-icon>
                            </v-btn>

                            <v-progress-circular
                              v-show="
                                controller.buscandoStatusServidor === item.id
                              "
                              indeterminate
                              :size="22"
                              :width="2"
                              style="margin: 7px"
                            ></v-progress-circular>
                          </template>
                          <span> Atualizar status do servidor </span>
                        </v-tooltip>
                        <v-btn
                          v-show="item.observacao"
                          icon
                        >
                          <v-icon @click="controller.infoStatus(item)">
                            mdi-information
                          </v-icon>
                        </v-btn>
                      </div>

                      <div class="font-weight-bold text-center">
                        {{ item.nome_cliente }}
                      </div>
                      <div class="font-weight-bold">
                        <v-checkbox
                          hide-details
                          dense
                          v-model="item.autenticado"
                          readonly
                          color="white"
                          inset
                          :label="
                            item.autenticado ? 'Autenticado' : 'Não Autenticado'
                          "
                        ></v-checkbox>
                      </div>
                      <div
                        class="d-flex justify-center align-center flex-column font-weight-bold"
                        style="font-size: 12px"
                      >
                        <a :href="item.pacific" target="_blank" rel="noopener noreferrer">{{ item.pacific }}</a>
                        <a class="text-center" style="width: 90%" :href="item.url" target="_blank" rel="noopener noreferrer">
                          {{ item.url }}
                        </a>
                      </div>
                      <div>
                        <div class="text-caption" :key="item.data_criacao">
                          Última consulta:
                          {{
                            dayjs
                              .utc(item.data_criacao)
                              .format("DD/MM/YYYY HH:mm")
                          }}
                        </div>
                      </div>
                    </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <div v-else>
      <div
        class="d-flex flex-column justify-center align-center font-weight-bold headline"
        style="height: calc(100vh - 100px)"
      >
        <div>Realizando consultas nas bases dos clientes</div>
        <dev-lottie
          class="mt-10"
          :options="controller.animacao"
          :height="300"
          :width="400"
        />
      </div>
    </div>

    <v-dialog
      v-model="controller.dialog"
      width="1024"
      content-class="rounded-xl"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="font-weight-bold">Status Servidores</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-5">
          <h3 class="black--text mb-3">Retorno</h3>
          <pre><code class="pa-5 rounded-xl" v-text="controller.textoLogs"></code></pre>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                rounded
                color="red"
                class="font-weight-bold"
                :elevation="0"
                v-on="on"
                @click="controller.cancelar()"
              >
                <v-icon left>mdi-close</v-icon> Fechar
              </v-btn>
            </template>
            <span>Fechar</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { statusServidorController } from "@/di/di";

export default {
  data: (context) => ({
    controller: statusServidorController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
  computed: {
    admrh() {
      const filtroAtual = this.controller.filtroAtual();

      if (filtroAtual.length < 2) {
        return this.controller.admrh.filter((i) => {
          if (filtroAtual.includes("parado")) {
            return i.status_code > 299;
          }

          return i.status_code < 299;
        });
      }
      return this.controller.admrh;
    },
    consultors() {
      const filtroAtual = this.controller.filtroAtual();

      if (filtroAtual.length < 2) {
        return this.controller.consultors.filter((i) => {
          if (filtroAtual.includes("parado")) {
            return i.status_code > 299;
          }

          return i.status_code < 299;
        });
      }

      return this.controller.consultors;
    },
  },
};
</script>

<style scoped>
code {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
  background-color: #212121 !important; 
  font-size: 12px;
  color: #FFF !important;
}
 
a {
  color: #000 !important;
}
</style>
