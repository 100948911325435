import Paginacao from "@/core/domain/model/paginacao"
import Handler from "@/core/domain/usecase/handler"
import Cliente from "../../domain/model/cliente"

const buscaClientesRepository = (axios) => async (paginacao) => {
    try {
        const response = await axios.get("/cliente", {
            params: paginacao
        })

        return new Paginacao(response.data, Cliente)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default buscaClientesRepository