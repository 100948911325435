export const colunas = [
  {
    text: "Data/Hora",
    align: "left",
    value: "dataCriacao",
    custom: true,
  },
  {
    text: "Usuário",
    align: "left",
    value: "nomeUsuario",
  },
  {
    text: "Ação",
    align: "left",
    value: "nomeAcao",
  },
  {
    text: "Permissão",
    align: "left",
    value: "nomePermissao",
  },
  {
    text: "Registro",
    align: "left",
    value: "registro",
  },
  {
    text: "Alterações/Inclusões",
    align: "left",
    value: "raw",
    custom: true,
  },
  {
    text: "Descrição",
    align: "left",
    value: "descricao",
  },
]