import ProxyController from "../controller/proxyController";
import { axiosInstance } from "@/plugins/axios";

import buscaProxiesRepository from "../data/repository/buscaProxiesRepository";
import buscaProxiesUseCase from "../domain/usecase/buscaProxiesUseCase";

import buscaClientesRepository from "@/module/cliente/data/repository/buscaClientesRepository";
import buscaClientesUseCase from "@/module/cliente/domain/usecase/buscaClienteUseCase";

import buscaProdutosRepository from "@/module/produto/data/repository/buscaProdutosRepository";
import buscaProdutosUseCase from "@/module/produto/domain/usecase/buscaProdutoUseCase";

import criaProxyRepository from "../data/repository/criaProxyRepository";
import criaProxyUseCase from "../domain/usecase/criaProxyUseCase";

import alteraProxyRepository from "../data/repository/alteraProxyRepository";
import alteraProxyUseCase from "../domain/usecase/alteraProxyUseCase";

import excluiProxyRepository from "../data/repository/excluiProxyRepository";
import excluiProxyUseCase from "../domain/usecase/excluiProxyUseCase";

const instance = axiosInstance;

const buscaProxiesRepositoryImpl = buscaProxiesRepository(instance);
const buscaProxiesUseCaseImpl = buscaProxiesUseCase(buscaProxiesRepositoryImpl);

const buscaClientesRepositoryImpl = buscaClientesRepository(instance);
const buscaClientesUseCaseImpl = buscaClientesUseCase(
  buscaClientesRepositoryImpl
);

const buscaProdutosRepositoryImpl = buscaProdutosRepository(instance);
const buscaProdutosUseCaseImpl = buscaProdutosUseCase(
  buscaProdutosRepositoryImpl
);

const criaProxyRepositoryImpl = criaProxyRepository(instance);
const criaProxyUseCaseImpl = criaProxyUseCase(criaProxyRepositoryImpl);

const alteraProxyRepositoryImpl = alteraProxyRepository(instance);
const alteraProxyUseCaseImpl = alteraProxyUseCase(alteraProxyRepositoryImpl);

const excluiProxyRepositoryImpl = excluiProxyRepository(instance);
const excluiProxyUseCaseImpl = excluiProxyUseCase(excluiProxyRepositoryImpl);

export const proxyController = (context) =>
  new ProxyController(
    context,
    buscaProxiesUseCaseImpl,
    buscaClientesUseCaseImpl,
    buscaProdutosUseCaseImpl,
    criaProxyUseCaseImpl,
    alteraProxyUseCaseImpl,
    excluiProxyUseCaseImpl
  );
