class GrupoUsuario {
  constructor({
    id = 0,
    nome = null,
  }) {
    this.id = id
    this.nome = nome
  }
}

export default GrupoUsuario