import Paginacao from "@/core/domain/model/paginacao"
import Handler from "@/core/domain/usecase/handler"
import ProjetoFirebase from "../../domain/model/projetoFirebase"

const buscaProjetosFirebaseRepository = (axios) => async (paginacao) => {
    try {
        const response = await axios.get('/projeto', {
            params: paginacao
        })

        return new Paginacao(response.data, ProjetoFirebase)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default buscaProjetosFirebaseRepository