class Cliente {
  constructor({
    id = 0,
    nome = null,
    chave = null,
    dataCriacao = null,
    codCliCgi = null,
  }) {
    this.id = id;
    this.nome = nome;
    this.chave = chave;
    this.dataCriacao = dataCriacao;
    this.codCliCgi = codCliCgi;
  }
}

export const defaultCliente = () =>
  new Cliente({
    id: 0,
    nome: "",
    chave: "",
    dataCriacao: null,
    codCliCgi: 0,
  });

export default Cliente;
