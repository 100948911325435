import Handler from "@/core/domain/usecase/handler"

const criaBucketRepository = (axios) => async (bucketName) => {
    try {
        await axios.post(`/storage/bucket/prod-cgi-${bucketName}`, bucketName)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default criaBucketRepository