<template>
  <div>
    <dev-tabela
      nome-tabela="Programas acessados pelo desktop"
      mostra-pesquisa
      :alterar="false"
      :deletar="false"
      :colunas="controller.colunas"
      :linhas="controller.logsDesktopPaginacao.items"
      :total-itens="controller.logsDesktopPaginacao.total"
      @paginando="controller.mounted($event)"
      :carregar="controller.carregando"
    >
      <template v-slot:botoes>
        <v-row>
          <v-col>
            <div class="mr-2">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="controller.carregando"
                    max-height="36"
                    max-width="36"
                    v-on="on"
                    v-bind="attrs"
                    icon
                  >
                    <v-icon @click="controller.atualizar()">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Recarregar</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:dataCriacao="{ item }">
        {{ dayjs(item.dataCriacao).format("DD/MM/YYYY HH:mm:ss") }}
      </template>
    </dev-tabela>
  </div>
</template>

<script>
import { logsDesktopController } from "@/di/di";

export default {
  data: (context) => ({
    controller: logsDesktopController(context),
  }),
};
</script>
