import Proxy from "../components/tabs/proxy.vue";
import Acessos from "../components/tabs/acessos.vue";
import CertificadoDigital from "../components/tabs/certificadoDigital.vue";
import BancosDados from "../components/tabs/bancosDados.vue";

export const tabs = [
  {
    nome: "Proxy",
    component: Proxy,
  },
  {
    nome: "Acessos",
    component: Acessos,
  },
  {
    nome: "Certificado Digital",
    component: CertificadoDigital,
  },
  {
    nome: "Bancos de Dados",
    component: BancosDados,
  },
];
