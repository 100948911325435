const buscaDeltasUseCase = (repository) => async (paginacao, filtro) => {
  const payload = {
    ...paginacao,
    sort: paginacao?.sortBy?.join(",") ?? null,
    descending: paginacao?.sortDesc?.join(",") ?? null,
    idProduto: filtro?.idProduto ?? null,
    idBancoDeDados: filtro?.idBancoDeDados ?? null,
  };

  return await repository(payload);
};

export default buscaDeltasUseCase;
