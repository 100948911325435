import { getToken, setJwtAuthToken } from '@/core/domain/model/jwtAuthToken';

const refreshUseCase = (repository) => async () => {
    try {
        const jwtAuthToken = getToken()
        const newJwtAuthToken = await repository(jwtAuthToken)
        setJwtAuthToken(newJwtAuthToken)
        
        return true
    } catch (error) {
        return false
    }
}

export default refreshUseCase