export const colunas = [
  {
    text: "Cliente",
    value: "nomeCliente",
    align: "start",
  },
  {
    text: "Usa NFE",
    value: "usaNfe",
    align: "start",
    custom: true,
  },
  {
    text: "Usa CTE",
    value: "usaCte",
    align: "start",
    custom: true,
  },
  {
    text: "Vencimento",
    value: "vencimento",
    align: "start",
    custom: true,
  },
];
