export const colunas = [
  {
    text: "Cliente",
    align: "left",
    value: "nomeCliente",
  },
  {
    text: "Programa",
    align: "left",
    value: "programa",
  },
  {
    text: "Módulo",
    align: "left",
    value: "modulo",
  },
  {
    text: "Processo",
    align: "left",
    value: "processo",
  },
  {
    text: "Versão",
    align: "left",
    value: "versao",
  },
  {
    text: "Data/Hora",
    align: "left",
    value: "dataCriacao",
    custom: true
  },
]