<template>
  <div>
    <tabela :controller="controller" />

    <dialog-form :controller="controller" ref="form" />
    <dialog-preview-delta :controller="controller" />
  </div>
</template>

<script>
import { cadastroDeltaController } from "../di/di";

import Tabela from "../components/tabela.vue";
import DialogForm from "../components/dialogForm.vue";
import DialogPreviewDelta from "../components/dialogPreviewDelta.vue";

export default {
  components: { Tabela, DialogForm, DialogPreviewDelta },
  data: (context) => ({
    controller: cadastroDeltaController(context),
  }),
};
</script>
