import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "./core/const/permissionsEnum.js";

import DevTabela from "./components/devTabela.vue";
import DevBtn from "./components/devBtn.vue";
import DevDialog from "./components/devDialog.vue";
import DevLottie from "./components/devLottie.vue";
import dayjs from "dayjs";
import DevSnackbar from "./components/devSnackbar";
import DevAlert from "./components/devAlert";
import DevCardsList from "./components/devCardsList.vue";

import { vMaska } from "maska";

import { hadPermission } from "./core/domain/model/permissions.js";

import "prismjs/themes/prism-okaidia.css";
import "./registerServiceWorker";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

Vue.config.productionTip = false;

Vue.component("dev-tabela", DevTabela);
Vue.component("dev-btn", DevBtn);
Vue.component("dev-dialog", DevDialog);
Vue.component("dev-lottie", DevLottie);
Vue.component("dev-snackbar", DevSnackbar);
Vue.component("dev-alert", DevAlert);
Vue.component("dev-cards-list", DevCardsList);

Vue.prototype.defaultNavigator = null;
Vue.prototype.dayjs = dayjs;
Vue.prototype.acl = Vue.observable({ permissions: null });
Vue.prototype.hadPermission = hadPermission;

Vue.directive("maska", vMaska);

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
