import Login from '@/module/login/view/Login.vue'
import Home from '@/module/home/view/Home.vue'
import Vue from 'vue'
import { isUserLogged, trocarSenha } from '@/core/domain/model/jwtAuthToken'

class DefaultController {
  component = null
  navigateEnum = {
    home: Home,
    login: Login,
  }

  mounted() {
    Vue.prototype.defaultNavigator = this

    if (!isUserLogged() || trocarSenha()) {
      this.component = Login

      return
    }

    this.component = Home
  }

  navigate(screen) {
    this.component = this.navigateEnum[screen]
  }
}

export default DefaultController