const configuracaoProxy = [
  { text: "URL Proxy:", value: "url", custom: true },
  { text: "Pacific:", value: "pacific", custom: true },
  { text: "URL Front:", value: "urlFrontend", custom: true },
];

const configuracaoAcessos = [
  { text: "Usuario:", value: "usuario" },
  { text: "Senha:", value: "senha" },
  { text: "URL", value: "proxyURL" },
];

const configuracaoCertificadoDigital = [
  { text: "Vencimento:", value: "vencimento" },
];

export {
  configuracaoProxy,
  configuracaoAcessos,
  configuracaoCertificadoDigital,
};
