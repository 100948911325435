const buscaCertificadoDigitalUseCase =
  (repository) => async (itensPagina, qtdPagina, id) => {
    try {
      const payload = {
        page: qtdPagina ?? 1,
        itemsPerPage: itensPagina ?? 1000,
        idCliente: id ?? 0,
      };

      return await repository(payload);
    } catch (error) {
      throw error;
    }
  };

export default buscaCertificadoDigitalUseCase;
