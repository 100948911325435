import Handler from "@/core/domain/usecase/handler"

const alteraProjetoFirebaseRepository = (axios) => async (projetoFirebase) => {
    try {
        await axios.put(`/projeto/${projetoFirebase.id}`, projetoFirebase)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default alteraProjetoFirebaseRepository