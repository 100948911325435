import animationData from "../../../assets/search.json";
import ClientesPorApp from "../domain/model/clientesPorApp";
import { colunasAplicativos } from "../const/colunasAplicativos";
import { colunasClientes } from "../const/colunasClientes";

class ClientesPorAppController {
  modelClientes = new ClientesPorApp({});
  carregando = false;
  animacao = { animationData };
  itens = [];
  clientesPorApp = [];
  clienteSelecionado = null;
  filtroSelecionado = null;
  aplicativoSelecionado = null;
  colunasAplicativos = colunasAplicativos;
  colunasClientes = colunasClientes;
  clientes = {
    itens: [],
  };
  projetos = {
    itens: [],
  };
  filtroSelecionado = "";
  opcoesFiltro = [
    { text: "Clientes", value: "clientes" },
    { text: "Aplicativos", value: "aplicativos" },
  ];

  constructor(
    context,
    buscaClientesPorAppUseCase,
    buscaProjetosUseCase,
    buscaClientesUseCase
  ) {
    this.context = context;
    this.buscaClientesPorAppUseCase = buscaClientesPorAppUseCase;
    this.buscaProjetosUseCase = buscaProjetosUseCase;
    this.buscaClientesUseCase = buscaClientesUseCase;
  }

  async mounted() {
    try {
      this.carregando = true;

      this.clientes = await this.buscaClientesUseCase({
        itemsPerPage: 1000,
        page: 1,
      });
      this.projetos = await this.buscaProjetosUseCase({
        itemsPerPage: 1000,
        page: 1,
      });
      const clientesPorApp = await this.buscaClientesPorAppUseCase({
        itemsPerPage: 1000,
        page: 1,
      });
      this.clientesPorApp = clientesPorApp.itens;
    } catch (err) {
      console.error("Erro ao buscar clientes e projetos:", err);
    } finally {
      this.carregando = false;
    }
  }

  filtrarItens() {
    if (this.filtroSelecionado === "clientes" && this.clienteSelecionado) {
      const clienteSelecionado = this.clientesPorApp.filter(
        (item) => item.idCliente === parseInt(this.clienteSelecionado)
      );
      this.itens = clienteSelecionado;
    } else if (
      this.filtroSelecionado === "aplicativos" &&
      this.aplicativoSelecionado
    ) {
      const projetoSelecionado = this.clientesPorApp.filter(
        (item) => item.idProjeto === parseInt(this.aplicativoSelecionado)
      );
      this.itens = projetoSelecionado;
    }
  }
}

export default ClientesPorAppController;
