<template>
  <div v-if="controller.carregando">
    <div
      class="d-flex flex-column justify-center align-center font-weight-bold headline"
      style="height: calc(100vh - 100px)"
    >
      <div>Buscando informações, isso pode demorar um pouco...</div>
      <dev-lottie
        class="mt-10"
        :options="controller.animacao"
        :height="300"
        :width="400"
      />
    </div>
  </div>
  <div v-else>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="3">
        <v-select
          label="Filtrar por:"
          :items="controller.opcoesFiltro"
          v-model="controller.filtroSelecionado"
          @change="controller.filtrarItens()"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="3" v-if="controller.filtroSelecionado === 'clientes'">
        <v-autocomplete
          label="Selecionar Cliente"
          :items="controller.clientes.itens"
          item-text="nome"
          item-value="id"
          v-model="controller.clienteSelecionado"
          @change="controller.filtrarItens()"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="3" v-if="controller.filtroSelecionado === 'aplicativos'">
        <v-autocomplete
          label="Selecionar Aplicativo"
          :items="controller.projetos.itens"
          item-text="nome"
          item-value="id"
          v-model="controller.aplicativoSelecionado"
          @change="controller.filtrarItens()"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
    </v-row>
    <dev-tabela
      v-if="
        controller.filtroSelecionado === 'clientes' &&
        controller.clienteSelecionado
      "
      :mostra-paginacao="false"
      nome-tabela="Aplicativos por Cliente"
      :colunas="controller.colunasClientes"
      :linhas="controller.itens"
      :carregar="controller.carregando"
      :deletar="false"
      :alterar="false"
      altura="calc(100vh - 240px)"
    >
      <template v-slot:ativo="{ item }">
        <v-chip :color="item.ativo === true ? 'green' : 'red'" small>
          {{ item.ativo === true ? "Sim" : "Não" }}
        </v-chip>
      </template>
    </dev-tabela>
    <dev-tabela
      v-if="
        controller.filtroSelecionado === 'aplicativos' &&
        controller.aplicativoSelecionado
      "
      :mostra-paginacao="false"
      nome-tabela="Clientes por Aplicativo"
      :colunas="controller.colunasAplicativos"
      :linhas="controller.itens"
      :carregar="controller.carregando"
      :deletar="false"
      :alterar="false"
      altura="calc(100vh - 240px)"
    >
      <template v-slot:ativo="{ item }">
        <v-chip :color="item.ativo === true ? 'green' : 'red'" small>
          {{ item.ativo === true ? "Sim" : "Não" }}
        </v-chip>
      </template>
    </dev-tabela>
  </div>
</template>

<script>
export default {
  props: {
    controller: { type: Object, required: true },
  },
};
</script>
