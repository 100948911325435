import Handler from "@/core/domain/usecase/handler"
import ClienteFirebase from "../../domain/model/clienteFirebase"

const buscaClientesFirebaseRepository = (axios) => async (projeto) => {
    try {
        const response = await axios.get(`/firebase/${projeto}`)

        return response.data?.map((cliente) => new ClienteFirebase(cliente)) ?? []
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default buscaClientesFirebaseRepository