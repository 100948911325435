import Prism from "prismjs";
import { snackbar } from "@/core/const/devSnackbar";

import AplicacaoDelta from "../domain/model/aplicacaoDelta";
import { defaultPaginacao } from "@/core/domain/model/paginacao";
import { colunas } from "../const/headers";
import { filtro } from "../const/filtro";

class AplicacaoDeltaControler {
  carregando = true;
  menuFiltro = false;
  dialogPreviewLog = false;
  logDelta = null;
  colunas = colunas;
  filtro = filtro;
  delta = {};
  deltasClientes = [];
  produtos = defaultPaginacao();
  deltas = defaultPaginacao();
  bancosDados = defaultPaginacao();
  desabilitaBancoDeDados = true;
  desabilitaDeltaAplicacao = true;
  rules = {
    obrigatorio: (v) => !!v || "Preencha o campo",
  };
  datatableKey = 0;

  constructor(
    context,
    buscaProdutosUseCase,
    buscaBancosDadosUseCase,
    buscaDeltasUseCase,
    buscaDeltasClientesUseCase,
    buscaDeltaClienteUseCase,
    aplicaDeltaUseCaseImpl
  ) {
    this.context = context;
    this.buscaProdutosUseCase = buscaProdutosUseCase;
    this.buscaBancosDadosUseCase = buscaBancosDadosUseCase;
    this.buscaDeltasUseCase = buscaDeltasUseCase;
    this.buscaDeltasClientesUseCase = buscaDeltasClientesUseCase;
    this.buscaDeltaClienteUseCase = buscaDeltaClienteUseCase;
    this.aplicaDeltaUseCaseImpl = aplicaDeltaUseCaseImpl;
  }

  async mounted() {
    try {
      this.carregando = true;

      await Promise.all([this.buscaProdutos(), this.buscaBancosDados()]);
    } finally {
      this.carregando = false;
    }
  }

  async salvar() {
    try {
      if (!this.validaFormulario()) {
        return;
      }

      if (!this.delta?.clientes?.length) {
        snackbar.show({
          message: "Selecione pelo menos um cliente para aplicar o delta!",
          color: "warning",
          timeout: 2000,
        });
        return;
      }

      this.carregando = true;

      const { message } = await this.aplicaDeltaUseCaseImpl(
        new AplicacaoDelta(this.delta)
      );

      snackbar.show({ message, color: "green", timeout: 2000 });

      await this.buscaDeltasClientes();
      this.limparSelecao();
    } finally {
      this.carregando = false;
    }
  }

  async buscaProdutos() {
    this.produtos = await this.buscaProdutosUseCase();
  }

  async buscaBancosDados() {
    this.bancosDados = await this.buscaBancosDadosUseCase();
  }

  async buscaDeltasEDeltasClientes() {
    try {
      this.carregando = true;
      await Promise.all([this.buscaDeltas(), this.buscaDeltasClientes()]);
    } finally {
      this.carregando = false;
      this.desabilitaDeltaAplicacao = false;
    }
  }

  async buscaDeltas(search) {
    if (!this.delta.idProduto && !this.delta.idBancoDeDados) {
      return;
    }

    this.deltas = await this.buscaDeltasUseCase(
      this.delta.idProduto,
      this.delta.idBancoDeDados,
      search
    );
  }

  async buscaDeltasClientes() {
    if (!this.delta.idProduto || !this.delta.idBancoDeDados) {
      return;
    }

    this.deltasClientes = await this.buscaDeltasClientesUseCase(
      this.delta,
      this.filtro
    );
  }

  async atualizar() {
    try {
      this.carregando = true;

      this.deltasClientes = await this.buscaDeltasClientesUseCase(
        this.delta,
        this.filtro
      );
    } finally {
      this.carregando = false;
    }
  }

  async filtrar() {
    try {
      this.carregando = true;

      await this.buscaDeltasClientes();
    } finally {
      this.carregando = false;
    }
  }

  async buscaDeltaCliente({ id }) {
    try {
      this.carregando = true;

      const indexOf = this.deltasClientes.findIndex((item) => item.id === id);

      if (indexOf !== -1) {
        const response = await this.buscaDeltaClienteUseCase(
          this.delta,
          this.filtro,
          id
        );

        this.deltasClientes.splice(indexOf, 1, response);
      }
    } finally {
      this.carregando = false;
    }
  }

  async resetaValidacaoFormulario() {
    await this.context.$nextTick();
    this.context.$refs.form.$refs.cadastro.resetValidation();
  }

  validaFormulario() {
    return this.context.$refs.form.$refs.cadastro.validate();
  }

  normalizeNomeDelta(nomeDelta) {
    if (!nomeDelta) {
      return nomeDelta;
    }

    const splitted = nomeDelta.split("/");
    const numDelta = this.padStart(splitted.at(0), 3);
    const numPatch = this.padStart(splitted.at(1), 4);

    return `${numDelta}/${numPatch}`;
  }

  abreDialogPreviewLog(log) {
    if (this.carregando) {
      return;
    }

    try {
      this.logDelta = Prism.highlight(
        JSON.stringify(JSON.parse(log), null, 4),
        Prism.languages.json
      );
    } catch (error) {
      this.logDelta = Prism.highlight(log, Prism.languages.txt);
    } finally {
      this.dialogPreviewLog = true;
    }
  }

  cancelaDialogPreviewLog() {
    this.logDelta = null;
    this.dialogPreviewLog = false;
  }

  padStart(value, length, fillStr = "0") {
    return String(value).padStart(length, fillStr);
  }

  cancelarFiltro() {
    this.menuFiltro = false;
  }

  limparFiltro() {
    this.filtro.status.value = null;
  }

  limparSelecao() {
    this.delta.clientes = [];
    this.datatableKey++;
  }

  getColor(item) {
    if (item === "Erro") {
      return "background-color: red; color: white;";
    } else {
      return "background-color: green; color: white;";
    }
  }
}

export default AplicacaoDeltaControler;
