class LogsApps {
  constructor({
    aplicativo = null,
    chamada = null,
    cliente = null,
    dataCriacao = null,
    id = 0,
    metodo = null,
    programa = null,
    resposta = null,
    url = null,
    usuario = null,
    versao = null
  }) {
    this.aplicativo = aplicativo
    this.chamada = chamada
    this.cliente = cliente
    this.dataCriacao = dataCriacao
    this.id = id
    this.metodo = metodo
    this.programa = programa
    this.resposta = resposta
    this.url = url
    this.usuario = usuario
    this.versao = versao
  }
}

export const defaultLogsApp = () => new LogsApps({
  aplicativo: null,
  chamada: null,
  cliente: null,
  dataCriacao: null,
  id: 0,
  metodo: null,
  programa: null,
  resposta: null,
  url: null,
  usuario: null,
  versao: null
})

export default LogsApps