const buscaLogsAppUseCase = (repository) => async (paginacao) => {
  try {
    paginacao['sort'] = paginacao.sortBy.join(",")
    paginacao['descending'] = paginacao.sortDesc.join(",")

    const logsApp = await repository(paginacao)
    return logsApp

  } catch (error) {
    throw error
  }
}

export default buscaLogsAppUseCase