import Handler from "@/core/domain/usecase/handler";

const criaUsuarioRepository = (axios) => async (usuario) => {
  try {
    await axios.post("/usuario", usuario)
  } catch (error) {
    throw new Handler().trataErro(error)
  }
}

export default criaUsuarioRepository