import Vue from "vue"
import { getAccountGroupID } from "./jwtAuthToken";

export function hadPermission(permissionID, idAcao) {
  const accountGroupID = getAccountGroupID()
  
  if (!permissionID) {
    return true;
  }

  if (accountGroupID === 1) {
    return true
  }

  if (Vue.prototype.acl.permissions) {
    const had = Vue.prototype.acl.permissions.some((permission) => permission.idAcao == idAcao && permission.idPermissao == permissionID)

    return had
  }

  return false
}