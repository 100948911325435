<template>
  <div>
    <dev-tabela
      nome-tabela="Logs de erro dos aplicativos"
      mostra-pesquisa
      :alterar="false"
      :deletar="false"
      :colunas="controller.colunas"
      :linhas="controller.logsAppPaginacao.itens"
      :total-itens="controller.logsAppPaginacao.total"
      @paginando="controller.mounted($event)"
      @linhas-selecionadas="controller.linhaSelecionada($event)"
      :carregar="controller.carregando"
      :sortBy="['dataCriacao']"
      :sortDesc="['true']"
    >
      <template v-slot:dataCriacao="{ item }">
        {{ dayjs.utc(item.dataCriacao).format("DD/MM/YYYY HH:mm:ss") }}
      </template>
      <template v-slot:url="{ item }">
        <v-chip :color="item.url ? 'primary' : 'transparent'" small>{{
          item.url
        }}</v-chip>
      </template>
      <template v-slot:metodo="{ item }">
        <v-chip :color="item.metodo ? 'orange' : 'transparent'" small>{{
          item.metodo
        }}</v-chip>
      </template>

      <template v-slot:botoes>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-row>
              <v-col>
                <div class="mr-2">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        :disabled="controller.carregando"
                        max-height="36"
                        max-width="36"
                        v-on="on"
                        v-bind="attrs"
                        icon
                      >
                        <v-icon @click="controller.atualizar()">
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Recarregar</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-btn
              v-show="controller.logsApp.chamada || controller.logsApp.resposta"
              icon
              x-small
              fab
              class="white--text font-weight-bold"
              color="grey lighten-4"
              style="
                background: linear-gradient(to right, #ffa64d, #fd6400);
                color: #121934;
              "
              v-on="on"
              :elevation="0"
              @click="controller.clickLogs()"
            >
              <v-icon small>mdi-file-chart</v-icon>
            </v-btn>
          </template>
          <span>Visualizar retorno dos logs</span>
        </v-tooltip>
      </template>
    </dev-tabela>

    <v-dialog
      v-model="controller.dialog"
      width="1024"
      content-class="rounded-xl"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="font-weight-bold">Retorno Logs</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-5">
          <h3 class="black--text mb-3">Dados enviados</h3>
          <pre><code class="language-json pa-5 rounded-xl" style="background-color: #212121;  font-size: 10px" v-html="controller.logsApp.chamada"></code></pre>

          <v-divider class="mt-3 mb-3"></v-divider>

          <h3 class="black--text mb-3">Retorno</h3>
          <pre><code class="language-json pa-5 rounded-xl" style="background-color: #212121;  font-size: 10px" v-html="controller.logsApp.resposta"></code></pre>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                rounded
                color="red"
                class="font-weight-bold"
                :elevation="0"
                v-on="on"
                @click="controller.cancelar()"
              >
                <v-icon left>mdi-close</v-icon> Fechar
              </v-btn>
            </template>
            <span>Fechar</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { logsAppController } from "@/di/di";

export default {
  data: (context) => ({
    controller: logsAppController(context),
  }),
};
</script>

<style scoped>
code {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
}
:deep(.token.punctuation) {
  color: #ffffff;
}
</style>
