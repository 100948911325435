import { defaultLogsDesktop } from "../domain/model/logsDesktop";
import { defaultPaginacao } from "@/core/domain/model/paginacao";
import { colunas } from "../const/colunas";

class LogsDesktopController {
  colunas = colunas
  logsDesktopPaginacao = defaultPaginacao()
  logsDesktop = defaultLogsDesktop()
  paginacao = {}
  carregando = false

  constructor(
    context,
    buscaLogsDesktopUseCase
  ) {
    this.context = context
    this.buscaLogsDesktopUseCase = buscaLogsDesktopUseCase
  }

  async mounted(paginacao) {
    try {
      this.paginacao = paginacao
      this.logsDesktopPaginacao = await this.buscaLogsDesktopUseCase({ ...paginacao })
    } catch (error) {
      // TODO
    }
  }

  async atualizar() {
    try {
      this.carregando = true
      this.logsDesktopPaginacao = await this.buscaLogsDesktopUseCase(this.paginacao);
    } finally {
      this.carregando = false
    }
  }

}

export default LogsDesktopController