const buscaBancosDadosUseCase = (repository) => async (paginacao) => {
  const payload = {
    ...paginacao,
    sort: paginacao?.sortBy?.join(",") ?? null,
    descending: paginacao?.sortDesc?.join(",") ?? null,
  };

  return await repository(payload);
};

export default buscaBancosDadosUseCase;
