<template>
  <v-app>
    <div style="background-color: #f4f5fa">
      <app-bar v-if="!controller.loading" :controller="controller" />
      <navigation-drawer :controller="controller" v-if="$vuetify.breakpoint.smAndDown"/>
      <v-main>
        <div class="ma-5 mt-6" >
          <component :is="controller.component" />
        </div>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { homeController } from "@/di/di";
import AppBar from "../component/AppBar.vue";
import NavigationDrawer from "../component/NavigationDrawer.vue";
export default {
  components: { AppBar, NavigationDrawer },
  data: (context) => ({
    controller: homeController(context),
  }),

  async mounted() {
    await this.controller.mounted();
  },
};
</script>

<style scoped>
.v-btn-menu:hover {
  font-size: 15px;
}
</style>