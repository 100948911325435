const buscaDeltasUseCase =
  (repository) => async (idProduto, idBancoDeDados) => {
    const payload = {
      page: 1,
      itemsPerPage: 1000,
      sort: ["numDelta", "numPatch"].join(","),
      descending: [true, true].join(","),
      idProduto,
      idBancoDeDados,
    };

    return await repository(payload);
  };

export default buscaDeltasUseCase;
