import Handler from '@/core/domain/usecase/handler';
import axios from 'axios';

const loginRepostory = (baseUrl) => async (email, senha) => {
  try {
    const response = await axios.post(`${baseUrl}/sessao`, {
      "email": email,
      "senha": senha
    })

    return response.data
  } catch (err) {
    throw new Handler().trataErro(err)
  }
}

export default loginRepostory