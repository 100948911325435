import axios from 'axios';

const refreshRepostory = (baseUrl) => async (jwtAuthToken) => {
    try {
        const response = await axios.get(baseUrl + '/sessao', {
            headers: {
                authorization: `Bearer ${jwtAuthToken.token} ${jwtAuthToken.refresh} ${jwtAuthToken.type}`
            }
        })

        const newJwtAuthToken = response.data
        return newJwtAuthToken
    } catch (error) {
        throw error
    }
}

export default refreshRepostory