import { defaultPaginacao } from "@/core/domain/model/paginacao";
import { colunas } from "../const/colunas";
import { defaultAcessoApp } from "../domain/model/acessoApp";
import _ from "lodash";
import { numberRule0And999 } from "@/core/const/rules";
import { defaultCliente } from "@/module/cliente/domain/model/cliente";
import { snackbar } from "@/core/const/devSnackbar";
import { alert } from "@/core/const/devAlert";

class AcessoAppController {
  dialog = false;
  colunas = colunas;
  chavesAcessoPaginacao = defaultPaginacao();
  clientePaginacao = defaultPaginacao();
  produtoPaginacao = defaultPaginacao();
  projetoPaginacao = defaultPaginacao();
  proxyPaginacao = defaultPaginacao();
  acessoApp = defaultAcessoApp();
  paginacao = {};
  numberRule0And999 = numberRule0And999;
  exibeCamposMaisVendas = false;
  projetoMaisVendas = "mais-vendas";
  projetoMaisVendasFlutter = "flutter-mais-vendas";
  cliente = null;
  carregando = false

  required = (v) => !!v || "Preencha o campo";

  constructor(
    context,
    buscaAcessosAppsUseCase,
    criaAcessoAppUseCase,
    alteraAcessoAppUseCase,
    excluiAcessoAppUseCase,
    buscaClientesUseCase,
    buscaProjetosFirebaseUseCase,
    buscaProxiesUseCase
  ) {
    this.context = context;
    this.buscaAcessosAppsUseCase = buscaAcessosAppsUseCase;
    this.criaAcessoAppUseCase = criaAcessoAppUseCase;
    this.alteraAcessoAppUseCase = alteraAcessoAppUseCase;
    this.excluiAcessoAppUseCase = excluiAcessoAppUseCase;
    this.buscaClientesUseCase = buscaClientesUseCase;
    this.buscaProjetosFirebaseUseCase = buscaProjetosFirebaseUseCase;
    this.buscaProxiesUseCase = buscaProxiesUseCase;
  }

  async mounted(paginacao) {
    try {
      this.paginacao = paginacao;
      this.chavesAcessoPaginacao = await this.buscaAcessosAppsUseCase({
        ...paginacao,
      });
      await this.buscaProjetos(null);
    } catch (error) {
      //snackbar.show({message: error})
    }
  }

  async atualizar() {
    try {
      this.carregando = true
      this.chavesAcessoPaginacao = await this.buscaAcessosAppsUseCase(this.paginacao);
    } finally {
      this.carregando = false
    }
  }

  async adicionarRegistro() {
    try {
      this.cliente = defaultCliente();
      await Promise.all([this.buscaClientes(null), this.buscaProjetos(null)]);
      this.dialog = true;
    } catch (error) {
      //snackbar.show({message: error})
    }
  }

  async alterarRegistro(item) {
    try {
      this.cliente = { id: item.idCliente, nome: item.nomeCliente };
      this.acessoApp = { ...item };
      await Promise.all([
        this.buscaClientes(item.nomeCliente),
        this.buscaProjetos(item.nomeProjeto),
        this.buscaProxies(this.acessoApp, true),
      ]);

      this.validaCamposExtrasMaisVendas(this.acessoApp.idProjeto);
      this.dialog = true;
    } catch (error) {
      //snackbar.show({message: error})
    }
  }

  cancelar() {
    this.acessoApp = defaultAcessoApp();
    this.context.$refs.form.resetValidation();
    this.exibeCamposMaisVendas = false;
    this.dialog = false;
  }

  buscaClientesDebounce = _.debounce(async (search) => {
    this.buscaClientes(search);
  }, 500);

  buscaProjetosDebounce = _.debounce(async (search) => {
    this.buscaProjetos(search);
  }, 500);

  async buscaClientes(search) {
    this.clientePaginacao = await this.buscaClientesUseCase({
      itemsPerPage: 10,
      page: 1,
      search: search,
      sortBy: ["nome"],
      sortDesc: [false],
      searchFields: "nome",
    });
  }

  async buscaProjetos(search) {
    this.projetoPaginacao = await this.buscaProjetosFirebaseUseCase({
      itemsPerPage: 10,
      page: 1,
      search: search,
      sortBy: ["nome"],
      sortDesc: [false],
      searchFields: "nome",
    });
  }

  async buscaProxies(cliente, changeItem = false) {
    let { nomeCliente, idCliente } = cliente ?? {};
    if (!changeItem) {
      this.acessoApp.idProxy = null;
    }

    if (!nomeCliente) {
      nomeCliente = cliente?.nome ?? null;
    }

    this.acessoApp.idCliente = idCliente ?? cliente?.id;
    this.proxyPaginacao = await this.buscaProxiesUseCase({
      itemsPerPage: 1000,
      page: 1,
      search: nomeCliente,
      sortBy: [],
      sortDesc: [],
      searchFields: "nomeCliente",
    });
  }

  async salvar() {
    try {
      if (this.context.$refs.form.validate()) {
        if (this.acessoApp.id === 0) {
          await this.criaAcessoAppUseCase(this.acessoApp);
        }

        if (this.acessoApp.id > 0) {
          await this.alteraAcessoAppUseCase(this.acessoApp);
        }

        snackbar.show({
          message: "Registro salvo com sucesso!",
          color: "green",
          timeout: 3000,
        });

        this.cancelar();
        this.mounted(this.paginacao);
      }
    } catch (error) {
      //snackbar.show({message: error})
    }
  }

  async excluirRegistro(item) {
    try {
      const deletar = await alert.confirm({
        message: "Deseja realmente excluir o registro?",
        color: "red",
      });

      if (deletar) {
        await this.excluiAcessoAppUseCase(item.id);
      }

      this.mounted(this.paginacao);
    } catch (error) {
      //snackbar.show({message: error})
    }
  }

  async copiarToken(event) {
    try {
      await navigator.clipboard.writeText(event);

      snackbar.show({
        message: "Copiado para a área de transferência!",
        color: "primary",
        timeout: 3000,
      });
    } catch (error) {
      //snackbar.show({message: error})
    }
  }

  async validaCamposExtrasMaisVendas(projetoId) {
    this.exibeCamposMaisVendas = false;
    if (!projetoId) {
      return;
    }

    const registros = await this.buscaProjetosFirebaseUseCase({
      itemsPerPage: 1,
      page: 1,
      search: projetoId,
      searchFields: "id",
    });

    if (registros.itens.length > 0) {
      const projetoSelecionado = registros.itens[0];

      if (
        projetoSelecionado.firebase === this.projetoMaisVendas ||
        projetoSelecionado.firebase === this.projetoMaisVendasFlutter
      ) {
        this.exibeCamposMaisVendas = true;
      }
    }
  }
}

export default AcessoAppController;
