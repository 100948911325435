import Handler from "@/core/domain/usecase/handler"

const criaProjetoFirebaseRepository = (axios) => async (projetoFirebase) => {
    try {
        await axios.post("/projeto", projetoFirebase)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default criaProjetoFirebaseRepository