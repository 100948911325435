<template>
  <div>
    <dev-tabela
      nome-tabela="Usuários"
      mostra-acoes
      mostra-pesquisa
      @paginando="controller.mounted($event)"
      @alterar-item="controller.alterarRegistro($event)"
      @deletar-item="controller.excluirRegistro($event)"
      :colunas="controller.colunas"
      :linhas="controller.usuariosPaginacao.itens"
      :total-itens="controller.usuariosPaginacao.total"
      :alterar="hadPermission(usuarioPermissionId, alterActionId)"
      :deletar="hadPermission(usuarioPermissionId, deleteActionId)"
      :carregar="controller.carregando"
    >
      <template v-slot:botoes>
        <v-row>
          <v-col>
            <div class="mr-2">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="controller.carregando"
                    max-height="36"
                    max-width="36"
                    v-on="on"
                    v-bind="attrs"
                    icon
                  >
                    <v-icon @click="controller.atualizar()">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Recarregar</span>
              </v-tooltip>
            </div>
          </v-col>

          <v-col>
            <dev-btn
              v-if="hadPermission(usuarioPermissionId, createActionId)"
              incluir
              @click="controller.adicionarRegistro()"
            />
          </v-col>
        </v-row>
      </template>
    </dev-tabela>

    <dev-dialog
      titulo="Cadastro"
      :dialog="controller.dialog"
      @cancelar="controller.cancelar()"
      @salvar="controller.salvar()"
    >
      <template v-slot:form>
        <v-form ref="form">
          <v-switch
            label="Bloqueado"
            inset
            dense
            hide-details
            v-model="controller.usuario.bloqueado"
          >
          </v-switch>
          <v-switch
            label="Trocar a senha no primeiro acesso"
            class="pb-4"
            inset
            dense
            hide-details
            v-model="controller.usuario.trocarSenha"
          >
          </v-switch>
          <v-text-field
            label="Sigla"
            dense
            outlined
            rounded
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model="controller.usuario.login"
          >
          </v-text-field>
          <v-text-field
            label="E-mail"
            type="email"
            dense
            outlined
            rounded
            v-model="controller.usuario.email"
            :rules="[(v) => !!v || 'Preencha o campo']"
          >
          </v-text-field>
          <v-autocomplete
            label="Grupo de Usuários"
            item-text="nome"
            item-value="id"
            rounded
            dense
            outlined
            v-model="controller.usuario.idGrupoUsuario"
            @update:search-input="controller.buscaGrupoUsuario($event)"
            :rules="[(v) => !!v || 'Preencha o campo']"
            :items="controller.grupoUsuarioPaginacao.itens"
          >
          </v-autocomplete>
          <v-text-field
            label="Senha"
            dense
            outlined
            rounded
            v-model="controller.usuario.senha"
            @click:append="controller.exibirSenha = !controller.exibirSenha"
            :append-icon="controller.exibirSenha ? 'mdi-eye' : 'mdi-eye-off'"
            :type="controller.exibirSenha ? 'text' : 'password'"
            :rules="
              controller.usuario.id === 0
                ? [(v) => !!v || 'Preencha o campo']
                : []
            "
          >
          </v-text-field>
        </v-form>
      </template>
    </dev-dialog>
  </div>
</template>

<script>
import { usuarioController } from "@/di/di";

export default {
  data: (context) => ({
    controller: usuarioController(context),
  }),
};
</script>
