import _ from "lodash";

import { defaultPaginacao } from "@/core/domain/model/paginacao";
import { snackbar } from "@/core/const/devSnackbar";
import { alert } from "@/core/const/devAlert";
import { defaultProxy } from "@/module/proxy/domain/model/proxy";
import { numberRule0And999 } from "@/core/const/rules";

import BancoDados from "../domain/model/bancoDados";
import * as detalhes from "../const/detalhes";
import { colunas } from "../const/colunas";
import { defaultCliente } from "../domain/model/cliente";
import { defaultAcessoApp } from "@/module/acessoApp/domain/model/acessoApp";
import { defaultCertificadoDigital } from "@/module/certificadoDigital/domain/model/certificadoDigital";

import animationData from "../../../assets/search.json";

class ClienteController {
  carregando = false;
  dialog = false;
  dialogDetalhes = false;

  tabDetalhes = 0;

  detalhes = detalhes;
  colunas = colunas;
  clientePaginacao = defaultPaginacao();
  mostraAcoes = false;
  mostraBotaoModulos = false;

  cliente = defaultCliente();
  clientes = [];
  clienteSelecionado = null;
  paginacao = {};

  rules = {
    numberRule0And999,
    obrigatorio: (v) => !!v || "Preencha o campo",
    subdominio: (v) =>
      /^[a-zA-Z0-9\s,.;:'"_()\[\]{}]*$/.test(v) ||
      "Preencha somente com o subdominio.",
    pacific: (v) =>
      !/https?/.test(v) || "Preencha somente com o ip/subdominio e porta.",
  };

  dialogProxy = false;
  carregandoProxy = false;
  cmd = false;
  proxy = defaultProxy();
  produtoPaginacao = defaultPaginacao();
  proxies = {
    admrh: {
      label: "AdmRH",
      items: [],
    },
    consultors: {
      label: "Consultors",
      items: [],
    },
  };

  dialogAcesso = false;
  carregandoAcesso = false;
  acessos = {};
  acessoApp = defaultAcessoApp();
  projetoPaginacao = defaultPaginacao();
  projetoMaisVendas = "mais-vendas";
  projetoMaisVendasFlutter = "flutter-mais-vendas";
  exibeCamposMaisVendas = false;

  animacao = { animationData: animationData };

  dialogModulo = false;
  programas = [];
  modulos = [];

  dialogCertificadoDigital = false;
  certificadosDigitais = defaultPaginacao();
  certificadoDigital = defaultCertificadoDigital();
  carregandoCertificadosDigitais = false;
  carregandoCertificadoDigital = false;

  bancosDadosCliente = {
    admrh: {
      label: "AdmRH",
      items: [],
    },
    consultors: {
      label: "Consultor's",
      items: [],
    },
  };
  bancoDadosCliente = new BancoDados({});
  bancosDados = defaultPaginacao();
  dialogBancoDados = false;
  carregandoBancoDados = false;

  constructor(
    context,
    buscaClientesUseCase,
    criaClienteUseCase,
    alteraClienteUseCase,
    excluiClienteUseCase,
    criaBucketUseCase,
    buscaProdutosUseCase,
    buscaProxiesUseCase,
    criaProxyUseCase,
    alteraProxyUseCase,
    excluiProxyUseCase,
    buscaChavesAcessosUseCase,
    buscaProjetosFirebaseUseCase,
    criaAcessoAppUseCase,
    alteraAcessoAppUseCase,
    excluiAcessoAppUseCase,
    buscaMenuConsultorsUseCase,
    buscaProgramasClienteUseCase,
    criaProgramasClienteUseCase,
    criaProgramasClienteMultiploUseCase,
    buscaCertificadoDigitalUseCase,
    criaCertificadoDigitalUseCase,
    alteraCertificadoDigitalUseCase,
    excluiCertificadoDigitalUseCase,
    buscaBancosDadosClienteUseCase,
    buscaBancosDadosUseCase,
    criaBancoDadosClienteUseCase,
    alteraBancoDadosClienteUseCase,
    deletaBancoDadosClienteUseCase
  ) {
    this.context = context;
    this.buscaClientesUseCase = buscaClientesUseCase;
    this.criaClienteUseCase = criaClienteUseCase;
    this.alteraClienteUseCase = alteraClienteUseCase;
    this.excluiClienteUseCase = excluiClienteUseCase;
    this.criaBucketUseCase = criaBucketUseCase;
    this.buscaProdutosUseCase = buscaProdutosUseCase;
    this.buscaProxiesUseCase = buscaProxiesUseCase;
    this.criaProxyUseCase = criaProxyUseCase;
    this.alteraProxyUseCase = alteraProxyUseCase;
    this.excluiProxyUseCase = excluiProxyUseCase;
    this.buscaChavesAcessosUseCase = buscaChavesAcessosUseCase;
    this.buscaProjetosFirebaseUseCase = buscaProjetosFirebaseUseCase;
    this.criaAcessoAppUseCase = criaAcessoAppUseCase;
    this.alteraAcessoAppUseCase = alteraAcessoAppUseCase;
    this.excluiAcessoAppUseCase = excluiAcessoAppUseCase;
    this.buscaMenuConsultorsUseCase = buscaMenuConsultorsUseCase;
    this.buscaProgramasClienteUseCase = buscaProgramasClienteUseCase;
    this.criaProgramasClienteUseCase = criaProgramasClienteUseCase;
    this.criaProgramasClienteMultiploUseCase =
      criaProgramasClienteMultiploUseCase;
    this.buscaCertificadoDigitalUseCase = buscaCertificadoDigitalUseCase;
    this.criaCertificadoDigitalUseCase = criaCertificadoDigitalUseCase;
    this.alteraCertificadoDigitalUseCase = alteraCertificadoDigitalUseCase;
    this.excluiCertificadoDigitalUseCase = excluiCertificadoDigitalUseCase;
    this.buscaBancosDadosClienteUseCase = buscaBancosDadosClienteUseCase;
    this.buscaBancosDadosUseCase = buscaBancosDadosUseCase;
    this.criaBancoDadosClienteUseCase = criaBancoDadosClienteUseCase;
    this.alteraBancoDadosClienteUseCase = alteraBancoDadosClienteUseCase;
    this.deletaBancoDadosClienteUseCase = deletaBancoDadosClienteUseCase;
  }

  async mounted(paginacao) {
    try {
      this.carregando = true;
      this.paginacao = paginacao;
      this.clientePaginacao = await this.buscaClientesUseCase(paginacao);
    } finally {
      this.carregando = false;
    }
  }

  async atualizar() {
    try {
      this.carregando = true;
      this.clientePaginacao = await this.buscaClientesUseCase(this.paginacao);
    } finally {
      this.carregando = false
    }
  }

  adicionarRegistro() {
    this.dialog = true;
  }

  alterarRegistro(item) {
    this.cliente = { ...item };
    this.dialog = true;
  }

  cancelarRegistro() {
    this.cliente = defaultCliente();
    this.context.$refs.form.resetValidation();
    this.dialog = false;
  }

  async salvarRegistro() {
    try {
      if (!this.context.$refs.form.validate()) {
        return;
      }

      this.carregando = true;

      if (this.cliente.id === 0) {
        await this.criaClienteUseCase(this.cliente);
      }

      if (this.cliente.id > 0) {
        await this.alteraClienteUseCase(this.cliente);
      }

      snackbar.show({
        message: "Registro salvo com sucesso!",
        color: "green",
        timeout: 3000,
      });

      this.cancelarRegistro();
      this.mounted(this.paginacao);
    } finally {
      this.carregando = false;
    }
  }

  async excluirRegistro(item) {
    try {
      const deletar = await alert.confirm({
        message: "Deseja realmente excluir o registro?",
        color: "red",
      });

      if (deletar) {
        this.carregando = true;

        await this.excluiClienteUseCase(item.id);
        await this.mounted(this.paginacao);

        this.clientes = [];
        this.mostraBotaoModulos = false;
      }
    } finally {
      this.carregando = false;
    }
  }

  async criarBucket(item) {
    try {
      this.carregando = true;
      await this.criaBucketUseCase(item.codCliCgi);
      snackbar.show({
        message: "Bucket Criado com sucesso!",
        color: "green",
        timeout: 3000,
      });
    } catch (error) {
      snackbar.show({
        message:
          "Não foi possível cadastrar o bucket, verifique se o mesmo já existe.",
      });
    } finally {
      this.carregando = false;
    }
  }

  linhasSelecionadas(items) {
    this.mostraAcoes = items.length === 1;
    this.mostraBotaoModulos = items.length;
    this.clientes = items.map((i) => i.id);
  }

  async verDetalhesRegistro(item) {
    try {
      this.carregando = true;
      this.clienteSelecionado = item;

      await Promise.all([
        this.buscaProxiesCliente(item),
        this.buscaAcessosCliente(item),
        this.buscaBancosDadosCliente(item),
      ]);

      this.dialogDetalhes = true;
    } finally {
      this.carregando = false;
    }
  }

  fecharDetalhes() {
    this.dialogDetalhes = false;
    this.tabDetalhes = 0;
  }

  async buscaProxiesCliente({ chave }) {
    const response = await this.buscaProxiesUseCase({
      itemsPerPage: 50,
      page: 1,
      search: chave,
      searchFields: "chaveCliente",
    });

    this.proxies.admrh.items = response.itens.filter(
      (item) => item.chaveProduto === "admrh"
    );

    this.proxies.consultors.items = response.itens.filter(
      (item) => item.chaveProduto === "consultors"
    );
  }

  adicionarProxy() {
    this.dialogProxy = true;
  }

  extrairSubdominio(url) {
    const regex =
      /^(?:https?:\/\/)?(?:admrh-auth-|consultors-auth-|admrh-|consultors-)?([a-z0-9-]+)\.(?:cgisoftware|webcgi)\.com\.br/;
    const found = url.match(regex);

    return found ? found[1] : null;
  }

  alterarProxy(item) {
    this.proxy = { ...item };
    this.proxy.url = this.extrairSubdominio(item.url);

    if (item.urlFrontend) {
      this.proxy.urlFrontend = item.urlFrontend
        .map((url) => this.extrairSubdominio(url.trim()))
        .join(", ");
    }

    this.proxy.pacific = item.pacific.replace(/https?:\/\//g, "");
    this.dialogProxy = true;
  }

  cancelarProxy(form) {
    this.dialogProxy = false;
    this.proxy = defaultProxy();
    form.resetValidation();
  }

  async salvarProxy(form) {
    try {
      if (!form.validate()) {
        return;
      }

      this.carregandoProxy = true;
      const url = `${this.montaPrefixoUrl()}${this.proxy.url
        }.cgisoftware.com.br`;
      const pacific = `http://${this.proxy.pacific}`;

      const proxy = {
        ...this.proxy,
        url,
        pacific,
      };

      if (this.proxy.urlFrontend) {
        const urlsFrontend =
          typeof this.proxy.urlFrontend === "string"
            ? this.proxy.urlFrontend.split(", ")
            : [];

        const urlFrontend = urlsFrontend
          .map((url) => `https://${url}.cgisoftware.com.br`)
          .join(", ");

        proxy.urlFrontend = urlFrontend;
      }

      if (proxy.id === 0) {
        proxy.idCliente = this.clienteSelecionado.id;
        await this.criaProxyUseCase(proxy);
      }

      if (proxy.id > 0) {
        if (Array.isArray(proxy.urlFrontend)) {
          proxy.urlFrontend = proxy.urlFrontend.join(", ");
        }

        await this.alteraProxyUseCase(proxy);
      }

      this.buscaProxiesCliente(this.clienteSelecionado);
      this.cancelarProxy(form);
    } finally {
      this.carregandoProxy = false;
    }
  }

  async excluirProxy(item) {
    try {
      const deletar = await alert.confirm({
        message: "Deseja realmente excluir o registro?",
        color: "red",
      });

      if (deletar) {
        this.carregandoProxy = true;

        await this.excluiProxyUseCase(item.id);
      }

      this.buscaProxiesCliente(this.clienteSelecionado);
    } finally {
      this.carregandoProxy = false;
    }
  }

  async buscaAcessosCliente({ nome }) {
    const response = await this.buscaChavesAcessosUseCase({
      page: 1,
      itemsPerPage: 50,
      search: nome,
      searchFields: "nomeCliente",
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
    });

    this.acessos = _.groupBy(response.itens, "nomeProjeto");
  }

  adicionarAcesso() {
    this.dialogAcesso = true;
  }

  alterarAcesso(item) {
    this.acessoApp = { ...item };
    this.validaCamposExtrasMaisVendas(item.idProjeto);
    this.dialogAcesso = true;
  }

  cancelarAcesso(form) {
    this.dialogAcesso = false;
    this.acessoApp = defaultAcessoApp();
    form.resetValidation();
  }

  async salvarAcesso(form) {
    try {
      if (!form.validate()) {
        return;
      }

      this.carregandoAcesso = true;

      if (this.acessoApp.id === 0) {
        await this.criaAcessoAppUseCase(this.acessoApp);
      }

      if (this.acessoApp.id > 0) {
        await this.alteraAcessoAppUseCase(this.acessoApp);
      }

      snackbar.show({
        message: "Registro salvo com sucesso!",
        color: "green",
        timeout: 3000,
      });

      this.buscaAcessosCliente(this.clienteSelecionado);
      this.cancelarAcesso(form);
    } finally {
      this.carregandoAcesso = false;
    }
  }

  async excluirAcesso(item) {
    try {
      const deletar = await alert.confirm({
        message: "Deseja realmente excluir o registro?",
        color: "red",
      });

      if (deletar) {
        this.carregandoAcesso = true;

        await this.excluiAcessoAppUseCase(item.id);
      }

      this.buscaAcessosCliente(this.clienteSelecionado);
    } finally {
      this.carregandoAcesso = false;
    }
  }

  async copiarTokenAcesso(item) {
    try {
      await navigator.clipboard.writeText(item.apiToken);

      snackbar.show({
        message: "Copiado para a área de transferência!",
        color: "primary",
        timeout: 3000,
      });
    } catch (error) {
      snackbar.show({ message: error });
    }
  }

  async validaCamposExtrasMaisVendas(projetoId) {
    this.exibeCamposMaisVendas = false;

    if (!projetoId) {
      return;
    }

    const registros = await this.buscaProjetosFirebaseUseCase({
      itemsPerPage: 1,
      page: 1,
      search: projetoId,
      searchFields: "id",
    });

    if (registros.itens.length > 0) {
      const projetoSelecionado = registros.itens[0];

      if (
        projetoSelecionado.firebase === this.projetoMaisVendas ||
        projetoSelecionado.firebase === this.projetoMaisVendasFlutter
      ) {
        this.exibeCamposMaisVendas = true;
      }
    }
  }

  cancelarModulo() {
    this.dialogModulo = false;
    this.programas = [];
  }

  async adicionarModulo() {
    try {
      this.carregando = true;
      await this.buscaProgramasClienteSelecionado();

      this.modulos = await this.buscaMenuConsultorsUseCase();
      this.dialogModulo = true;
    } finally {
      this.carregando = false;
    }
  }

  async buscaProgramasClienteSelecionado() {
    if (this.clientes.length === 1) {
      this.programas = await this.buscaProgramasClienteUseCase(
        this.clientes[0]
      );

      return;
    }

    this.programas = [];
  }

  async salvarModulo() {
    try {
      this.carregando = true;

      if (this.clientes.length === 1) {
        await this.criaProgramasClienteUseCase({
          idCliente: this.clientes[0],
          programas: this.programas,
        });

        snackbar.show({
          message: "Registro salvo com sucesso!",
          color: "green",
          timeout: 3000,
        });

        this.cancelarModulo();
        return;
      }

      snackbar.show({
        message: "Registro salvo com sucesso!",
        color: "green",
        timeout: 3000,
      });

      await this.criaProgramasClienteMultiploUseCase({
        clientes: this.clientes,
        programas: this.programas,
      });

      this.cancelarModulo();
    } finally {
      this.carregando = false;
    }
  }

  async buscaCertificadosDigitaisCliente(cliente) {
    try {
      this.carregandoCertificadosDigitais = true;
      this.certificadosDigitais = await this.buscaCertificadoDigitalUseCase(
        10,
        1,
        cliente.id
      );
    } finally {
      this.carregandoCertificadosDigitais = false;
    }
  }

  adicionarCertificadoDigital() {
    this.certificadoDigital.idCliente = this.clienteSelecionado.id;
    this.dialogCertificadoDigital = true;
  }

  alterarCertificadoDigital(item) {
    this.certificadoDigital = { ...item };
    this.dialogCertificadoDigital = true;
  }

  cancelarCertificadoDigital(form) {
    this.certificadoDigital = defaultCertificadoDigital();
    form.resetValidation();
    this.dialogCertificadoDigital = false;
  }

  async salvarCertificadoDigital(form) {
    try {
      if (!form.validate()) {
        return;
      }

      this.carregandoCertificadoDigital = true;
      if (this.certificadoDigital.id === 0) {
        await this.criaCertificadoDigitalUseCase(this.certificadoDigital);
      }

      if (this.certificadoDigital.id > 0) {
        await this.alteraCertificadoDigitalUseCase(this.certificadoDigital);
      }

      snackbar.show({
        message: "Registro salvo com sucesso!",
        color: "green",
        timeout: 3000,
      });

      this.cancelarCertificadoDigital(form);
      this.buscaCertificadosDigitaisCliente(this.clienteSelecionado);
    } finally {
      this.carregandoCertificadoDigital = false;
    }
  }

  async excluirCertificadoDigital(item) {
    try {
      const deletar = await alert.confirm({
        message: "Deseja realmente excluir o registro?",
        color: "red",
      });

      if (deletar) {
        this.carregandoCertificadoDigital = true;

        await this.excluiCertificadoDigitalUseCase(item.id);
      }

      this.buscaCertificadosDigitaisCliente(this.clienteSelecionado);
    } finally {
      this.carregandoCertificadoDigital = false;
    }
  }

  async buscaBancosDadosCliente({ id }) {
    const response = await this.buscaBancosDadosClienteUseCase({
      page: 1,
      itemsPerPage: 50,
      idCliente: id,
    });

    this.bancosDadosCliente.admrh.items = response.itens.filter(
      (item) => item.chaveProduto === "admrh"
    );

    this.bancosDadosCliente.consultors.items = response.itens.filter(
      (item) => item.chaveProduto === "consultors"
    );
  }

  async salvarBancoDados(form) {
    try {
      if (!form.validate()) {
        return;
      }

      this.carregandoBancoDados = true;

      if (this.bancoDadosCliente.id) {
        await this.alteraBancoDadosClienteUseCase(
          this.bancoDadosCliente.toSave(this.clienteSelecionado.id)
        );
      } else {
        await this.criaBancoDadosClienteUseCase(
          this.bancoDadosCliente.toSave(this.clienteSelecionado.id)
        );
      }

      this.cancelarBancoDados(form);
      await this.buscaBancosDadosCliente(this.clienteSelecionado);
    } finally {
      this.carregandoBancoDados = false;
    }
  }

  async deletarBancoDados({ id }) {
    try {
      const confirma = await alert.confirm({
        message: "Deseja realmente excluír o banco de dados?",
        color: "red",
      });

      if (confirma) {
        this.carregandoBancoDados = true;

        await this.deletaBancoDadosClienteUseCase(id);
        await this.buscaBancosDadosCliente(this.clienteSelecionado);
      }
    } finally {
      this.carregandoBancoDados = false;
    }
  }

  incluirBancoDadosCliente() {
    this.dialogBancoDados = true;
    this.bancoDadosCliente = new BancoDados({});
  }

  alterarBancoDados(item) {
    this.dialogBancoDados = true;
    this.bancoDadosCliente = new BancoDados(item);
  }

  cancelarBancoDados(form) {
    this.dialogBancoDados = false;
    this.bancoDadosCliente = new BancoDados({});
    form.resetValidation();
  }

  async buscaBancosDados(search) {
    this.bancosDados = await this.buscaBancosDadosUseCase({
      itemsPerPage: 30,
      page: 1,
      search: search,
      sortBy: ["nome"],
      sortDesc: [false],
      searchFields: "nome",
    });
  }

  async buscaProdutos(search) {
    this.produtoPaginacao = await this.buscaProdutosUseCase({
      itemsPerPage: 30,
      page: 1,
      search: search,
      sortBy: ["nome"],
      sortDesc: [false],
      searchFields: "nome",
    });
  }

  async buscaProjetos(search) {
    this.projetoPaginacao = await this.buscaProjetosFirebaseUseCase({
      itemsPerPage: 30,
      page: 1,
      search: search,
      sortBy: ["nome"],
      sortDesc: [false],
      searchFields: "nome",
    });
  }

  montaPrefixoUrl() {
    let prefixo = "https://";

    if (this.proxy.idProduto) {
      const produto = this.produtoPaginacao.itens.find(
        (item) => item.id === this.proxy.idProduto
      );

      if (produto) {
        prefixo += `${produto.chave}-`;
      }

      if (this.proxy.autenticado) {
        prefixo += "auth-";
      }
    }

    return prefixo;
  }

  buscaBancosDadosDebounce = _.debounce(async (search) => {
    this.buscaBancosDados(search);
  }, 500);

  buscaProdutosDebounce = _.debounce(async (search) => {
    this.buscaProdutos(search);
  }, 500);

  buscaProjetosDebounce = _.debounce(async (search) => {
    this.buscaProjetos(search);
  }, 500);
}

export default ClienteController;
