import dayjs from "dayjs";

const formataVencimento = (vencimento) => {
  if (!vencimento) return null;

  const [data, hora] = vencimento.split(" ");
  const [dia, mes, ano] = data.split("/");

  const dataFormatada = new Date(ano, mes - 1, dia, ...hora.split(":"));

  if (!dayjs(dataFormatada).isValid()) return null;

  return dataFormatada;
};

class CertificadoDigital {
  constructor({
    id = 0,
    idCliente = null,
    usaNfe = false,
    usaCte = false,
    nomeCliente = null,
    proxyURL = null,
    vencimento = null,
  }) {
    this.id = id;
    this.idCliente = idCliente;
    this.usaNfe = usaNfe;
    this.usaCte = usaCte;
    this.nomeCliente = nomeCliente;
    this.proxyURL = proxyURL;
    this.vencimento = formataVencimento(vencimento);
  }
}

export const defaultCertificadoDigital = () =>
  new CertificadoDigital({
    id: 0,
    idCliente: "",
    usaNfe: false,
    usaCte: false,
    nomeCliente: null,
    proxyURL: null,
    vencimento: null,
  });

export default CertificadoDigital;
