import Handler from "@/core/domain/usecase/handler";

const buscaProgramasClienteRepository = (axios) => async (id) => {
  try {
    const response = await axios.get(
      `/cliente/${id}/liberacao-programa-cliente`
    );

    return response.data;
  } catch (error) {
    new Handler().trataErro(error);
  }
};

export default buscaProgramasClienteRepository;
