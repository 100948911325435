<template>
  <v-card elevation="5" class="rounded-xl">
    <v-card-title v-if="titulo">
      {{ titulo }}
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            :cols="colunas"
            :lg="colunas"
            sm="12"
            v-for="(item, index) in items"
            :key="`${titulo}_${index}`"
          >
            <v-card flat outlined class="rounded-xl mb-4">
              <v-card-title
                v-if="mostraAcoes"
                class="d-flex justify-space-between"
              >
                <div v-if="carregar" class="d-flex align-center">
                  <v-skeleton-loader type="chip" class="mr-2" />
                  <v-skeleton-loader type="chip" class="mr-2" />
                  <v-skeleton-loader type="chip" class="mr-2" />
                </div>

                <slot
                  v-if="temLegenda && !carregar"
                  name="legenda"
                  v-bind:item="item"
                >
                </slot>

                <v-skeleton-loader v-if="carregar" type="button" />

                <template v-else>
                  <v-menu content-class="rounded-xl">
                    <template #activator="{ on, attrs }">
                      <v-btn v-on="on" v-bind="attrs" icon class="ml-auto">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>

                    <v-card class="rounded-xl elevation-0">
                      <v-card-text>
                        <div class="font-weight-bold mb-5">
                          Ações do registro
                        </div>

                        <div class="d-flex justify-space-between align-center">
                          <div
                            v-for="(acao, index) in acoes"
                            :key="index"
                            v-if="acao.mostrar"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  @click="acao.acao(item)"
                                  :color="acao.cor"
                                  :fab="true"
                                  x-small
                                  icon
                                >
                                  <v-icon>{{ acao.icone }}</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ acao.descricao }}</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-card-title>

              <v-card-text
                v-if="configuracao.length"
                class="d-flex flex-column"
              >
                <div v-for="config in configuracaoVisivel" :key="config.value">
                  <template v-if="carregar">
                    <v-skeleton-loader type="sentences" />
                  </template>

                  <template v-else>
                    <span>
                      <b>
                        {{ config.text }}
                      </b>
                    </span>
                    <span>{{ item[config.value] }}</span>
                  </template>
                </div>

                <template v-for="config in configuracaoCustomizavel">
                  <v-skeleton-loader v-if="carregar" type="sentences" />

                  <slot v-else :name="config.value" v-bind:item="item"> </slot>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    configuracao: {
      type: Array,
      default: () => [],
    },
    "mostra-acoes": {
      type: Boolean,
      default: () => false,
    },
    "copiar-token": {
      type: Boolean,
      default: () => false,
    },
    alterar: {
      type: Boolean,
      default: () => false,
    },
    deletar: {
      type: Boolean,
      default: () => false,
    },
    colunas: {
      type: String,
      default: () => "12",
    },
    carregar: {
      type: Boolean,
      default: () => false,
    },
  },
  data: (context) => ({
    acoes: [
      {
        nome: "Copiar token",
        icone: "mdi-content-copy",
        cor: "green",
        descricao: "Copiar token",
        acao: (item) => {
          context.$emit("copiar-token", item);
        },
        mostrar: context.copiarToken,
      },
      {
        nome: "Alterar",
        icone: "mdi-pencil",
        cor: "blue",
        descricao: "Alterar registro",
        acao: (item) => {
          context.$emit("alterar-item", item);
        },
        mostrar: context.alterar,
      },
      {
        nome: "Excluir",
        icone: "mdi-delete",
        cor: "red",
        descricao: "Excluir registro",
        acao: (item) => {
          context.$emit("deletar-item", item);
        },
        mostrar: context.deletar,
      },
    ],
  }),
  computed: {
    configuracaoCustomizavel() {
      return this.configuracao.filter((config) => config.custom);
    },
    configuracaoVisivel() {
      return this.configuracao.filter((config) => !config.custom);
    },
    temLegenda() {
      return this.$slots["legenda"] || this.$scopedSlots["legenda"];
    },
  },
};
</script>
