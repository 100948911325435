const buscaClientesUseCase =
  (repository) =>
  async ({ itemsPerPage, page }) => {
    try {
      return await repository({ itemsPerPage, page });
    } catch (error) {
      throw error;
    }
  };

export default buscaClientesUseCase;
