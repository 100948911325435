'use strict'
import jwt_decode from "jwt-decode";

const SESSION_TOKEN_KEY = 'auth'
const SESSION_USER_KEY = 'user'

export function getJwtAuthToken() {
  return localStorage.getItem(SESSION_TOKEN_KEY)
}

export function setJwtAuthToken(token) {
  localStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify(token))
}

export function clearUserToken() {
  localStorage.removeItem(SESSION_TOKEN_KEY)

  return localStorage.getItem(SESSION_TOKEN_KEY) === null
}

export function isUserLogged() {
  return localStorage.getItem(SESSION_TOKEN_KEY) !== null
}

export function trocarSenha() {
  return getUser()?.trocarSenha
}

export function getUser() {
  const { token } = getToken()
  const jwtDecode = token ? jwt_decode(token) : null

  return jwtDecode?.usuario
}

export function getToken() {
  const userSession = localStorage.getItem(SESSION_TOKEN_KEY)

  return userSession !== null ? JSON.parse(userSession) : {}
}

export function clearUser() {
  localStorage.removeItem(SESSION_USER_KEY)

  return localStorage.getItem(SESSION_USER_KEY) === null
}

export function getAccountGroupID() {
  return getUser().idGrupoUsuario
}