import { axiosInstance, axiosInstanceDesktopLogs } from "@/plugins/axios";
import { baseUrl } from "@/constants";

import LoginController from "@/module/login/controller/loginController";
import DefaultController from "@/module/default/controller/defaultController";
import HomeController from "@/module/home/controller/homeController";
import DashboardController from "@/module/dashboard/controller/dashboardController";
import UsuarioController from "@/module/usuario/controller/usuarioController";
import LogsAppController from "@/module/logsApp/controller/logsAppController";
import LogsDesktopController from "@/module/logsDesktop/controller/logsDesktopController";
import StatusServidorController from "@/module/statusServidor/controller/statusServidorController";
import CertificadoDigitalController from "@/module/certificadoDigital/controller/certificadoDigitalController";
import ClientePorAplicativoFirebaseController from "@/module/clienteFirebase/controller/clientePorAplicativoFirebaseController";
import ConfiguracoesController from "@/module/configuracoes/controller/configuracoesController";
import ProjetoFirebaseController from "@/module/projetoFirebase/controller/projetoFirebaseController";
import ClienteFirebaseController from "@/module/clienteFirebase/controller/clienteFirebaseController";
import ModuloController from "@/module/modulo/controller/moduloController";
import AcessoAppController from "@/module/acessoApp/controller/acessoAppController";
import AuditoriaController from "@/module/auditoria/controller/auditoriaController";

import loginRepostory from "@/core/data/repository/loginRepository";
import loginUseCase from "@/core/domain/usecase/loginUseCase";

import refreshRepository from "@/core/data/repository/refreshRepository";
import refreshUseCase from "@/core/domain/usecase/refreshUseCase";

import alterarSenhaRepository from "@/core/data/repository/alterarSenhaRepository";
import alterarSenhaUseCase from "@/core/domain/usecase/alterarSenhaUseCase";

import buscaUsuariosUseCase from "@/module/usuario/domain/usecase/buscaUsuariosUseCase";
import buscaUsuariosRepository from "@/module/usuario/data/repository/buscaUsuariosRepository";

import buscaProxiesRepository from "@/module/proxy/data/repository/buscaProxiesRepository";
import buscaProxiesUseCase from "@/module/proxy/domain/usecase/buscaProxiesUseCase";

import buscaClientesRepository from "@/module/cliente/data/repository/buscaClientesRepository";
import buscaClientesUseCase from "@/module/cliente/domain/usecase/buscaClienteUseCase";

import buscaGrupoUsuarioRepository from "@/module/grupoUsuario/data/repository/buscaGrupoUsuarioRepository";
import buscaGrupoUsuarioUseCase from "@/module/grupoUsuario/domain/usecase/buscaGrupoUsuarioUseCase";

import buscaProjetosFirebaseRepository from "@/module/projetoFirebase/data/repository/buscaProjetoFirebaseRepository";
import buscaProjetosFirebaseUseCase from "@/module/projetoFirebase/domain/usecase/buscaProjetoFirebaseUseCase";

import criaProjetoFirebaseRepository from "@/module/projetoFirebase/data/repository/criaProjetoFirebaseRepository";
import criaProjetoFirebaseUseCase from "@/module/projetoFirebase/domain/usecase/criaProjetoFirebaseUseCase";

import alteraProjetoFirebaseRepository from "@/module/projetoFirebase/data/repository/alteraProjetoFirebaseRepository";
import alteraProjetoFirebaseUseCase from "@/module/projetoFirebase/domain/usecase/alteraProjetoFirebaseUseCase";

import excluiProjetoFirebaseRepository from "@/module/projetoFirebase/data/repository/excluiProjetoFirebaseRepository";
import excluiProjetoFirebaseUseCase from "@/module/projetoFirebase/domain/usecase/excluiProjetoFirebaseUseCase";

import buscaClientesFirebaseRepository from "@/module/clienteFirebase/data/repository/buscaClienteFirebaseRepository";
import buscaClientesFirebaseUseCase from "@/module/clienteFirebase/domain/usecase/buscaClienteFirebaseUseCase";

import criaClienteFirebaseRepository from "@/module/clienteFirebase/data/repository/criaClienteFirebaseRepository";
import criaClienteFirebaseUseCase from "@/module/clienteFirebase/domain/usecase/criaClienteFirebaseUseCase";

import excluiClienteFirebaseRepository from "@/module/clienteFirebase/data/repository/excluiClienteFirebaseRepository";
import excluiClienteFirebaseUseCase from "@/module/clienteFirebase/domain/usecase/excluiClienteFirebaseUseCase";

import criaUsuarioRepository from "@/module/usuario/data/repository/criaUsuarioRepository";
import criaUsuarioUseCase from "@/module/usuario/domain/usecase/criaUsuarioUseCase";

import alteraUsuarioRepository from "@/module/usuario/data/repository/alteraUsuarioRepository";
import alteraUsuarioUseCase from "@/module/usuario/domain/usecase/alteraUsuarioUseCase";

import excluiUsuarioRepository from "@/module/usuario/data/repository/excluiUsuarioRepository";
import excluiUsuarioUseCase from "@/module/usuario/domain/usecase/excluiUsuarioUseCase";

import buscaMenuConsultorsRepository from "@/module/modulo/data/repository/buscaMenuConsultorsRepository";
import buscaMenuConsultorsUseCase from "@/module/modulo/domain/usecase/buscaMenuConsultorsUseCase";

import buscaProgramasClienteRepository from "@/module/modulo/data/repository/buscaProgramasClienteRepository";
import buscaProgramasClienteUseCase from "@/module/modulo/domain/usecase/buscaProgramasClienteUseCase";

import criaProgramasClienteRepository from "@/module/modulo/data/repository/criaProgramasClienteRepository";
import criaProgramasClienteUseCase from "@/module/modulo/domain/usecase/criaProgramasClienteUseCase";

import buscaChavesAcessosRepository from "@/module/acessoApp/data/repository/buscaAcessosAppsRepository";
import buscaChavesAcessosUseCase from "@/module/acessoApp/domain/usecase/buscaAcessosAppsUseCase";

import criaAcessoAppRepository from "@/module/acessoApp/data/repository/criaAcessoAppRepository";
import criaAcessoAppUseCase from "@/module/acessoApp/domain/usecase/criaAcessoAppUseCase";

import alteraAcessoAppRepository from "@/module/acessoApp/data/repository/alteraAcessoAppRepository";
import alteraAcessoAppUseCase from "@/module/acessoApp/domain/usecase/alteraAcessoAppUseCase";

import excluiAcessoAppRepository from "@/module/acessoApp/data/repository/excluiAcessoAppRepository";
import excluiAcessoAppUseCase from "@/module/acessoApp/domain/usecase/excluiAcessoAppUseCase";

import buscaAuditoriaRepository from "@/module/auditoria/data/repository/buscaAuditoriaRepository";
import buscaAuditoriaUseCase from "@/module/auditoria/domain/usecase/buscaAuditoriaUseCase";

import buscaLogsAppRepository from "@/module/logsApp/data/repository/buscaLogsAppRepository";
import buscaLogsAppUseCase from "@/module/logsApp/domain/usecase/buscaLogsAppUseCase";

import buscarPermissoesRepository from "@/core/data/repository/buscarPermissoesRepository";
import buscarPermissoesUseCase from "@/core/domain/usecase/buscarPermissoesUseCase";

import buscaLogsDesktopRepository from "@/module/logsDesktop/data/repository/buscaLogsDesktopRepository";
import buscaLogsDesktopUseCase from "@/module/logsDesktop/domain/usecase/buscaLogsDesktopUseCase";

import criaProgramasClienteMultiploRepository from "@/module/modulo/data/repository/criaProgramasClienteMultiploRepository";
import criaProgramasClienteMultiploUseCase from "@/module/modulo/domain/usecase/criaProgramasClienteMultiploUseCase";

import buscaCertificadoDigitalRepository from "@/module/certificadoDigital/data/repository/buscaCertificadoDigitalRepository";
import buscaCertificadoDigitalUseCase from "@/module/certificadoDigital/domain/usecase/buscaCertificadoDigitalUseCase";

import criaCertificadoDigitalRepository from "@/module/certificadoDigital/data/repository/criaCertificadoDigitalRepository";
import criaCertificadoDigitalUseCase from "@/module/certificadoDigital/domain/usecase/criaCertificadoDigitalCase";

import alteraCertificadoDigitalRepository from "@/module/certificadoDigital/data/repository/alteraCertificaoDigitalRepository";
import alteraCertificadoDigitalUseCase from "@/module/certificadoDigital/domain/usecase/alteraCertificadoDigitalUseCase";

import excluiCertificadoDigitalRepository from "@/module/certificadoDigital/data/repository/excluiCertificadoDigitalRepository";
import excluiCertificadoDigitalUseCase from "@/module/certificadoDigital/domain/usecase/excluiCertificadoDigitalUseCase";

import buscaClientesPorAppFirebaseRepository from "@/module/clienteFirebase/data/repository/buscaClientePorAppFirebaseRepository";
import buscaClientesPorAppFirebaseUseCase from "@/module/clienteFirebase/domain/usecase/buscaClientePorAppFirebaseUseCase";

import buscaInfoBackupsRepository from "@/module/dashboard/data/repository/buscaInfoBackupsRepository";
import buscaInfoBackupsUseCase from "@/module/dashboard/domain/usecase/buscaInfoBackupsUseCase";

import buscaStatusServidoresRepository from "@/module/statusServidor/data/repository/buscaStatusServidoresRepository";
import buscaStatusServidoresUseCase from "@/module/statusServidor/domain/usecase/buscaStatusServidoresUseCase";

import buscaStatusServidorRepository from "@/module/statusServidor/data/repository/buscaStatusServidorRepository";
import buscaStatusServidorUseCase from "@/module/statusServidor/domain/usecase/buscaStatusServidorUseCase";

const loginRepostoryImpl = loginRepostory(baseUrl);
const loginUseCaseImpl = loginUseCase(loginRepostoryImpl);

const refreshRepositoryImpl = refreshRepository(baseUrl);
const refreshUseCaseImpl = refreshUseCase(refreshRepositoryImpl);

const alterarSenhaRepositoryImpl = alterarSenhaRepository(axiosInstance);
const alterarSenhaUseCaseImpl = alterarSenhaUseCase(alterarSenhaRepositoryImpl);

const buscaInfoBackupsRepositoryImpl =
  buscaInfoBackupsRepository(axiosInstance);
const buscaInfoBackupsUseCaseImpl = buscaInfoBackupsUseCase(
  buscaInfoBackupsRepositoryImpl
);

const buscaProxiesRepositoryImpl = buscaProxiesRepository(axiosInstance);
const buscaProxiesUseCaseImpl = buscaProxiesUseCase(buscaProxiesRepositoryImpl);

const buscaProjetosFirebaseRepositoryImpl =
  buscaProjetosFirebaseRepository(axiosInstance);
const buscaProjetosFirebaseUseCaseImpl = buscaProjetosFirebaseUseCase(
  buscaProjetosFirebaseRepositoryImpl
);
const criaProjetoFirebaseRepositoryImpl =
  criaProjetoFirebaseRepository(axiosInstance);
const criaProjetoFirebaseUseCaseImpl = criaProjetoFirebaseUseCase(
  criaProjetoFirebaseRepositoryImpl
);
const alteraProjetoFirebaseRepositoryImpl =
  alteraProjetoFirebaseRepository(axiosInstance);
const alteraProjetoFirebaseUseCaseImpl = alteraProjetoFirebaseUseCase(
  alteraProjetoFirebaseRepositoryImpl
);
const excluiProjetoFirebaseRepositoryImpl =
  excluiProjetoFirebaseRepository(axiosInstance);
const excluiProjetoFirebaseUseCaseImpl = excluiProjetoFirebaseUseCase(
  excluiProjetoFirebaseRepositoryImpl
);

const buscaClientesFirebaseRepositoryImpl =
  buscaClientesFirebaseRepository(axiosInstance);
const buscaClientesFirebaseUseCaseImpl = buscaClientesFirebaseUseCase(
  buscaClientesFirebaseRepositoryImpl
);
const criaClienteFirebaseRepositoryImpl =
  criaClienteFirebaseRepository(axiosInstance);
const criaClienteFirebaseUseCaseImpl = criaClienteFirebaseUseCase(
  criaClienteFirebaseRepositoryImpl
);
const excluiClienteFirebaseRepositoryImpl =
  excluiClienteFirebaseRepository(axiosInstance);
const excluiClienteFirebaseUseCaseImpl = excluiClienteFirebaseUseCase(
  excluiClienteFirebaseRepositoryImpl
);

const buscaClientesRepositoryImpl = buscaClientesRepository(axiosInstance);
const buscaClientesUseCaseImpl = buscaClientesUseCase(
  buscaClientesRepositoryImpl
);

const buscaCertificadoDigitalRepositoryImpl =
  buscaCertificadoDigitalRepository(axiosInstance);
const buscaCertificadoDigitalUseCaseImpl = buscaCertificadoDigitalUseCase(
  buscaCertificadoDigitalRepositoryImpl
);
const criaCertificadoDigitalRepositoryImpl =
  criaCertificadoDigitalRepository(axiosInstance);
const criaCertificadoDigitalUseCaseImpl = criaCertificadoDigitalUseCase(
  criaCertificadoDigitalRepositoryImpl
);
const alteraCertificadoDigitalRepositoryImpl =
  alteraCertificadoDigitalRepository(axiosInstance);
const alteraCertificadoDigitalUseCaseImpl = alteraCertificadoDigitalUseCase(
  alteraCertificadoDigitalRepositoryImpl
);
const excluiCertificadoDigitalRepositoryImpl =
  excluiCertificadoDigitalRepository(axiosInstance);
const excluiCertificadoDigitalUseCaseImpl = excluiCertificadoDigitalUseCase(
  excluiCertificadoDigitalRepositoryImpl
);

const buscaUsuariosRepositoryImpl = buscaUsuariosRepository(axiosInstance);
const buscaUsuariosUseCaseImpl = buscaUsuariosUseCase(
  buscaUsuariosRepositoryImpl
);
const criaUsuarioRepositoryImpl = criaUsuarioRepository(axiosInstance);
const criaUsuarioUseCaseImpl = criaUsuarioUseCase(criaUsuarioRepositoryImpl);
const alteraUsuarioRepositoryImpl = alteraUsuarioRepository(axiosInstance);
const alteraUsuarioUseCaseImpl = alteraUsuarioUseCase(
  alteraUsuarioRepositoryImpl
);
const excluiUsuarioRepositoryImpl = excluiUsuarioRepository(axiosInstance);
const excluiUsuarioUseCaseImpl = excluiUsuarioUseCase(
  excluiUsuarioRepositoryImpl
);

const buscaGrupoUsuarioRepositoryImpl =
  buscaGrupoUsuarioRepository(axiosInstance);
const buscaGrupoUsuarioUseCaseImpl = buscaGrupoUsuarioUseCase(
  buscaGrupoUsuarioRepositoryImpl
);

const buscaMenuConsultorsRepositoryImpl = buscaMenuConsultorsRepository();
const buscaMenuConsultorsUseCaseImpl = buscaMenuConsultorsUseCase(
  buscaMenuConsultorsRepositoryImpl
);
const buscaProgramasClienteRepositoryImpl =
  buscaProgramasClienteRepository(axiosInstance);
const buscaProgramasClienteUseCaseImpl = buscaProgramasClienteUseCase(
  buscaProgramasClienteRepositoryImpl
);
const criaProgramasClienteRepositoryImpl =
  criaProgramasClienteRepository(axiosInstance);
const criaProgramasClienteUseCaseImpl = criaProgramasClienteUseCase(
  criaProgramasClienteRepositoryImpl
);
const criaProgramasClienteMultiploRepositoryImpl =
  criaProgramasClienteMultiploRepository(axiosInstance);
const criaProgramasClienteMultiploUseCaseImpl =
  criaProgramasClienteMultiploUseCase(
    criaProgramasClienteMultiploRepositoryImpl
  );

const buscaChavesAcessosRepositoryImpl =
  buscaChavesAcessosRepository(axiosInstance);
const buscaChavesAcessosUseCaseImpl = buscaChavesAcessosUseCase(
  buscaChavesAcessosRepositoryImpl
);
const criaAcessoAppRepositoryImpl = criaAcessoAppRepository(axiosInstance);
const criaAcessoAppUseCaseImpl = criaAcessoAppUseCase(
  criaAcessoAppRepositoryImpl
);
const alteraAcessoAppRepositoryImpl = alteraAcessoAppRepository(axiosInstance);
const alteraAcessoAppUseCaseImpl = alteraAcessoAppUseCase(
  alteraAcessoAppRepositoryImpl
);
const excluiAcessoAppRepositoryImpl = excluiAcessoAppRepository(axiosInstance);
const excluiAcessoAppUseCaseImpl = excluiAcessoAppUseCase(
  excluiAcessoAppRepositoryImpl
);

const buscaAuditoriaRepositoryImpl = buscaAuditoriaRepository(axiosInstance);
const buscaAuditoriaUseCaseImpl = buscaAuditoriaUseCase(
  buscaAuditoriaRepositoryImpl
);

const buscaLogsAppRepositoryImpl = buscaLogsAppRepository(axiosInstance);
const buscaLogsAppUseCaseImpl = buscaLogsAppUseCase(buscaLogsAppRepositoryImpl);

const buscarPermissoesRepositoryImpl =
  buscarPermissoesRepository(axiosInstance);
const buscarPermissoesUseCaseImpl = buscarPermissoesUseCase(
  buscarPermissoesRepositoryImpl
);

const buscaLogsDesktopRepositoryImpl = buscaLogsDesktopRepository(
  axiosInstanceDesktopLogs
);
const buscaLogsDesktopUseCaseImpl = buscaLogsDesktopUseCase(
  buscaLogsDesktopRepositoryImpl
);

const buscaClientesPorAppFirebaseRepositoryImpl =
  buscaClientesPorAppFirebaseRepository(axiosInstance);
const buscaClientesPorAppFirebaseUseCaseImpl =
  buscaClientesPorAppFirebaseUseCase(buscaClientesPorAppFirebaseRepositoryImpl);

const buscaStatusServidoresRepositoryImpl =
  buscaStatusServidoresRepository(axiosInstance);
const buscaStatusServidoresUseCaseImpl = buscaStatusServidoresUseCase(
  buscaStatusServidoresRepositoryImpl
);

const buscaStatusServidorRepositoryImpl =
  buscaStatusServidorRepository(axiosInstance);
const buscaStatusServidorUseCaseImpl = buscaStatusServidorUseCase(
  buscaStatusServidorRepositoryImpl
);

const loginController = (context) =>
  new LoginController(context, loginUseCaseImpl, alterarSenhaUseCaseImpl);

const usuarioController = (context) =>
  new UsuarioController(
    context,
    buscaUsuariosUseCaseImpl,
    buscaGrupoUsuarioUseCaseImpl,
    criaUsuarioUseCaseImpl,
    alteraUsuarioUseCaseImpl,
    excluiUsuarioUseCaseImpl
  );
const defaultController = (context) => new DefaultController(context);

const homeController = (context) =>
  new HomeController(context, buscarPermissoesUseCaseImpl);

const dashboardController = (context) =>
  new DashboardController(context, buscaInfoBackupsUseCaseImpl);

const projetoFirebaseController = (context) =>
  new ProjetoFirebaseController(
    context,
    buscaProjetosFirebaseUseCaseImpl,
    criaProjetoFirebaseUseCaseImpl,
    alteraProjetoFirebaseUseCaseImpl,
    excluiProjetoFirebaseUseCaseImpl
  );

const clienteFirebaseController = (context) =>
  new ClienteFirebaseController(
    context,
    buscaClientesFirebaseUseCaseImpl,
    criaClienteFirebaseUseCaseImpl,
    excluiClienteFirebaseUseCaseImpl
  );

const moduloController = (context) =>
  new ModuloController(
    context,
    buscaMenuConsultorsUseCaseImpl,
    buscaClientesUseCaseImpl,
    buscaProgramasClienteUseCaseImpl,
    criaProgramasClienteUseCaseImpl,
    criaProgramasClienteMultiploUseCaseImpl
  );

const acessoAppController = (context) =>
  new AcessoAppController(
    context,
    buscaChavesAcessosUseCaseImpl,
    criaAcessoAppUseCaseImpl,
    alteraAcessoAppUseCaseImpl,
    excluiAcessoAppUseCaseImpl,
    buscaClientesUseCaseImpl,
    buscaProjetosFirebaseUseCaseImpl,
    buscaProxiesUseCaseImpl
  );

const auditoriaController = (context) =>
  new AuditoriaController(context, buscaAuditoriaUseCaseImpl);

const logsAppController = (context) =>
  new LogsAppController(context, buscaLogsAppUseCaseImpl);

const logsDesktopController = (context) =>
  new LogsDesktopController(context, buscaLogsDesktopUseCaseImpl);

const statusServidorController = (context) =>
  new StatusServidorController(
    context,
    buscaProxiesUseCaseImpl,
    buscaStatusServidoresUseCaseImpl,
    buscaStatusServidorUseCaseImpl
  );

const certificadoDigitalController = (context) =>
  new CertificadoDigitalController(
    context,
    buscaCertificadoDigitalUseCaseImpl,
    criaCertificadoDigitalUseCaseImpl,
    alteraCertificadoDigitalUseCaseImpl,
    excluiCertificadoDigitalUseCaseImpl,
    buscaClientesUseCaseImpl
  );

const clientePorAppFirebaseController = (context) =>
  new ClientePorAplicativoFirebaseController(
    context,
    buscaClientesUseCaseImpl,
    buscaClientesPorAppFirebaseUseCaseImpl,
    buscaProjetosFirebaseUseCaseImpl
  );

const configuracoesController = (context) =>
  new ConfiguracoesController(
    context,
    alteraUsuarioUseCaseImpl,
    buscaGrupoUsuarioUseCaseImpl,
    refreshUseCaseImpl
  );

export {
  statusServidorController,
  loginController,
  defaultController,
  homeController,
  dashboardController,
  usuarioController,
  projetoFirebaseController,
  clienteFirebaseController,
  moduloController,
  acessoAppController,
  auditoriaController,
  logsAppController,
  logsDesktopController,
  certificadoDigitalController,
  clientePorAppFirebaseController,
  configuracoesController,
};
