import Handler from "@/core/domain/usecase/handler"
import ClienteFirebase from "../../domain/model/clienteFirebase"

const buscaClientesPorAppFirebaseRepository = (axios) => async () => {
    try {
        const response = await axios.get(`/firebase`)

        return response.data ?? []
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default buscaClientesPorAppFirebaseRepository