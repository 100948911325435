export const colunas = [
  {
    text: "Nome",
    value: "nome",
    align: "start",
  },
  {
    text: "Chave",
    value: "chave",
    align: "start",
  },
  {
    text: "Código do Cliente na CGI",
    value: "codCliCgi",
    align: "start",
  },
  {
    text: "Ações",
    value: "acoes",
    align: "end",
    custom: true,
    sortable: false,
  },
];
