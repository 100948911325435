<template>
  <div>
    <dev-tabela
      altura="calc(100vh - 550px)"
      v-model="controller.delta.clientes"
      selecionar-varios
      mostra-acoes
      mostra-pesquisa
      :linhas="controller.deltasClientes"
      :carregar="controller.carregando"
      :colunas="controller.colunas"
      :total-itens="controller.deltasClientes.length"
      :mostra-paginacao="false"
      :alterar="false"
      :deletar="false"
      :key="controller.datatableKey"
      :paginacao-servidor="false"
    >
      <template v-slot:situacaoDeltaCliente="{ item }">
        <div
          class="destaque-delta"
          :style="controller.getColor(item.situacaoDeltaCliente)"
        >
          <span
            ><b> {{ item.situacaoDeltaCliente }} </b></span
          >
        </div>
      </template>
      <template v-slot:situacaoDeltaAplicacao="{ item }">
        <div
          class="destaque-delta"
          :style="controller.getColor(item.situacaoDeltaAplicacao)"
        >
          <span
            ><b> {{ item.situacaoDeltaAplicacao }} </b></span
          >
        </div>
      </template>
      <template v-slot:botoes>
        <div class="d-flex align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="grey primary--text lighten-4"
                class="mx-2 px-2"
                elevation="0"
                v-on="on"
                :disabled="
                  controller.carregando ||
                  !(
                    controller.delta.idProduto &&
                    controller.delta.idBancoDeDados
                  )
                "
                @click="controller.atualizar()"
                x-small
                fab
              >
                <v-icon small> mdi-refresh </v-icon>
              </v-btn>
            </template>
            <span>Atualizar status dos pacifics</span>
          </v-tooltip>

          <v-menu
            v-model="controller.menuFiltro"
            :close-on-content-click="false"
            :close-on-click="false"
            :nudge-width="200"
            offset-x
            offset-y
          >
            <template v-slot:activator="{ on: onMenu, attrs: attrMenu }">
              <v-tooltip bottom>
                <template
                  v-slot:activator="{ on: onTooltip, attrs: attrTooltip }"
                >
                  <v-btn
                    class="white--text font-weight-bold mx-2 px-2"
                    style="
                      background: linear-gradient(to right, #ffa64d, #fd6400);
                      color: #121934;
                    "
                    elevation="0"
                    v-bind="{ ...attrMenu, ...attrTooltip }"
                    v-on="{ ...onMenu, ...onTooltip }"
                    :disabled="
                      controller.carregando ||
                      !(
                        controller.delta.idProduto &&
                        controller.delta.idBancoDeDados
                      )
                    "
                    x-small
                    fab
                  >
                    <v-icon small>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <span>Filtrar registros</span>
              </v-tooltip>
            </template>

            <filtro :controller="controller" />
          </v-menu>
        </div>
      </template>

      <template v-slot:nomeDelta="{ item }">
        <td>
          {{ controller.normalizeNomeDelta(item.nomeDelta) }}
        </td>
      </template>

      <template v-slot:logDeltaCliente="{ item }">
        <td>
          <a
            class="log-link"
            v-if="item.logDeltaCliente"
            @click="controller.abreDialogPreviewLog(item.logDeltaCliente)"
          >
            Visualizar Log
          </a>

          <span v-else> Sem log </span>
        </td>
      </template>

      <template v-slot:logDeltaAplicacao="{ item }">
        <td>
          <a
            v-if="item.logDeltaAplicacao"
            class="log-link"
            @click="controller.abreDialogPreviewLog(item.logDeltaAplicacao)"
          >
            Visualizar Log
          </a>

          <span v-else> Sem log </span>
        </td>
      </template>

      <template v-slot:acoes="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              @click="controller.buscaDeltaCliente(item)"
              color="primary"
              v-on="on"
              small
            >
              mdi-refresh
            </v-icon>
          </template>
          <span>Atualizar status do pacific</span>
        </v-tooltip>
      </template>
    </dev-tabela>

    <dialog-preview-log :controller="controller" />
  </div>
</template>

<script>
import Filtro from "./filtro.vue";
import DialogPreviewLog from "./dialogPreviewLog.vue";

export default {
  components: { Filtro, DialogPreviewLog },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.log-link {
  text-decoration: underline;
}

.destaque-delta {
  border-radius: 25px;
  cursor: default;
  line-height: 28px;
  padding: 0 18px;
}
</style>
