import Handler from "@/core/domain/usecase/handler";
import axios from "axios";

const buscaMenuConsultorsRepository = () => async (payload) => {
  try {
    const response = await axios.put(
      "https://consultors-cgi.cgisoftware.com.br/rest/app/app",
      payload
    );

    return response.data?.ttWebTelaMenu ?? [];
  } catch (error) {
    new Handler().trataErro(error);
  }
};

export default buscaMenuConsultorsRepository;
