import Handler from "@/core/domain/usecase/handler"

const criaCertificadoDigitalRepository = (axios) => async (certificadoDigital) => {
    try {
        await axios.post("/certificado-digital", certificadoDigital)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default criaCertificadoDigitalRepository