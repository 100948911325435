<template>
  <v-container fluid class="dialog-detalhes-bg">
    <v-row dense>
      <v-col class="d-flex justify-end">
        <dev-btn incluir @click="controller.incluirBancoDadosCliente()" />
      </v-col>
    </v-row>

    <v-row
      v-if="
        controller.bancosDadosCliente.admrh.items.length ||
        controller.bancosDadosCliente.consultors.items.length
      "
    >
      <v-col
        lg="6"
        sm="12"
        v-for="banco in controller.bancosDadosCliente"
        :key="banco.label"
        v-if="banco.items.length"
      >
        <dev-cards-list
          :titulo="`Projeto: ${banco.label}`"
          :items="banco.items"
          @alterar-item="controller.alterarBancoDados($event)"
          @deletar-item="controller.deletarBancoDados($event)"
          :carregar="controller.carregandoBancoDados"
          mostra-acoes
          alterar
          deletar
        >
          <template v-slot:legenda="{ item }">
            <div>
              <v-chip color="grey-lighten-2" class="mr-2 chip" small>
                <v-icon left size="15"> mdi-database </v-icon>
                <strong>Banco de Dados: </strong>
                <span>{{ item.nomeBancoDeDados }}</span>
              </v-chip>
            </div>
          </template>
        </dev-cards-list>
      </v-col>
    </v-row>

    <v-row dense v-else>
      <v-col cols="12">
        <div
          class="d-flex flex-column justify-center align-center font-weight-bold headline"
        >
          <div>Nenhum banco de dados</div>
          <dev-lottie
            class="mt-10"
            :options="controller.animacao"
            :height="300"
            :width="400"
          />
        </div>
      </v-col>
    </v-row>

    <dev-dialog
      titulo="Cadastro"
      :dialog="controller.dialogBancoDados"
      @cancelar="controller.cancelarBancoDados($refs.form)"
      @salvar="controller.salvarBancoDados($refs.form)"
    >
      <template v-slot:form>
        <v-form ref="form" :disabled="controller.carregandoBancoDados">
          <v-container fluid class="pa-0">
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  label="Banco de Dados"
                  item-text="nome"
                  item-value="id"
                  v-model="controller.bancoDadosCliente.idBancoDeDados"
                  :items="controller.bancosDados.itens"
                  :rules="[controller.rules.obrigatorio]"
                  @update:search-input="
                    controller.buscaBancosDadosDebounce($event)
                  "
                  outlined
                  rounded
                  dense
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <v-autocomplete
                  label="Produto"
                  item-text="nome"
                  item-value="id"
                  v-model="controller.bancoDadosCliente.idProduto"
                  :items="controller.produtoPaginacao.itens"
                  :rules="[controller.rules.obrigatorio]"
                  @update:search-input="
                    controller.buscaProdutosDebounce($event)
                  "
                  outlined
                  rounded
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </template>
    </dev-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
