import { snackbar } from "@/core/const/devSnackbar";
import { defaultPaginacao } from "@/core/domain/model/paginacao";
import animationData from "../../../assets/search.json";

class ModuloController {
  itens = [];
  programas = [];
  clientePaginacao = defaultPaginacao();
  clientes = [];
  animacao = { animationData: animationData };

  constructor(
    context,
    buscaMenuConsultorsUseCase,
    buscaClientesUseCase,
    buscaProgramasClienteUseCase,
    criaProgramasClienteUseCase,
    criaProgramasClienteMultiploUseCase
  ) {
    this.context = context;
    this.buscaMenuConsultorsUseCase = buscaMenuConsultorsUseCase;
    this.buscaClientesUseCase = buscaClientesUseCase;
    this.buscaProgramasClienteUseCase = buscaProgramasClienteUseCase;
    this.criaProgramasClienteUseCase = criaProgramasClienteUseCase;
    this.criaProgramasClienteMultiploUseCase =
      criaProgramasClienteMultiploUseCase;
  }

  async mounted() {
    try {
      this.clientePaginacao = await this.buscaClientesUseCase({
        itemsPerPage: 1000,
        page: 1,
        sort: ["nome"],
        sortDesc: [false],
      });

      this.itens = await this.buscaMenuConsultorsUseCase();
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  async buscaPrograsClienteSelecionado() {
    try {
      if (this.clientes.length === 1) {
        this.programas = await this.buscaProgramasClienteUseCase(
          this.clientes[0]
        );
        return;
      }

      this.programas = [];
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  async salvar() {
    try {
      if (this.clientes.length === 1) {
        await this.criaProgramasClienteUseCase({
          idCliente: this.clientes[0],
          programas: this.programas,
        });

        snackbar.show({
          message: "Registro salvo com sucesso!",
          color: "green",
          timeout: 3000,
        });
        return;
      }

      snackbar.show({
        message: "Registro salvo com sucesso!",
        color: "green",
        timeout: 3000,
      });

      await this.criaProgramasClienteMultiploUseCase({
        clientes: this.clientes,
        programas: this.programas,
      });
    } catch (error) {
      // snackbar.show({message: error})
    }
  }
}

export default ModuloController;
