import Handler from "@/core/domain/usecase/handler"

const alteraProxyRepository = (axios) => async (proxy) => {
    try {
        await axios.put(`/proxy/${proxy.id}`, proxy)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default alteraProxyRepository