export const colunasClientes = [
  {
    text: "Nome Projeto",
    value: "nomeProjeto",
    align: "start",
    sortable: false,
  },
  {
    text: "Ativo",
    value: "ativo",
    align: "start",
    custom: true,
    sortable: false,
  },
];
