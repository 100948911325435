class ProjetoFirebase {
    constructor({
        id = 0,
        nome = null,
        firebase = null,
        apiToken = null
    }) {
        this.id = id
        this.nome = nome
        this.firebase = firebase
        this.apiToken = apiToken
    }
}

export const defaultProjetoFirebase = () => new ProjetoFirebase({
    id: 0,
    nome: null,
    firebase: null,
    apiToken: null
})

export default ProjetoFirebase