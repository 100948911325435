<template>
  <div>
    <dev-tabela
      nome-tabela="Cliente"
      :linhas="controller.clientePaginacao.itens"
      :colunas="controller.colunas"
      :total-itens="controller.clientePaginacao.total"
      :alterar="hadPermission(clientePermissionId, alterActionId)"
      :deletar="hadPermission(clientePermissionId, deleteActionId)"
      @paginando="controller.mounted($event)"
      @alterar-item="controller.alterarRegistro($event)"
      @deletar-item="controller.excluirRegistro($event)"
      @ver-detalhes="controller.verDetalhesRegistro($event)"
      @linhas-selecionadas="controller.linhasSelecionadas($event)"
      :mostra-acoes="controller.mostraAcoes"
      :carregar="controller.carregando"
      selecao-multipla
      mostra-detalhes
      mostra-pesquisa
      limpar-selecao
    >
      <template v-slot:botoes.prepend>
        <v-tooltip
          v-if="
            hadPermission(clientePermissionId, createActionId) &&
            hadPermission(clientePermissionId, alterActionId) &&
            controller.mostraBotaoModulos
          "
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              @click="controller.adicionarModulo()"
              :disabled="controller.carregando"
              color="grey lighten-4"
              elevation="0"
              class="mx-2 px-2"
              v-on="on"
              x-small
              fab
            >
              <v-icon color="primary">mdi-view-grid-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar módulos</span>
        </v-tooltip>
      </template>

      <template v-slot:botoes>
        <v-row>
          <v-col>
            <div class="mr-2">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="controller.carregando"
                    max-height="36"
                    max-width="36"
                    v-on="on"
                    v-bind="attrs"
                    icon
                  >
                    <v-icon @click="controller.atualizar()">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Recarregar</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col>
            <dev-btn
              incluir
              v-if="hadPermission(clientePermissionId, createActionId)"
              @click="controller.adicionarRegistro()"
              :desabilita-incluir="controller.carregando"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:acoes="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              @click.stop="controller.criarBucket(item)"
              color="primary"
              v-on="on"
              small
            >
              mdi-cloud-upload
            </v-icon>
          </template>
          <span>Cadastrar Bucket</span>
        </v-tooltip>
      </template>
    </dev-tabela>

    <dev-dialog
      :dialog="controller.dialog"
      titulo="Cadastro"
      :desabilita-cancelar="controller.carregando"
      :carregando-salvar="controller.carregando"
      @cancelar="controller.cancelarRegistro()"
      @salvar="controller.salvarRegistro()"
    >
      <template v-slot:form>
        <v-form ref="form" :disabled="controller.carregando">
          <v-text-field
            outlined
            rounded
            dense
            label="Nome"
            :rules="[controller.rules.obrigatorio]"
            v-model="controller.cliente.nome"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Chave"
            :rules="[controller.rules.obrigatorio]"
            v-model="controller.cliente.chave"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Código do Cliente na CGI"
            :rules="[controller.rules.obrigatorio]"
            v-model.number="controller.cliente.codCliCgi"
          ></v-text-field>
        </v-form>
      </template>
    </dev-dialog>

    <dev-dialog
      titulo="Menu geral consultors"
      :dialog="controller.dialogModulo"
      @cancelar="controller.cancelarModulo()"
      @salvar="controller.salvarModulo()"
    >
      <template v-slot:form>
        <modulo :controller="controller" />
      </template>
    </dev-dialog>

    <dialog-detalhes :controller="controller" />
  </div>
</template>

<script>
import { clienteController } from "../di/di";

import DialogDetalhes from "../components/dialogDetalhes.vue";
import Modulo from "../components/modulo.vue";

export default {
  components: { DialogDetalhes, Modulo },
  data: (context) => ({
    controller: clienteController(context),
  }),
};
</script>

<style>
.dialog-detalhes-bg {
  background: rgb(244, 245, 250);
}
</style>
