<template>
  <v-snackbar
    :color="alert.color"
    :right="alert.position.right"
    :center="alert.position.center"
    :top="alert.position.top"
    :timeout="alert.timeout"
    multi-line
    v-model="alert.snackbar"
    content-class="rounded-xl"
    rounded="pill"
  >
    <div v-html="alert.message"></div>
    <template v-slot:action="{ attrs }">
      <div style="padding-right: 10px">
        <v-btn
          v-show="alert.actions.accept"
          v-bind="attrs"
          @click="alert.accept()"          
          text
        >
          Sim
        </v-btn>
        <v-btn
        v-show="alert.actions.accept || alert.actions.show"
        v-bind="attrs"
        @click="alert.decline()"
        icon
        >
        <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </template>
    <v-spacer></v-spacer>
  </v-snackbar>
</template>

<script>
import Vue from 'vue'
export default {
  data: () => ({
    alert: Vue.prototype.devSnackbar
  })
}
</script>
