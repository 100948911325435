import Handler from "@/core/domain/usecase/handler";

const buscaStatusServidorRepository = (axios) => async (id) => {
  try {
    const response = await axios.get(`/status-servidor/${id}`);

    return response?.data ?? {};
  } catch (error) {
    throw new Handler().trataErro(error);
  }
};

export default buscaStatusServidorRepository;
