import Vue from "vue"

Vue.prototype.clientePermissionId =  1
Vue.prototype.projetoPermissionId =  2
Vue.prototype.usuarioPermissionId =  3
Vue.prototype.auditoriaPermissionId =  4
Vue.prototype.proxyPermissionId =  5
Vue.prototype.acessoAppPermissionId =  6
Vue.prototype.produtoPermissionId =  7
Vue.prototype.logAppPermissionId =  8
Vue.prototype.logDesktopPermissionId =  9
Vue.prototype.certificadoDigitalPermissionId =  10

Vue.prototype.createActionId =  1
Vue.prototype.alterActionId =  2
Vue.prototype.deleteActionId =  3
Vue.prototype.readActionId =  4