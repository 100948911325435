class Auditoria {
  constructor({
    dataCriacao = null,
    descricao = null,
    id = 0,
    idAcao = 0,
    idPermissao = 0,
    idUsuario = 0,
    nomeAcao = null,
    nomePermissao = null,
    nomeUsuario = null,
    registro = 0,
    raw = null,
  }) {
    this.dataCriacao = dataCriacao
    this.descricao = descricao
    this.id = id
    this.idAcao = idAcao
    this.idPermissao = idPermissao
    this.idUsuario = idUsuario
    this.nomeAcao = nomeAcao
    this.nomePermissao = nomePermissao
    this.nomeUsuario = nomeUsuario
    this.registro = registro
    this.raw = raw
  }
}


export const defaultAuditoria = () => new Auditoria({
  dataCriacao: null,
  descricao: null,
  id: 0,
  idAcao: 0,
  idPermissao: 0,
  idUsuario: 0,
  nomeAcao: null,
  nomePermissao: null,
  nomeUsuario: null,
  registro: 0,
  raw: null
})

export default Auditoria