import Handler from "@/core/domain/usecase/handler";

const excluiUsuarioRepository = (axios) => async (id) => {
  try {
    await axios.delete(`/usuario/${id}`)
  } catch (error) {
    throw new Handler().trataErro(error)
  }
}

export default excluiUsuarioRepository