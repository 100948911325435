import Paginacao from "@/core/domain/model/paginacao"
import Handler from "@/core/domain/usecase/handler"
import Usuario from "../../domain/model/usuario"

const buscaUsuariosRepository = (axios) => async (paginacao) => {
  try{  
    const response = await axios.get('/usuario', {
      params: paginacao
    })

    return new Paginacao(response.data, Usuario)
  } catch (error) {
    throw new Handler().trataErro(error)
  }
}

export default buscaUsuariosRepository