class Proxy {
  constructor({
    autenticado = true,
    ativo = true,
    chaveProduto = null,
    dataCriacao = null,
    id = 0,
    idCliente = null,
    idProduto = null,
    pacific = null,
    produto = null,
    url = null,
    urlFrontend = null,
    nomeCliente = null,
    chaveCliente = null,
  }) {
    this.autenticado = autenticado;
    this.ativo = ativo;
    this.chaveProduto = chaveProduto;
    this.dataCriacao = dataCriacao;
    this.id = id;
    this.idCliente = idCliente;
    this.idProduto = idProduto;
    this.pacific = pacific;
    this.produto = produto;
    this.url = url;
    this.urlFrontend = urlFrontend ? urlFrontend.split(",") : [];
    this.nomeCliente = nomeCliente;
    this.chaveCliente = chaveCliente;
  }
}

export const defaultProxy = () =>
  new Proxy({
    autenticado: true,
    ativo: true,
    chaveProduto: null,
    dataCriacao: null,
    id: 0,
    idCliente: null,
    idProduto: null,
    pacific: null,
    produto: null,
    url: null,
    urlFrontend: null,
  });

export default Proxy;
