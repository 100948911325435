<template>
  <v-menu
    left
    min-width="200px"
    offset-x
    content-class="rounded-xl"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        x-large
        v-on="on"
        class="rounded-xl mr-4"
      >
        <v-avatar
          color="primary"
          size="50"
        >
          <span
            class="white--text text-h6"
          >
            {{ controller.usuario.login }}
          </span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card
      class="rounded-xl elevation-0"
    >
      <v-list-item-content
        class="justify-center"
      >
        <div
          class="mx-auto text-center"
        >
          <h3 class="mt-2">
            {{ controller.usuario.login }}
          </h3>
          <p
            class="text-caption mt-1"
          >
            {{ controller.usuario.email }}
          </p>
          <v-divider class="my-3" />
          <v-btn
            depressed
            rounded
            text
            @click="controller.navigate(controller.componentConfigPerfil)"
          >
            Editar
          </v-btn>
          <v-divider class="my-3" />
          <v-btn
            depressed
            rounded
            text
            @click="controller.logout()"
          >
            Sair
            <v-icon
              color="error"
              right
            >
              mdi-logout
            </v-icon>
          </v-btn>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
export default {
    props: {
      controller: {
      type: Object,
      required: true,
    },
  }
}
</script>