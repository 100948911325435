import CadastroDeltaController from "../controller/cadastroDeltaController";
import { axiosInstance } from "@/plugins/axios";

import buscaDeltasRepository from "../data/repository/buscaDeltasRepository";
import buscaDeltasUseCase from "../domain/usecase/buscaDeltasUseCase";

import criaDeltaRepository from "../data/repository/criaDeltaRepository";
import criaDeltaUseCase from "../domain/usecase/criaDeltaUseCase";

import alteraDeltaRepository from "../data/repository/alteraDeltaRepository";
import alteraDeltaUseCase from "../domain/usecase/alteraDeltaUseCase";

import deletaDeltaRepository from "../data/repository/deletaDeltaRepository";
import deletaDeltaUseCase from "../domain/usecase/deletaDeltaUseCase";

import buscaProdutosRepository from "../data/repository/buscaProdutosRepository";
import buscaProdutosUseCase from "../domain/usecase/buscaProdutosUseCase";

import buscaBancosDadosRepository from "../data/repository/buscaBancosDadosRepository";
import buscaBancosDadosUseCase from "../domain/usecase/buscaBancosDadosUseCase";

const instance = axiosInstance;

const buscaDeltasRepositoryImpl = buscaDeltasRepository(instance);
const buscaDeltasUseCaseImpl = buscaDeltasUseCase(buscaDeltasRepositoryImpl);

const criaDeltaRepositoryImpl = criaDeltaRepository(instance);
const criaDeltaUseCaseImpl = criaDeltaUseCase(criaDeltaRepositoryImpl);

const alteraDeltaRepositoryImpl = alteraDeltaRepository(instance);
const alteraDeltaUseCaseImpl = alteraDeltaUseCase(alteraDeltaRepositoryImpl);

const deletaDeltaRepositoryImpl = deletaDeltaRepository(instance);
const deletaDeltaUseCaseImpl = deletaDeltaUseCase(deletaDeltaRepositoryImpl);

const buscaProdutosRepositoryImpl = buscaProdutosRepository(instance);
const buscaProdutosUseCaseImpl = buscaProdutosUseCase(
  buscaProdutosRepositoryImpl
);

const buscaBancosDadosRepositoryImpl = buscaBancosDadosRepository(instance);
const buscaBancosDadosUseCaseImpl = buscaBancosDadosUseCase(
  buscaBancosDadosRepositoryImpl
);

export const cadastroDeltaController = (context) =>
  new CadastroDeltaController(
    context,
    buscaDeltasUseCaseImpl,
    criaDeltaUseCaseImpl,
    alteraDeltaUseCaseImpl,
    deletaDeltaUseCaseImpl,
    buscaProdutosUseCaseImpl,
    buscaBancosDadosUseCaseImpl
  );
