export const colunasAplicativos = [
  {
    text: "Nome Cliente",
    value: "nomeCliente",
    align: "start",
    sortable: false,
  },
  {
    text: "Ativo",
    value: "ativo",
    align: "start",
    custom: true,
    sortable: false,
  },
];
