const buscaUsuariosUseCase = (repository) => async (paginacao) => {
  try {
    paginacao['sort'] = paginacao.sortBy.join(",")
    paginacao['descending'] = paginacao.sortDesc.join(",")
    const usuarios = await repository(paginacao)
    return usuarios
  } catch (error) {
    throw error
  }
}

export default buscaUsuariosUseCase