import AplicacaoDeltaControler from "../controller/aplicacaoDeltaController";
import { axiosInstance } from "@/plugins/axios";

import buscaProdutosRepository from "../data/repository/buscaProdutosRepository";
import buscaProdutosUseCase from "../domain/usecase/buscaProdutosUseCase";

import buscaBancosDadosRepository from "../data/repository/buscaBancosDadosRepository";
import buscaBancosDadosUseCase from "../domain/usecase/buscaBancosDadosUseCase";

import buscaDeltasRepository from "../data/repository/buscaDeltasRepository";
import buscaDeltasUseCase from "../domain/usecase/buscaDeltasUseCase";

import buscaDeltasClientesRepository from "../data/repository/buscaDeltasClientesRepository";
import buscaDeltasClientesUseCase from "../domain/usecase/buscaDeltasClienteUseCase";

import buscaDeltaClienteRepository from "../data/repository/buscaDeltaClienteRepository";
import buscaDeltaClienteUseCase from "../domain/usecase/buscaDeltaClienteUseCase";

import aplicaDeltaRepository from "../data/repository/aplicaDeltaRepository";
import aplicaDeltaUseCase from "../domain/usecase/aplicaDeltaUseCase";

const instance = axiosInstance;

const buscaProdutosRepositoryImpl = buscaProdutosRepository(instance);
const buscaProdutosUseCaseImpl = buscaProdutosUseCase(
  buscaProdutosRepositoryImpl
);

const buscaBancosDadosRepositoryImpl = buscaBancosDadosRepository(instance);
const buscaBancosDadosUseCaseImpl = buscaBancosDadosUseCase(
  buscaBancosDadosRepositoryImpl
);

const buscaDeltasRepositoryImpl = buscaDeltasRepository(instance);
const buscaDeltasUseCaseImpl = buscaDeltasUseCase(buscaDeltasRepositoryImpl);

const buscaDeltasClientesRepositoryImpl =
  buscaDeltasClientesRepository(instance);
const buscaDeltasClientesUseCaseImpl = buscaDeltasClientesUseCase(
  buscaDeltasClientesRepositoryImpl
);

const buscaDeltaClienteRepositoryImpl = buscaDeltaClienteRepository(instance)
const buscaDeltaClienteUseCaseImpl = buscaDeltaClienteUseCase(buscaDeltaClienteRepositoryImpl)

const aplicaDeltaRepositoryImpl = aplicaDeltaRepository(instance);
const aplicaDeltaUseCaseImpl = aplicaDeltaUseCase(aplicaDeltaRepositoryImpl);

export const aplicacaoDeltaController = (context) =>
  new AplicacaoDeltaControler(
    context,
    buscaProdutosUseCaseImpl,
    buscaBancosDadosUseCaseImpl,
    buscaDeltasUseCaseImpl,
    buscaDeltasClientesUseCaseImpl,
    buscaDeltaClienteUseCaseImpl,
    aplicaDeltaUseCaseImpl,
  );
