import Handler from "@/core/domain/usecase/handler"

const criaClienteFirebaseRepository = (axios) => async (clienteFirebase, projeto, hash) => {
    try {
        await axios.post(`/firebase/${projeto}/${clienteFirebase.id}?hash=${hash}`, clienteFirebase)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default criaClienteFirebaseRepository