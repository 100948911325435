<template>
  <div>
    <dashboard :controller="controller" />
  </div>
</template>

<script>
import Dashboard from "../components/dashboard.vue";
import { clientesPorAppController } from "../di/di";

export default {
  components: { Dashboard },
  data: (context) => ({
    controller: clientesPorAppController(context),
  }),
  async mounted() {
    await this.controller.mounted();
  },
};
</script>
