import { setJwtAuthToken } from "../model/jwtAuthToken"

const loginUseCase = (repository) => async (username, password) => {
  try {
    const jwtAuthToken = await repository(username, password)
    setJwtAuthToken(jwtAuthToken)
    
    return true
  } catch (error) {
    throw error
  }
}

export default loginUseCase