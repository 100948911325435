import Handler from "@/core/domain/usecase/handler";

const buscaClientesRepository =
  (axios) =>
  async ({ itemsPerPage, page }) => {
    try {
      const response = await axios.get("/cliente", {
        params: { itemsPerPage, page },
      });

      return response?.data ?? [];
    } catch (error) {
      throw new Handler().trataErro(error);
    }
  };

export default buscaClientesRepository;
