import Handler from '@/core/domain/usecase/handler';

const alterarSenhaRepository = (axios) => async (usuario) => {
  try {
    await axios.put(`/usuario/${usuario.id}/senha`, usuario)
  } catch (err) {
    throw new Handler().trataErro(err)
  }
}

export default alterarSenhaRepository