import Handler from "@/core/domain/usecase/handler";

const buscaStatusServidoresRepository = (axios) => async (paginacao) => {
  try {
    const response = await axios.get("/status-servidor", {
      params: paginacao,
    });

    return response?.data?.itens ?? [];
  } catch (error) {
    throw new Handler().trataErro(error);
  }
};

export default buscaStatusServidoresRepository;
