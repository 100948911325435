<template>
  <v-card class="rounded-xl" elevation="0" :height="'calc(100vh - 110px)'">
    <v-card-title>Configurações</v-card-title>

    <v-card-text>
      <v-tabs v-model="controller.tab">
        <v-tab v-for="(tab, index) in controller.tabs" :key="index">{{
          tab
        }}</v-tab>
      </v-tabs>
      <v-tabs-items class="nav" v-model="controller.tab">
        <v-tab-item v-for="(tab, index) in controller.tabs" :key="index">
          <perfil
            v-if="index == 0"
            :controller="controller"
          ></perfil>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { configuracoesController } from "@/di/di";
import perfil from "../component/Perfil.vue";

export default {
  components: {
    perfil,
  },
  data: (context) => ({
    controller: configuracoesController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
};
</script>