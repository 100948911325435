import Paginacao from "@/core/domain/model/paginacao";
import Handler from "@/core/domain/usecase/handler";
import GrupoUsuario from '../../domain/model/grupoUsuario'

const buscaGrupoUsuarioRepository = (axios) => async (paginacao) => {
  try {
    const response = await axios.get("/grupo-usuario", {
      params: paginacao
    })

    return new Paginacao(response.data, GrupoUsuario)
  } catch (error) {
    throw new Handler().trataErro(error)
  }
}

export default buscaGrupoUsuarioRepository