<template>
  <div>
    <dev-tabela
      nome-tabela="Auditoria"
      mostra-pesquisa
      @paginando="controller.mounted($event)"
      :carregar="controller.carregando"
      :alterar="false"
      :deletar="false"
      :colunas="controller.colunas"
      :linhas="controller.auditoriaPaginacao.itens"
      :total-itens="controller.auditoriaPaginacao.total"
    >
      <template v-slot:botoes>
        <v-row>
          <v-col>
            <div class="mr-2">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="controller.carregando"
                    max-height="36"
                    max-width="36"
                    v-on="on"
                    v-bind="attrs"
                    icon
                  >
                    <v-icon @click="controller.atualizar()">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Recarregar</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:raw="{ item }">
        <td>
          <a
            v-if="item.raw"
            class="log-link"
            @click="controller.abreDialogPreviewLog(item.raw)"
          >
            Visualizar Informações
          </a>

          <span v-else> Sem Informação </span>
        </td>
      </template>
    </dev-tabela>
    <dialog-preview-log :controller="controller" />
  </div>
</template>

<script>
import { auditoriaController } from "@/di/di";
import DialogPreviewLog from "../components/dialogPreviewLog.vue";

export default {
  components: { DialogPreviewLog },
  data: (context) => ({
    controller: auditoriaController(context),
  }),
};
</script>

<style scoped>
.log-link {
  text-decoration: underline;
}
</style>
