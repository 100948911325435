export const colunas = [
  {
    text: "Cliente",
    value: "nome",
  },
  {
    text: "Delta/Patch",
    value: "nomeDelta",
    custom: true,
  },
  {
    text: "Situação Delta Cliente",
    value: "situacaoDeltaCliente",
    custom: true,
  },
  {
    text: "Log Delta Cliente",
    value: "logDeltaCliente",
    custom: true,
  },
  {
    text: "Situação Aplicação do Delta",
    value: "situacaoDeltaAplicacao",
    custom: true,
  },
  {
    text: "Log Aplicação do Delta",
    value: "logDeltaAplicacao",
    custom: true,
  },
  {
    text: "Motivo Log",
    value: "motivoLog",
  },
  {
    text: "Ações",
    value: "acoes",
    align: "end",
    custom: true,
    sortable: false,
  },
];
