var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('dev-tabela',{key:_vm.controller.datatableKey,attrs:{"altura":"calc(100vh - 550px)","selecionar-varios":"","mostra-acoes":"","mostra-pesquisa":"","linhas":_vm.controller.deltasClientes,"carregar":_vm.controller.carregando,"colunas":_vm.controller.colunas,"total-itens":_vm.controller.deltasClientes.length,"mostra-paginacao":false,"alterar":false,"deletar":false,"paginacao-servidor":false},scopedSlots:_vm._u([{key:"situacaoDeltaCliente",fn:function({ item }){return [_c('div',{staticClass:"destaque-delta",style:(_vm.controller.getColor(item.situacaoDeltaCliente))},[_c('span',[_c('b',[_vm._v(" "+_vm._s(item.situacaoDeltaCliente)+" ")])])])]}},{key:"situacaoDeltaAplicacao",fn:function({ item }){return [_c('div',{staticClass:"destaque-delta",style:(_vm.controller.getColor(item.situacaoDeltaAplicacao))},[_c('span',[_c('b',[_vm._v(" "+_vm._s(item.situacaoDeltaAplicacao)+" ")])])])]}},{key:"botoes",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-2 px-2",attrs:{"color":"grey primary--text lighten-4","elevation":"0","disabled":_vm.controller.carregando ||
                !(
                  _vm.controller.delta.idProduto &&
                  _vm.controller.delta.idBancoDeDados
                ),"x-small":"","fab":""},on:{"click":function($event){return _vm.controller.atualizar()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Atualizar status dos pacifics")])]),_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":false,"nudge-width":200,"offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onMenu, attrs: attrMenu }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip, attrs: attrTooltip }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text font-weight-bold mx-2 px-2",staticStyle:{"background":"linear-gradient(to right, #ffa64d, #fd6400)","color":"#121934"},attrs:{"elevation":"0","disabled":_vm.controller.carregando ||
                    !(
                      _vm.controller.delta.idProduto &&
                      _vm.controller.delta.idBancoDeDados
                    ),"x-small":"","fab":""}},'v-btn',{ ...attrMenu, ...attrTooltip },false),{ ...onMenu, ...onTooltip }),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter")])],1)]}}],null,true)},[_c('span',[_vm._v("Filtrar registros")])])]}}]),model:{value:(_vm.controller.menuFiltro),callback:function ($$v) {_vm.$set(_vm.controller, "menuFiltro", $$v)},expression:"controller.menuFiltro"}},[_c('filtro',{attrs:{"controller":_vm.controller}})],1)],1)]},proxy:true},{key:"nomeDelta",fn:function({ item }){return [_c('td',[_vm._v(" "+_vm._s(_vm.controller.normalizeNomeDelta(item.nomeDelta))+" ")])]}},{key:"logDeltaCliente",fn:function({ item }){return [_c('td',[(item.logDeltaCliente)?_c('a',{staticClass:"log-link",on:{"click":function($event){return _vm.controller.abreDialogPreviewLog(item.logDeltaCliente)}}},[_vm._v(" Visualizar Log ")]):_c('span',[_vm._v(" Sem log ")])])]}},{key:"logDeltaAplicacao",fn:function({ item }){return [_c('td',[(item.logDeltaAplicacao)?_c('a',{staticClass:"log-link",on:{"click":function($event){return _vm.controller.abreDialogPreviewLog(item.logDeltaAplicacao)}}},[_vm._v(" Visualizar Log ")]):_c('span',[_vm._v(" Sem log ")])])]}},{key:"acoes",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.controller.buscaDeltaCliente(item)}}},on),[_vm._v(" mdi-refresh ")])]}}],null,true)},[_c('span',[_vm._v("Atualizar status do pacific")])])]}}]),model:{value:(_vm.controller.delta.clientes),callback:function ($$v) {_vm.$set(_vm.controller.delta, "clientes", $$v)},expression:"controller.delta.clientes"}}),_c('dialog-preview-log',{attrs:{"controller":_vm.controller}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }