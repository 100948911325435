class Handler {
  trataErro(error) {
    if (error) {
      if (error.isAxiosError) {
        switch (error.response.status) {
          case 500:
            return error.response.data.message;
          default:
            return error.response.data.message;
        }
      }
    }

    return error;
  }

  getDefaultPayload({
    usuario = "",
    senha = "",
    programa,
    metodo,
    valor = "",
  }) {
    return {
      uid: usuario,
      pwd: senha,
      ptoP: programa,
      params: [
        {
          parametro: "pcMetodo",
          data_type: "char",
          param_type: "input",
          valor: metodo,
        },
        {
          parametro: "pcParametros",
          data_type: "longchar",
          param_type: "input",
          valor: valor,
        },
        {
          parametro: "pcRetorno",
          data_type: "longchar",
          param_type: "output",
          valor: "",
        },
      ],
    };
  }
}

export default Handler;
