export const testaAtivoString = (ativo) => ativo == 'Sim' ? true : false

export const testaAtivoBoolean = (ativo) => ativo ? 'Sim' : 'Nao'

export const senhasCgi = ["deploy123"]

export const listToTree = (list, chave, chavePai, descricao) => {
    list.sort(compare)

    let map = {},
        node,
        roots = [],
        i;
    for (i = 0; i < list.length; i += 1) {
        map[list[i][chave]] = i;
        list[i].children = [];
        list[i].nomeComposto = `${list[i][chave]} - ${list[i][descricao]}`;
    }
    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node[chavePai] !== 0) {
            list[map[node[chavePai]]]?.children?.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export const compare = (a, b) => {
    if (a.ordem < b.ordem) {
        return -1;
    }
    if (a.ordem > b.ordem) {
        return 1;
    }
    return 0;
}

export const isJSON = (str) => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}