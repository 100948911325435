export const filtro = {
  status: {
    value: null,
    items: [
      { text: "Todos", value: null },
      { text: "Com erro", value: 1 },
      { text: "Aplicados com sucesso", value: 2 },
    ],
  },
};
