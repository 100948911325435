const buscaAuditoriaUseCase = (repository) => async (paginacao) => {
  try {
    paginacao['sort'] = paginacao?.sortBy?.join(",") ?? null
    paginacao['descending'] = paginacao?.sortDesc?.join(",") ?? null
    const auditoria = await repository(paginacao)
    return auditoria
  } catch (error) {
    throw error
  }
}

export default buscaAuditoriaUseCase