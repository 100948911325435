<template>
  <div>
    <dev-tabela
      nome-tabela="Certificados Digitais"
      :linhas="controller.certificadoDigitalPaginacao.itens"
      :colunas="controller.colunas"
      :total-itens="controller.certificadoDigitalPaginacao.total"
      :carregar="controller.carregando"
      :alterar="false"
      :deletar="false"
      :paginacao-servidor="false"
      :mostra-paginacao="false"
      mostra-pesquisa
    >
      <template v-slot:botoes>
        <v-row>
          <v-col>
            <div class="mr-2">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="controller.carregando"
                    max-height="36"
                    max-width="36"
                    v-on="on"
                    v-bind="attrs"
                    icon
                  >
                    <v-icon @click="controller.atualizar()">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Recarregar</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:usaNfe="{ item }">
        <v-checkbox
          class="mt-0"
          dense
          readonly
          hide-details
          v-model="item.usaNfe"
        />
      </template>

      <template v-slot:usaCte="{ item }">
        <v-checkbox
          class="mt-0"
          dense
          readonly
          hide-details
          v-model="item.usaCte"
        />
      </template>

      <template v-slot:vencimento="{ item }">
        <td>
          {{
            item.vencimento
              ? dayjs(item.vencimento).format("DD/MM/YYYY hh:mm:ss")
              : "Proxy (não autenticado) não configurado"
          }}
        </td>
      </template>
    </dev-tabela>

    <dev-dialog
      :dialog="controller.dialog"
      titulo="Cadastro"
      @cancelar="controller.cancelar()"
      @salvar="controller.salvar()"
    >
      <template v-slot:form>
        <v-form ref="form">
          <v-autocomplete
            label="Cliente"
            dense
            :items="controller.clientePaginacao.itens"
            rounded
            outlined
            item-text="nome"
            item-value="id"
            @update:search-input="controller.buscaClientesDebounce($event)"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model="controller.certificadoDigital.idCliente"
          ></v-autocomplete>
          <v-row>
            <v-col>
              <v-switch
                inset
                dense
                label="Usa NFE"
                v-model="controller.certificadoDigital.usaNfe"
              ></v-switch>
            </v-col>
            <v-col>
              <v-switch
                inset
                dense
                label="Usa CTE"
                v-model="controller.certificadoDigital.usaCte"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </dev-dialog>
  </div>
</template>

<script>
import { certificadoDigitalController } from "@/di/di";
export default {
  data: (context) => ({
    controller: certificadoDigitalController(context),
  }),
  async mounted() {
    await this.controller.mounted();
  },
};
</script>
