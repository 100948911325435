<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid style="background-color: #f4f5fa">
        <v-row align="center" justify="center">
          <v-col cols="12" xl="7" lg="8">
            <v-card class="rounded-xl" style="margin: 30" elevation="0">
              <v-card-text class="pa-8">
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <div
                      style="height: 80vh"
                      class="d-flex flex-column align-start justify-space-between fill-height"
                      :class="{ 'pa-10 pb-15': !$vuetify.breakpoint.smAndDown }"
                    >
                      <div
                        class="d-flex flex-column display-1 font-weight-bold full-width"
                      >
                        <div class="align-self-center">
                          <v-img
                            :src="'/cgi-preta-nova.png'"
                            :lazy-src="'/cgi-preta-nova.png'"
                            max-height="100"
                            min-height="100"
                            max-width="200"
                            class="pa-2 ml-0"
                            contain
                          ></v-img>
                        </div>
                        <div class="pt-4">
                          {{
                            !controller.trocarSenha
                              ? "Login"
                              : "Alteração de senha"
                          }}
                        </div>
                      </div>

                      <div style="width: 100%">
                        <v-form ref="formLogin" v-if="!controller.trocarSenha">
                          <label
                            for="email"
                            class="d-flex justify-start ml-1 mb-3 text-subtitle-1 font-weight-bold"
                          >
                            E-mail *
                          </label>
                          <v-text-field
                            id="email"
                            append-icon="mdi-account"
                            name="E-mail"
                            type="text"
                            :rules="controller.emailRules"
                            v-model="controller.usuario"
                            @keyup.enter="controller.login()"
                            outlined
                            required
                            rounded
                          />

                          <label
                            for="senha"
                            class="d-flex justify-start ml-1 mb-3 text-subtitle-1 font-weight-bold"
                          >
                            Senha *
                          </label>
                          <v-text-field
                            id="senha"
                            @keyup.enter="controller.login()"
                            @click:append="
                              controller.exibirSenha = !controller.exibirSenha
                            "
                            :append-icon="
                              controller.exibirSenha ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            name="senha"
                            :type="controller.exibirSenha ? 'text' : 'password'"
                            v-model="controller.senha"
                            required
                            :rules="[(v) => !!v || 'Informe a senha']"
                            outlined
                            rounded
                          />

                          <v-btn
                            rounded
                            block
                            width="40"
                            class="mr-5 btn-gradient"
                            elevation="0"
                            height="50px"
                            @click="controller.login()"
                          >
                            Entrar
                          </v-btn>
                        </v-form>
                        <v-form ref="formAlterarSenha" v-else>
                          <div>
                            <label
                              for="senha"
                              class="d-flex justify-start ml-1 mb-3 text-subtitle-1 font-weight-bold"
                            >
                              Senha *
                            </label>
                            <v-text-field
                              @click:append="
                                controller.exibirSenha = !controller.exibirSenha
                              "
                              :type="
                                controller.exibirSenha ? 'text' : 'password'
                              "
                              :append-icon="
                                controller.exibirSenha
                                  ? 'mdi-eye'
                                  : 'mdi-eye-off'
                              "
                              id="senha"
                              name="senha"
                              @keyup.enter="controller.alterarSenha()"
                              v-model="controller.senha"
                              required
                              :rules="[(v) => !!v || 'Informe a senha']"
                              outlined
                              rounded
                            />
                            <v-btn
                              rounded
                              block
                              width="40"
                              class="mr-5 btn-gradient"
                              height="50px"
                              @click="controller.alterarSenha()"
                            >
                              Alterar Senha
                            </v-btn>
                          </div>
                        </v-form>
                      </div>
                      <div
                        class="mt-6 text-h5 font-weight-bold"
                        style="width: 100%"
                      >
                        <v-row
                          class="d-flex justify-center align-center"
                          justify="center"
                        >
                          <v-col cols="12" class="d-flex justify-center">
                            <h4 class="font-weight-light">
                              Siga-nos nas redes sociais!
                            </h4>
                          </v-col>
                          <v-col cols="12" class="d-flex justify-space-around">
                            <v-icon
                              @click="controller.toFacebook()"
                              color="#166FE5"
                              large
                              >mdi-facebook</v-icon
                            >
                            <v-icon
                              @click="controller.toInstagram()"
                              color="primary"
                              large
                              >mdi-instagram</v-icon
                            >
                            <v-icon
                              @click="controller.toLinkedin()"
                              color="#0A66C2"
                              large
                              >mdi-linkedin</v-icon
                            >
                            <v-icon
                              @click="controller.toTwitter()"
                              color="#2AA9E0"
                              large
                              >mdi-twitter</v-icon
                            >
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    class="pa-10"
                    v-if="!$vuetify.breakpoint.smAndDown"
                  >
                    <v-card class="rounded-xl" elevation="0">
                      <v-img src="/login.jpg">
                        <template v-slot>
                          <v-row
                            class="fill-height ma-0 d-flex align-center justify-center"
                          >
                            <div
                              class="pa-5 display-1 font-weight-bold white--text glass"
                            >
                              A necessidade é a mãe da inovação ✨
                            </div>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { loginController } from "@/di/di";

export default {
  name: "LoginView",
  data: (context) => ({
    controller: loginController(context),
  }),
};
</script>
<style scoped>
.glass {
  background: rgba(255, 255, 255, 0.2);
  max-width: 80%;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 0px solid rgba(255, 255, 255, 0.3);
}

.btn-gradient {
  background: rgb(160, 221, 229);
  background: linear-gradient(
    151deg,
    rgba(160, 221, 229, 1) 0%,
    rgba(84, 141, 202, 1) 100%
  );
  color: white;
}

.full-width {
  width: 100%;
}
</style>
