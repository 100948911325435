import Handler from "@/core/domain/usecase/handler";

const criaProgramasClienteMultiploRepository = (axios) => async (programas) => {
  try {
    await axios.post(`/clientes/liberacao-programa-cliente`, programas);
  } catch (error) {
    new Handler().trataErro(error);
  }
};

export default criaProgramasClienteMultiploRepository;
