<template>
  <v-row style="height: calc(100vh - 120px)">
    <v-col lg="4">
      <v-skeleton-loader
        class="rounded-xl"
        v-if="controller.loading"
        v-bind="{
        class: 'mb-6',
        boilerplate: false,
        elevation: 0,
      }"
        type="chip"
      >
      </v-skeleton-loader>
      <div
        v-else
        class="font-weight-bold display-1 my-10"
      >Backups</div>

      <v-skeleton-loader
        class="rounded-xl"
        v-if="controller.loading"
        v-bind="{
        class: 'mb-6',
        boilerplate: false,
        elevation: 0,
      }"
        type="article"
      >
      </v-skeleton-loader>
      <card-backup
        v-if="!controller.loading"
        cor="orange"
        :data-criacao="dayjs(controller.postgres.dataCriacao).format('DD/MM/YYYY [às] HH:mm:ss')"
        :tamanho="controller.postgres.tamanho"
        titulo="Banco de dados do dev"
        :bucket="controller.postgres.bucket"
        :caminho="controller.postgres.path"
      ></card-backup>

      <card-backup
        v-if="!controller.loading"
        cor="blue"
        :data-criacao="dayjs(controller.report.dataCriacao).format('DD/MM/YYYY [às] HH:mm:ss')"
        :tamanho="controller.report.tamanho"
        :bucket="controller.report.bucket"
        :caminho="controller.report.path"
        titulo="JSReport"
      ></card-backup>

    </v-col>
    <v-divider
      class="ma-4"
      vertical
    ></v-divider>
  </v-row>
</template>
  
<script>
import CardBackup from "../component/CardBackup.vue";
import { dashboardController } from "@/di/di";
export default {
  data: (context) => ({
    controller: dashboardController(context),
  }),
  components: {
    CardBackup,
  },
  mounted() {
    this.controller.mounted();
  },
};
</script>
  
<style>
</style>