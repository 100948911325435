class Usuario {
  constructor({
    dataCriacao = null,
    email = null,
    grupoUsuario = null,
    id = 0,
    idGrupoUsuario = null,
    login = null,
    trocarSenha = false
  }) {
    this.dataCriacao = dataCriacao
    this.email = email
    this.grupoUsuario = grupoUsuario
    this.id = id
    this.idGrupoUsuario = idGrupoUsuario
    this.login = login
    this.trocarSenha = trocarSenha
  }
}

export const defaultUsuario = () => new Usuario({
  dataCriacao: null,
  email: null,
  grupoUsuario: null,
  id: 0,
  idGrupoUsuario: null,
  login: null,
  trocarSenha: false
})

export default Usuario