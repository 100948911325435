<template>
  <dev-dialog
    titulo="Cadastro"
    :dialog="controller.dialogForm"
    :desabilita-cancelar="controller.carregando"
    :carregando-salvar="controller.carregando"
    @cancelar="controller.cancelar()"
    @salvar="controller.salvar()"
  >
    <template v-slot:form>
      <v-form ref="cadastro" :disabled="controller.carregando">
        <v-container fluid class="pa-0">
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                label="Produto"
                item-text="nome"
                item-value="id"
                v-model="controller.delta.idProduto"
                :items="controller.produtos.itens"
                :rules="[controller.rules.obrigatorio]"
                :disabled="!!controller.delta.id"
                @update:search-input="controller.buscaProdutosDebounce($event)"
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-file-input
                label="Arquivo .df"
                accept=".df"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon=""
                v-model="controller.arquivoDelta"
                :disabled="!!controller.delta.id"
                :rules="[controller.rules.tipoArquivoDf]"
                @change="controller.leArquivoDelta($event)"
                @click:clear="controller.limpaArquivoDelta()"
                outlined
                rounded
                dense
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                label="Banco de Dados"
                item-text="nome"
                item-value="id"
                v-model="controller.delta.idBancoDeDados"
                :items="controller.bancosDados.itens"
                :rules="[controller.rules.obrigatorio]"
                :disabled="!!controller.delta.id"
                @update:search-input="
                  controller.buscaBancosDadosDebounce($event)
                "
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6">
              <v-text-field
                label="Número Delta"
                data-maska="###"
                v-maska
                v-model="controller.delta.numDelta"
                :rules="[controller.rules.obrigatorio]"
                :disabled="!!controller.delta.id"
                outlined
                rounded
                dense
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                label="Número Patch"
                data-maska="####"
                v-maska
                v-model="controller.delta.numPatch"
                :rules="[controller.rules.obrigatorio]"
                :disabled="!!controller.delta.id"
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-textarea
                v-model="controller.delta.textoDoArquivoDF"
                :rules="[controller.rules.obrigatorio]"
                rows="10"
                auto-grow
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </dev-dialog>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
