const criaProgramasClienteMultiploUseCase =
  (repository) => async (programas) => {
    try {
      return repository(programas);
    } catch (error) {
      throw error;
    }
  };

export default criaProgramasClienteMultiploUseCase;
