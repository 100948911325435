<template>
  <v-dialog
    v-model="dialog"
    width="600"
    content-class="rounded-xl"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title>{{ titulo }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-5">
        <slot name="form"></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-6">
        <dev-btn
          cancelar
          @click="$emit('cancelar')"
          :desabilita-cancelar="desabilitaCancelar"
        ></dev-btn>
        <v-spacer></v-spacer>
        <dev-btn
          salvar
          @click="$emit('salvar')"
          :desabilita-salvar="desabilitaSalvar"
          :carregando-salvar="carregandoSalvar"
        ></dev-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    titulo: {
      type: String,
      default: () => "",
    },
    "desabilita-salvar": {
      type: Boolean,
      default: () => false,
    },
    "desabilita-cancelar": {
      type: Boolean,
      default: () => false,
    },
    "carregando-salvar": {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
