import Handler from "@/core/domain/usecase/handler";

const alteraUsuarioRepository = (axios) => async (usuario) => {
  try {
    await axios.put(`/usuario/${usuario.id}`, usuario)
  } catch (error) {
    throw new Handler().trataErro(error)
  }
}

export default alteraUsuarioRepository