class Usuario {
  constructor({
    dataCriacao = null,
    email = null,
    grupoUsuario = null,
    id = 0,
    idGrupoUsuario = null,
    login = null,
    trocarSenha = false,
    bloqueado = false
  }) {
    this.dataCriacao = dataCriacao
    this.email = email
    this.grupoUsuario = grupoUsuario
    this.id = id
    this.idGrupoUsuario = idGrupoUsuario
    this.login = login
    this.trocarSenha = trocarSenha
    this.bloqueado = bloqueado
  }
}

export const defaultUsuario = () => new Usuario({})

export default Usuario