import Handler from "@/core/domain/usecase/handler"

const alteraCertificadoDigitalRepository = (axios) => async (certificadoDigital) => {
    try {
        await axios.put(`/certificado-digital/${certificadoDigital.id}`, certificadoDigital)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default alteraCertificadoDigitalRepository