export const colunas = [
  {
    text: "Cliente",
    align: "left",
    value: "cliente",
  },
  {
    text: "Aplicativo",
    align: "left",
    value: "aplicativo",
  },
  {
    text: "Usuário",
    align: "left",
    value: "usuario",
  },
  {
    text: "Data/Hora",
    value: "dataCriacao",
    custom: true,
  },
  {
    text: "URL",
    value: "url",
    custom: true,
  },
  {
    text: "Método",
    value: "metodo",
    custom: true
  },
  {
    text: "Programa",
    align: "left",
    value: "programa",
  },
  {
    text: "Versão",
    align: "left",
    value: "versao",
  },
]