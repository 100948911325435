<template>
  <v-card width="500">
    <v-card-title> Filtro </v-card-title>

    <v-card-text>
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col cols="12">
            <v-autocomplete
              label="Produto"
              item-text="nome"
              item-value="id"
              v-model="controller.filtro.idProduto"
              :items="controller.produtos.itens"
              @update:search-input="controller.buscaProdutosDebounce($event)"
              outlined
              rounded
              dense
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-autocomplete
              label="Banco de Dados"
              item-text="nome"
              item-value="id"
              v-model="controller.filtro.idBancoDeDados"
              :items="controller.bancosDados.itens"
              @update:search-input="controller.buscaBancosDadosDebounce($event)"
              outlined
              rounded
              dense
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions class="d-flex align-center justify-space-between">
      <dev-btn cancelar @click="controller.cancelarFiltro()" />
      <dev-btn limpar @click="controller.limparFiltro()" />
      <dev-btn salvar @click="controller.filtrar()" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
