class Delta {
  constructor({
    id = 0,
    idBancoDeDados = null,
    idProduto = null,
    numDelta = null,
    numPatch = null,
    textoDoArquivoDF = "",
  }) {
    this.id = id;
    this.idBancoDeDados = idBancoDeDados;
    this.idProduto = idProduto;
    this.numDelta = String(numDelta).padStart(3, '0');
    this.numPatch = String(numPatch).padStart(4, '0');
    this.textoDoArquivoDF = textoDoArquivoDF;
  }

  toSave() {
    const payload = { ...this };

    payload.numDelta = parseInt(payload.numDelta)
    payload.numPatch = parseInt(payload.numPatch)

    if (!payload.id) {
      delete payload.id;
    }

    return payload;
  }
}

export default Delta;
