<template>
  <div>
    <v-app style="background-color: #F4F5FA">
      <dev-snackbar/>
      <dev-alert/>
      <component :is="controller.component" />
    </v-app>
  </div>
</template>

<script>
import { defaultController } from "@/di/di";
export default {
  data: (context) => ({
    controller: defaultController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
};
</script>

<style>
</style>