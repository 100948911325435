<template>
  <v-container fluid class="dialog-detalhes-bg">
    <v-row dense>
      <v-col class="d-flex justify-end">
        <dev-btn
          incluir
          v-if="hadPermission(certificadoDigitalPermissionId, createActionId)"
          @click="controller.adicionarCertificadoDigital()"
        ></dev-btn>
      </v-col>
    </v-row>

    <v-row
      v-if="
        controller.carregandoCertificadosDigitais ||
        !controller.certificadosDigitais.itens.length
      "
    >
      <v-col cols="12">
        <div
          class="d-flex flex-column justify-center align-center font-weight-bold headline"
        >
          <div>
            {{
              controller.carregandoCertificadosDigitais
                ? "Carregando..."
                : "Nenhum certificado digital"
            }}
          </div>
          <dev-lottie
            class="mt-10"
            :options="controller.animacao"
            :height="300"
            :width="400"
          />
        </div>
      </v-col>
    </v-row>

    <v-row dense v-else>
      <v-col cols="12">
        <dev-cards-list
          titulo="Certificados"
          colunas="6"
          :items="controller.certificadosDigitais.itens"
          :configuracao="controller.detalhes.configuracaoCertificadoDigital"
          @alterar-item="controller.alterarCertificadoDigital($event)"
          @deletar-item="controller.excluirCertificadoDigital($event)"
          :carregar="controller.carregandoCertificadoDigital"
          :alterar="
            hadPermission(certificadoDigitalPermissionId, alterActionId)
          "
          :deletar="
            hadPermission(certificadoDigitalPermissionId, deleteActionId)
          "
          mostra-acoes
        >
          <template v-slot:legenda="{ item }">
            <div>
              <v-chip color="grey-lighten-2" class="mr-2 chip" small>
                <v-icon left size="15">
                  {{
                    item.usaNfe
                      ? "mdi-file-document-check"
                      : "mdi-file-document-remove"
                  }}
                </v-icon>

                {{ item.usaNfe ? "Utiliza NFe" : "Não utiliza NFe" }}
              </v-chip>

              <v-chip color="grey-lighten-2" class="mr-2 chip" small>
                <v-icon left size="15">
                  {{
                    item.usaCte
                      ? "mdi-file-document-check"
                      : "mdi-file-document-remove"
                  }}
                </v-icon>

                {{ item.usaCte ? "Utiliza CTe" : "Não utiliza CTe" }}
              </v-chip>
            </div>
          </template>
        </dev-cards-list>
      </v-col>
    </v-row>

    <dev-dialog
      :dialog="controller.dialogCertificadoDigital"
      titulo="Cadastro"
      @cancelar="controller.cancelarCertificadoDigital($refs.form)"
      @salvar="controller.salvarCertificadoDigital($refs.form)"
    >
      <template v-slot:form>
        <v-form ref="form" :disabled="controller.carregandoCertificadoDigital">
          <v-autocomplete
            :items="controller.clientePaginacao.itens"
            v-model="controller.certificadoDigital.idCliente"
            label="Cliente"
            item-text="nome"
            item-value="id"
            readonly
            outlined
            rounded
            dense
          ></v-autocomplete>

          <v-row>
            <v-col>
              <v-switch
                inset
                dense
                label="Usa NFE"
                v-model="controller.certificadoDigital.usaNfe"
              ></v-switch>
            </v-col>
            <v-col>
              <v-switch
                inset
                dense
                label="Usa CTE"
                v-model="controller.certificadoDigital.usaCte"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </dev-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    await this.controller.buscaCertificadosDigitaisCliente(
      this.controller.clienteSelecionado
    );
  },
};
</script>
