import Handler from "@/core/domain/usecase/handler"

const criaProxyRepository = (axios) => async (proxy) => {
    try {
        await axios.post("/proxy", proxy)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default criaProxyRepository