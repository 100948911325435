class BancoDados {
  constructor({ id = 0, idBancoDeDados = 0, idCliente = 0, idProduto = 0 }) {
    this.id = id;
    this.idBancoDeDados = idBancoDeDados;
    this.idCliente = idCliente;
    this.idProduto = idProduto;
  }

  toSave(idCliente) {
    const payload = { ...this, idCliente };

    if (!payload.id) {
      delete payload.id;
    }

    return payload;
  }
}

export default BancoDados;
