export const colunas = [
  {
    text: "Produto",
    value: "produto",
  },
  {
    text: "Cliente",
    value: "nomeCliente",
  },
  {
    text: "Chave cliente",
    value: "chaveCliente",
  },
  {
    text: "Autenticado",
    value: "autenticado",
    custom: true,
    align: "center",
  },
  {
    text: "Ativo",
    value: "ativo",
    custom: true,
    align: "center",
  },
  {
    text: "URL Proxy",
    value: "url",
    custom: true,
  },
  {
    text: "Pacific",
    value: "pacific",
    custom: true,
  },
  {
    text: "URL Front",
    value: "urlFrontend",
    custom: true,
  },
];
