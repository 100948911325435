<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          :items="controller.clientePaginacao.itens"
          v-model="controller.clientes"
          label="Cliente"
          item-text="nome"
          item-value="id"
          readonly
          multiple
          dense
          rounded
          outlined
          chips
          small-chips
          @change="controller.buscaPrograsClienteSelecionado()"
        ></v-autocomplete>
        <div
          class="red--text font-weight-bold pb-5 text-center"
          v-if="controller.clientes.length > 1"
        >
          Os programas selecionados abaixo somente serão adicionados aos
          clientes, não havendo opção de remover programas. Para isso selecione
          somente um único cliente.
        </div>
        <v-treeview
          v-if="controller.clientes.length > 0"
          v-model="controller.programas"
          :items="controller.modulos"
          selected-color="primary"
          item-text="nomeComposto"
          item-key="codMenu"
          selectable
        ></v-treeview>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
