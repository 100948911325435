import { defaultPaginacao } from "@/core/domain/model/paginacao";
import _ from "lodash";

import { snackbar } from "@/core/const/devSnackbar";
import { alert } from "@/core/const/devAlert";

import { colunas } from "../const/colunas";
import { defaultProxy } from "../domain/model/proxy";

class ProxyController {
  dialog = false;
  colunas = colunas;
  proxiesPaginacao = defaultPaginacao();
  clientePaginacao = defaultPaginacao();
  produtoPaginacao = defaultPaginacao();
  proxy = defaultProxy();
  paginacao = {};
  loading = false;

  constructor(
    context,
    buscaProxiesUseCase,
    buscaClientesUseCase,
    buscaProdutosUseCase,
    criaProxyUseCase,
    alteraProxyUseCase,
    excluiProxyUseCase
  ) {
    this.context = context;
    this.buscaProxiesUseCase = buscaProxiesUseCase;
    this.buscaClientesUseCase = buscaClientesUseCase;
    this.buscaProdutosUseCase = buscaProdutosUseCase;
    this.criaProxyUseCase = criaProxyUseCase;
    this.alteraProxyUseCase = alteraProxyUseCase;
    this.excluiProxyUseCase = excluiProxyUseCase;
  }

  async mounted(paginacao) {
    try {
      this.loading = true
      this.paginacao = paginacao;
      this.proxiesPaginacao = await this.buscaProxiesUseCase({ ...paginacao });
    } catch (error) {
      // snackbar.show({message: error})
    } finally {
      this.loading = false
    }
  }

  async atualizar() {
    try {
      this.loading = true
      this.proxiesPaginacao = await this.buscaProxiesUseCase(this.paginacao);
    } finally {
      this.loading = false
    }
  }

  async adicionarRegistro() {
    try {
      this.proxy = {};
      await Promise.all([this.buscaClientes(null), this.buscaProdutos(null)]);
      this.dialog = true;
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  async alterarRegistro(item) {
    try {
      const currentItem = this.proxiesPaginacao.itens.find(
        (i) => i.id === item.id
      );

      await Promise.all([
        this.buscaClientes(item.nomeCliente),
        this.buscaProdutos(item.nomeProduto),
      ]);

      this.proxy = {
        ...item,
        ...currentItem,
        urlFrontend: item?.urlFrontend?.join(","),
      };

      this.dialog = true;
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  cancelar() {
    this.proxy = defaultProxy();
    this.context.$refs.form.resetValidation();
    this.dialog = false;
  }

  linhaSelecionada(item) {
    this.proxy = { ...item[0], urlFrontend: item[0]?.urlFrontend?.join(",") };
  }

  buscaClientesDebounce = _.debounce(async (search) => {
    this.buscaClientes(search);
  }, 500);

  buscaProdutosDebounce = _.debounce(async (search) => {
    this.buscaProdutos(search);
  }, 500);

  async buscaClientes(search) {
    this.clientePaginacao = await this.buscaClientesUseCase({
      itemsPerPage: 10,
      page: 1,
      search: search,
      sortBy: ["nome"],
      sortDesc: [false],
      searchFields: "nome",
    });
  }

  async buscaProdutos(search) {
    this.produtoPaginacao = await this.buscaProdutosUseCase({
      itemsPerPage: 10,
      page: 1,
      search: search,
      sortBy: ["nome"],
      sortDesc: [false],
      searchFields: "nome",
    });
  }

  async salvar() {
    try {
      if (this.context.$refs.form.validate()) {
        if (this.proxy.id === 0) {
          await this.criaProxyUseCase(this.proxy);
        }

        if (this.proxy.id > 0) {
          await this.alteraProxyUseCase(this.proxy);
        }

        snackbar.show({
          message: "Registro salvo com sucesso!",
          color: "green",
          timeout: 3000,
        });

        this.cancelar();
        this.mounted(this.paginacao);
      }
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  async excluirRegistro(item) {
    try {
      const deletar = await alert.confirm({
        message: "Deseja realmente excluir o registro?",
        color: "red",
      });

      if (deletar) {
        await this.excluiProxyUseCase(item.id);
      }

      this.mounted(this.paginacao);
    } catch (error) {
      // snackbar.show({message: error})
    }
  }
}

export default ProxyController;
