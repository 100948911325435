<template>
  <div>
    <v-dialog
      v-model="alert.dialog"
      max-width="500"
      persistent
      content-class="rounded-xl"
    >
      <v-card
        :color="alert.color"
        class="white--color"
      >
        <v-card-title class="white--text">
          Aviso!
        </v-card-title>
        <v-card-text
          class="white--text"
          v-html="alert.message"
        >
        <!-- {{alert.message}} -->
        </v-card-text>
        <v-card-actions
          v-if="alert.actions.accept"
        >
          <v-btn
            class="white--text"
            @click="alert.decline()"
            text
          >Não</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            @click="alert.accept()"
            text
          >Sim</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn 
            class="white--text"
            @click="alert.decline()"
            text
          >OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  data: () => ({
    alert: Vue.prototype.devAlert
  })
}
</script>