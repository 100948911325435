export const colunas = [
  {
    text: "Nome",
    value: "nome",
  },
  {
    text: "Firebase",
    value: "firebase",
  },
  {
    text: "API Token",
    value: "apiToken",
    align: "end",
    custom: true,
    sortable: false,
  },
];
