<template>
  <v-card elevation="0" class="rounded-xl">
    <v-card-text>
      <v-data-table
        multi-sort
        v-model="selectedItems"
        :height="height"
        :hide-default-footer="!mostraPaginacao"
        :disable-pagination="!mostraPaginacao"
        :hide-default-header="!mostraColunas"
        :server-items-length="paginacaoServidor ? totalItens : undefined"
        :fixed-header="colunasFixas"
        :items="linhas"
        :headers="visibleColumns"
        :loading="carregar"
        :loading-text="textoCarregando"
        :item-class="rowClass"
        :search="search"
        @click:row="clickRow"
        :footer-props="{
          itemsPerPageOptions: [30, 100, 200],
          itemsPerPageText: 'Linhas por página',
        }"
        :group-by="agruparPor"
        :items-per-page="100"
        :show-select="selecionarVarios"
        :item-key="chaveTabela"
        :sort-by="options.sortBy"
        :sort-desc="options.sortDesc"
        :options.sync="paginacaoServidor ? options : {}"
        @update:options="groupBy"
        dense
      >
        <template v-slot:no-data>
          <v-col cols="12">
            <v-row align="center" justify="center">Sem dados</v-row>
          </v-col>
        </template>

        <template v-slot:header.data-table-select="{ props, on }">
          <v-simple-checkbox
            :value="props.value"
            :indeterminate="props.indeterminate"
            :disabled="carregar"
            @input="on.input($event)"
          />
        </template>

        <template v-slot:top>
          <div
            v-if="mostraToolbar"
            class="d-flex flex-wrap justify-space-evenly align-content-space-between"
          >
            <div>
              <v-toolbar-title class="mr-5">{{ nomeTabela }}</v-toolbar-title>
            </div>

            <div class="d-flex">
              <v-text-field
                label="Pesquisar"
                style="max-width: 250px"
                prepend-inner-icon="mdi-magnify"
                color="#FD6400"
                v-model="search"
                v-if="mostraPesquisa"
                @input="debounce"
                rounded
                dense
                solo-inverted
                flat
                class="elevation-0"
                hide-details
              />

              <v-tooltip bottom>
                <template v-slot:activator="{ on: onTooltip }">
                  <v-menu :close-on-content-click="false" :nudge-width="200">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="mostraPaginacao"
                        v-on="{ ...on, ...onTooltip }"
                        v-bind="attrs"
                      >
                        mdi-dots-vertical
                      </v-icon>
                    </template>

                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Filtrar por</v-list-item-title>
                            <v-list-item-subtitle>
                              Campos disponíveis:
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-divider></v-divider>

                      <v-list>
                        <v-list-item
                          v-for="(campo, index) in camposFiltroInterno"
                          :key="index"
                        >
                          <v-list-item-action>
                            <v-switch
                              v-model="campo.enabled"
                              inset
                              dense
                              hide-details
                            />
                          </v-list-item-action>

                          <v-list-item-title>
                            {{ campo.nome }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </template>
                <span>Filtrar por</span>
              </v-tooltip>
            </div>

            <v-spacer></v-spacer>

            <div class="ml-3 d-flex">
              <slot name="botoes.prepend"></slot>

              <v-tooltip bottom v-if="mostraDetalhes && acoes">
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    color="grey lighten-4"
                    fab
                    elevation="0"
                    class="mx-2 px-2"
                    v-on="on"
                    @click="clickDetails()"
                    :disabled="carregar"
                  >
                    <v-icon color="green">mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver detalhes</span>
              </v-tooltip>
              <v-tooltip bottom v-if="alterar && acoes">
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    elevation="0"
                    class="mx-2 px-2"
                    color="grey lighten-4"
                    fab
                    v-on="on"
                    @click="clickEdit()"
                    :disabled="carregar"
                  >
                    <v-icon color="primary">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Alterar registro</span>
              </v-tooltip>
              <v-tooltip bottom v-if="deletar && acoes">
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    elevation="0"
                    class="mx-2 px-2"
                    color="grey lighten-4"
                    fab
                    v-on="on"
                    @click="clickDel()"
                    :disabled="carregar"
                  >
                    <v-icon color="red">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Excluir registro</span>
              </v-tooltip>

              <v-tooltip bottom v-if="limparSelecao && selectedRows.length > 1">
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    elevation="0"
                    class="mx-2 px-2"
                    color="grey lighten-4"
                    fab
                    v-on="on"
                    @click="clickRemoveSelection()"
                    :disabled="carregar"
                  >
                    <v-icon color="primary">mdi-selection-remove</v-icon>
                  </v-btn>
                </template>
                <span>Remover seleção</span>
              </v-tooltip>
              <div class="mx-2">
                <slot name="botoes"></slot>
              </div>
            </div>
          </div>
        </template>

        <template
          v-for="coluna in customColunas"
          v-slot:[`item.${coluna.value}`]="{ item }"
        >
          <slot :name="coluna.value" v-bind:item="item"> </slot>
        </template>

        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            :disabled="carregar"
            @input="select($event)"
          />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: (vm) => ({
    search: vm.pesquisa,
    visibleColumns: [],
    debounceSearch: null,
    menu: false,
    acoes: false,
    camposFiltroInterno: [],
    selectedRows: [],
    selectedItems: vm.value,
    options: {
      page: 1,
      itemsPerPage: 30,
      sortBy: vm.$props.sortBy ?? [],
      sortDesc: vm.$props.sortDesc ?? [],
      groupBy: [],
      groupDesc: [],
    },
  }),

  mounted() {
    this.ajustaCols();
  },

  created() {
    this.debounceSearch = this.debounce(this.updateSearch, 500);
    this.debouncePagination = this.debounce(this.updatePagination, 500);
  },

  computed: {
    customPesquisa() {
      return !!this.$slots["pesquisa"];
    },
    customColunas() {
      return this.colunas.filter((coluna) => coluna?.custom ?? false);
    },
    height() {
      if (this.altura) {
        return this.altura;
      }

      if (this.mostraPaginacao) {
        return "calc(100vh - 250px)";
      }

      return "calc(100vh - 190px)";
    },
  },

  watch: {
    camposFiltroInterno: {
      handler: function () {
        if (this.search) {
          this.adicionaFields();
          this.$emit("paginando", this.options);
        }
      },
      deep: true,
    },

    pesquisa() {
      this.search = this.pesquisa;
    },

    search() {
      this.debounceSearch();
    },

    options(n, o) {
      if (!(JSON.stringify(n) === JSON.stringify(o))) {
        this.adicionaFields();
        n["search"] = this.search;
        this.$emit("paginando", n);
      }
    },

    colunas() {
      this.ajustaCols();
    },

    mostraAcoes(mostra) {
      this.acoes = mostra;
    },

    selectedItems() {
      this.$emit("input", this.selectedItems);
    },

    value() {
      this.selectedItems = this.value;
    },

    linhas(newValue, oldValue) {
      if (newValue?.length < oldValue?.length && this.selectedRows?.length) {
        this.acoes = false;
        this.selectedRows = [];
      }
    },
  },

  methods: {
    adicionaFields() {
      this.options["searchFields"] = this.camposFiltroInterno
        .filter((campo) => campo.enabled)
        .map((campo) => campo.chave)
        .join(",");
    },
    ajustaCols() {
      this.visibleColumns = this.colunas;

      this.camposFiltroInterno = this.colunas
        .filter((coluna) => coluna.value !== "acoes")
        .map((coluna) => ({
          nome: coluna.text,
          chave: coluna.value,
          enabled: true,
        }));
    },

    groupBy(val) {
      this.options = val;
      this.options["search"] = this.search;

      if (this.agruparPor != null) {
        if (!this.options.sortBy.some((item) => item === this.agruparPor)) {
          this.options.sortBy.unshift(this.agruparPor);
          this.options.sortDesc.unshift(false);
        }
      }
      this.debouncePagination();
    },

    rowClass(item) {
      if (item.color) {
        return item.color;
      }

      if (this.selectedRows.find((row) => row.id === item.id)) {
        return "orange lighten-5";
      }

      return "";
    },

    clickRow(item) {
      if (this.selecaoMultipla) {
        if (!this.selectedRows.find((row) => row.id === item.id)) {
          this.selectedRows.push(item);
        } else {
          const index = this.selectedRows.findIndex(
            (row) => row.id === item.id
          );
          this.selectedRows.splice(index, 1);
        }
      } else {
        this.selectedRows = [item];
      }

      this.acoes = this.selectedRows.length === 1;
      this.$emit("linhas-selecionadas", this.selectedRows);
    },

    debounce(func, wait) {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(func, wait);
      };
    },

    updateSearch() {
      this.adicionaFields();
      this.options["search"] = this.search;
      this.$emit("paginando", this.options);
    },

    updatePagination() {
      this.options["search"] = this.search;
    },

    clickEdit() {
      this.$emit("alterar-item", this.selectedRows[0]);
    },

    clickDetails() {
      this.$emit("ver-detalhes", this.selectedRows[0]);
    },

    clickDel() {
      this.$emit("deletar-item", this.selectedRows[0]);
    },

    clickRemoveSelection() {
      this.selectedRows = [];
      this.$emit("linhas-selecionadas", this.selectedRows);
    },
  },
  props: {
    colunas: {
      type: Array,
      default: () => undefined,
      required: true,
    },
    linhas: {
      type: Array,
      default: () => undefined,
      required: true,
    },
    carregar: {
      type: Boolean,
      default: () => false,
    },
    alterar: {
      type: Boolean,
      default: () => true,
    },
    deletar: {
      type: Boolean,
      default: () => true,
    },
    paginacaoServidor: {
      type: Boolean,
      default: () => true,
    },
    "limpar-selecao": {
      type: Boolean,
      default: () => false,
    },
    paginacao: {
      type: Object,
      default: () => {},
    },
    pesquisa: {
      type: Object,
      default: () => null,
    },
    "texto-carregando": {
      type: String,
      default: () => "",
    },
    "mostra-paginacao": {
      type: Boolean,
      default: () => true,
    },
    "mostra-pesquisa": {
      type: Boolean,
      default: () => false,
    },
    "mostra-detalhes": {
      type: Boolean,
      default: () => false,
    },
    "mostra-toolbar": {
      type: Boolean,
      default: () => true,
    },
    "mostra-acoes": {
      type: Boolean,
      default: () => false,
    },
    "nome-tabela": {
      type: String,
      default: () => "",
    },
    "mostra-colunas": {
      type: Boolean,
      default: () => true,
    },
    "colunas-fixas": {
      type: Boolean,
      default: () => true,
    },
    "total-itens": {
      type: Number,
      default: () => 30,
    },
    "agrupar-por": {
      type: String,
      default: () => null,
    },
    "selecao-multipla": {
      type: Boolean,
      default: () => false,
    },
    altura: {
      type: String,
      default: () => null,
    },
    "selecionar-varios": {
      type: Boolean,
      default: () => false,
    },
    "chave-tabela": {
      type: String,
      default: () => "id",
    },
    "sort-by": {
      type: Array,
      default: () => null,
    },
    "sort-desc": {
      type: Array,
      default: () => null,
    },
    "paginacao-servidor": {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
