import Paginacao from "@/core/domain/model/paginacao";
import Handler from "@/core/domain/usecase/handler";
import LogsApps from "../../domain/model/logsApp";

const buscaLogsAppRepository = (axios) => async (paginacao) => {
  try  {
    const response = await axios.get('/logs/gateway', {
      params: paginacao
    })

    return new Paginacao(response.data, LogsApps)
  } catch (error) {
    throw new Handler().trataErro(error)
  }
}

export default buscaLogsAppRepository