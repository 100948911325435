<template>
  <v-dialog
    v-model="controller.dialogPreviewLog"
    width="1024"
    content-class="rounded-xl"
    scrollable
  >
    <v-card>
      <v-card-title class="font-weight-bold"> Informações </v-card-title>

      <v-divider />

      <v-card-text class="py-5">
        <pre><code class="language-txt pa-5 rounded-xl" style="font-size: 10px"  v-html="controller.logAuditoria.header"></code></pre>

        <v-divider class="mt-5 mb-5"></v-divider>

          <pre><code class="language-txt pa-5 rounded-xl" style="font-size: 10px" v-html="controller.logAuditoria.body"></code></pre>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-start">
        <v-btn
          class="font-weight-bold"
          color="red"
          elevation="0"
          @click="controller.cancelaDialogPreviewLog()"
          outlined
          rounded
        >
          <v-icon left>mdi-close</v-icon> Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
code {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
  background-color: #212121 !important;
  color: #fff !important;
  font-size: 10px;
}
</style>
