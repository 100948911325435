<template>
  <div>
    <cadastro :controller="controller" ref="form" />
  </div>
</template>

<script>
import { aplicacaoDeltaController } from "../di/di";

import cadastro from "../components/cadastro.vue";

export default {
  components: { cadastro },
  data: (context) => ({
    controller: aplicacaoDeltaController(context),
  }),
  async mounted() {
    await this.controller.mounted();
  },
};
</script>

<style scoped>
.save-btn {
  background: linear-gradient(to right, #ffa64d, #fd6400);
  color: #121934;
}
</style>
