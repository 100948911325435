import Paginacao from "@/core/domain/model/paginacao";
import Handler from "@/core/domain/usecase/handler";
import CertificadoDigital from "../../domain/model/certificadoDigital";

const buscaCertificadoDigitalRepository = (axios) => async (paginacao) => {
  try {
    const response = await axios.get("/certificado-digital", {
      params: paginacao,
    });

    return new Paginacao(response.data, CertificadoDigital);
  } catch (error) {
    throw new Handler().trataErro(error);
  }
};

export default buscaCertificadoDigitalRepository;
