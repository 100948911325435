class BancoDados {
  constructor({ id = 0, nome = "" }) {
    this.id = id;
    this.nome = nome;
  }

  toSave() {
    const payload = { ...this };

    payload.nome = payload.nome.trim();

    if (!payload.id) {
      delete payload.id;
    }

    return payload;
  }
}

export default BancoDados;
