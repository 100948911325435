class DeltaPaginacao {
  constructor({ itens = [], total = 0 }) {
    this.itens = this.normalize(itens);
    this.total = total;
  }

  normalize(items) {
    return items.map((item) => {
      const numDelta = this.padStart(item.numDelta, 3);
      const numPatch = this.padStart(item.numPatch, 4);
      const nome = `${numDelta}/${numPatch}`;

      return { ...item, nome };
    });
  }

  padStart(value, length, fillStr = "0") {
    return String(value).padStart(length, fillStr);
  }
}

export default DeltaPaginacao;
