<template>
  <div>
    <dev-tabela
      nome-tabela="Projetos do firebase"
      :linhas="controller.projetosPaginacao.itens"
      :colunas="controller.colunas"
      mostra-acoes
      @paginando="controller.mounted($event)"
      @alterar-item="controller.alterarRegistro($event)"
      @deletar-item="controller.excluirRegistro($event)"
      @linhas-selecionadas="controller.linhaSelecionada($event)"
      :total-itens="controller.projetosPaginacao.total"
      :alterar="hadPermission(projetoPermissionId, alterActionId)"
      :deletar="hadPermission(projetoPermissionId, deleteActionId)"
      mostra-pesquisa
      :carregar="controller.carregando"
    >
      <template v-slot:apiToken="{ item }">
        <v-btn
          text
          icon
          color="primary"
          @click="controller.copiarToken(item.apiToken)"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">
                mdi-content-copy
              </v-icon>
            </template>
            <span>Copiar Token</span>
          </v-tooltip>
        </v-btn>
      </template>
      <template v-slot:autenticado="{ item }">
        <div class="d-flex justify-center">
          <v-checkbox
            dense
            hide-details
            v-model="item.autenticado"
            readonly
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:botoes>
        <v-row>
          <v-col>
            <div class="mr-2">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="controller.carregando"
                    max-height="36"
                    max-width="36"
                    v-on="on"
                    v-bind="attrs"
                    icon
                  >
                    <v-icon @click="controller.atualizar()">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Recarregar</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col>
            <dev-btn
              incluir
              v-if="hadPermission(projetoPermissionId, createActionId)"
              @click="controller.adicionarRegistro()"
            ></dev-btn>
          </v-col>
        </v-row>
      </template>

      <template v-slot:url="{ item }">
        <v-chip :color="item.idProduto === 1 ? 'primary' : 'orange'" small>{{
          item.url
        }}</v-chip>
      </template>
      <template v-slot:pacific="{ item }">
        <v-chip :color="item.idProduto === 1 ? 'primary' : 'orange'" small>{{
          item.pacific
        }}</v-chip>
      </template>
      <template v-slot:urlFrontend="{ item }">
        <v-chip
          v-for="(i, index) in item.urlFrontend"
          :key="index"
          :color="item.idProduto === 1 ? 'primary' : 'orange'"
          small
          >{{ i }}</v-chip
        >
      </template>
    </dev-tabela>

    <dev-dialog
      :dialog="controller.dialog"
      titulo="Cadastro"
      @cancelar="controller.cancelar()"
      @salvar="controller.salvar()"
    >
      <template v-slot:form>
        <v-form ref="form">
          <v-text-field
            outlined
            rounded
            dense
            label="Nome"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model="controller.projetoFirebase.nome"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Firebase"
            v-model="controller.projetoFirebase.firebase"
          ></v-text-field>
        </v-form>
      </template>
    </dev-dialog>
  </div>
</template>

<script>
import { projetoFirebaseController } from "@/di/di";
export default {
  name: "ProjetoFirebase",
  data: (context) => ({
    controller: projetoFirebaseController(context),
  }),
};
</script>
