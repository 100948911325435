<template>
  <v-container fluid class="dialog-detalhes-bg">
    <v-row dense>
      <v-col class="d-flex justify-end">
        <dev-btn
          incluir
          v-if="hadPermission(proxyPermissionId, createActionId)"
          @click="controller.adicionarProxy()"
        ></dev-btn>
      </v-col>
    </v-row>

    <v-row
      dense
      v-if="
        controller.proxies.admrh.items.length ||
        controller.proxies.consultors.items.length
      "
    >
      <v-col
        lg="6"
        sm="12"
        v-for="proxy in controller.proxies"
        :key="proxy.label"
        v-if="proxy.items.length"
      >
        <dev-cards-list
          :titulo="`Produto: ${proxy.label}`"
          :items="proxy.items"
          :configuracao="controller.detalhes.configuracaoProxy"
          @alterar-item="controller.alterarProxy($event)"
          @deletar-item="controller.excluirProxy($event)"
          :alterar="hadPermission(proxyPermissionId, alterActionId)"
          :deletar="hadPermission(proxyPermissionId, deleteActionId)"
          :carregar="controller.carregandoProxy"
          mostra-acoes
        >
          <template v-slot:legenda="{ item }">
            <div>
              <v-chip color="grey-lighten-2" class="mr-2 chip" small>
                <v-icon left size="15">
                  {{
                    item.autenticado
                      ? "mdi-lock-outline"
                      : "mdi-lock-open-outline"
                  }}
                </v-icon>
                {{ item.autenticado ? "Autenticado" : "Não autenticado" }}
              </v-chip>

              <v-chip
                :color="item.ativo ? 'green lighten-2' : 'red lighten-2'"
                class="mr-2 chip"
                small
              >
                {{ item.ativo ? "Ativo" : "Desativado" }}
              </v-chip>
            </div>
          </template>

          <template v-slot:url="{ item }">
            <div>
              <span class="my-1">
                <b>URL Proxy: </b>
              </span>
              <v-chip
                :color="item.idProduto === 1 ? 'primary' : 'orange'"
                class="ma-1 chip"
                small
              >
                {{ item.url }}
              </v-chip>
            </div>
          </template>

          <template v-slot:pacific="{ item }">
            <div>
              <span class="my-1">
                <b>Pacific: </b>
              </span>
              <v-chip
                :color="item.idProduto === 1 ? 'primary' : 'orange'"
                class="ma-1 chip"
                small
              >
                {{ item.pacific }}
              </v-chip>
            </div>
          </template>

          <template v-slot:urlFrontend="{ item }">
            <div>
              <span class="my-1">
                <b>URL Front: </b>
              </span>
              <v-chip
                v-for="i in item.urlFrontend"
                :key="i"
                :color="item.idProduto === 1 ? 'primary' : 'orange'"
                class="ma-1 chip"
                small
              >
                {{ i }}
              </v-chip>

              <v-chip
                v-show="!item.urlFrontend.length"
                :color="item.idProduto === 1 ? 'primary' : 'orange'"
                class="ma-1 chip"
                small
              >
                Sem URL
              </v-chip>
            </div>
          </template>
        </dev-cards-list>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <div
          class="d-flex flex-column justify-center align-center font-weight-bold headline"
        >
          <div>Nenhum proxy</div>
          <dev-lottie
            class="mt-10"
            :options="controller.animacao"
            :height="300"
            :width="400"
          />
        </div>
      </v-col>
    </v-row>

    <dev-dialog
      titulo="Cadastro de proxy"
      :dialog="controller.dialogProxy"
      @cancelar="controller.cancelarProxy($refs.form)"
      @salvar="controller.salvarProxy($refs.form)"
    >
      <template v-slot:form>
        <v-form ref="form" :disabled="controller.carregandoProxy">
          <v-switch
            class="pb-4"
            inset
            label="Autenticado"
            dense
            hide-details
            v-model="controller.proxy.autenticado"
          ></v-switch>

          <v-switch
            class="pb-4"
            inset
            label="Ativo"
            dense
            hide-details
            v-model="controller.proxy.ativo"
          ></v-switch>

          <v-autocomplete
            rounded
            outlined
            label="Produto"
            :items="controller.produtoPaginacao.itens"
            item-text="nome"
            item-value="id"
            @update:search-input="controller.buscaProdutosDebounce($event)"
            :rules="[controller.rules.obrigatorio]"
            dense
            v-model="controller.proxy.idProduto"
          ></v-autocomplete>

          <v-text-field
            outlined
            rounded
            dense
            label="URL"
            :rules="[controller.rules.obrigatorio, controller.rules.subdominio]"
            v-model="controller.proxy.url"
            :prefix="controller.montaPrefixoUrl()"
            :disabled="!controller.proxy.idProduto"
            suffix=".cgisoftware.com.br"
          ></v-text-field>

          <v-text-field
            outlined
            rounded
            dense
            label="URL Frontend"
            v-model="controller.proxy.urlFrontend"
            :rules="[controller.rules.subdominio]"
            :disabled="!controller.proxy.idProduto"
            prefix="https://"
            suffix=".cgisoftware.com.br"
            hint="Para cadastrar multiplas urls, separe o subdomínio uma por vírgula seguida de espaço."
            persistent-hint
          >
          </v-text-field>

          <v-text-field
            outlined
            rounded
            dense
            label="Pacific"
            prefix="http://"
            v-model="controller.proxy.pacific"
            :rules="[controller.rules.obrigatorio, controller.rules.pacific]"
            :disabled="!controller.proxy.idProduto"
          ></v-text-field>
        </v-form>
      </template>
    </dev-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.chip {
  width: fit-content;
}

code {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
}
:deep(.token.punctuation) {
  color: #ffffff;
}
</style>
