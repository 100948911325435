import { defaultPaginacao } from "@/core/domain/model/paginacao"
import { colunas } from "../const/colunas"
import { defaultProjetoFirebase } from "../domain/model/projetoFirebase"
import _ from 'lodash'
import { snackbar } from "@/core/const/devSnackbar"
import { alert } from "@/core/const/devAlert"

class ProjetoFirebaseController {
    dialog = false
    colunas = colunas
    carregando = false;
    projetosPaginacao = defaultPaginacao()
    projetoFirebase = defaultProjetoFirebase()
    paginacao = {}

    constructor(
        context,
        buscaPorjetosUseCase,
        criaProjetoFirebaseUseCase,
        alteraProjetoFirebaseUseCase,
        excluiProjetoFirebaseUseCase
    ) {
        this.context = context
        this.buscaPorjetosUseCase = buscaPorjetosUseCase
        this.criaProjetoFirebaseUseCase = criaProjetoFirebaseUseCase
        this.alteraProjetoFirebaseUseCase = alteraProjetoFirebaseUseCase
        this.excluiProjetoFirebaseUseCase = excluiProjetoFirebaseUseCase
    }

    async mounted(paginacao) {
        try {
            this.carregando = true
            this.paginacao = paginacao;
            this.projetosPaginacao = await this.buscaPorjetosUseCase(paginacao)
        } finally {
            this.carregando = false
        }
    }

    async atualizar() {
        try {
            this.carregando = true;
            this.projetosPaginacao = await this.buscaPorjetosUseCase(this.paginacao);
        } finally {
            this.carregando = false
        }
    }

    async adicionarRegistro() {
        try {
            this.dialog = true
        } catch (error) {
            // snackbar.show({message: error})
        }
    }

    async alterarRegistro(item) {
        try {
            this.projetoFirebase = { ...item }
            this.dialog = true
        } catch (error) {
            // snackbar.show({message: error})
        }
    }

    cancelar() {
        this.projetoFirebase = defaultProjetoFirebase()
        this.context.$refs.form.resetValidation()
        this.dialog = false
    }


    async salvar() {
        try {
            if (this.context.$refs.form.validate()) {
                if (this.projetoFirebase.id === 0) {
                    await this.criaProjetoFirebaseUseCase(this.projetoFirebase)
                }

                if (this.projetoFirebase.id > 0) {
                    await this.alteraProjetoFirebaseUseCase(this.projetoFirebase)
                }

                snackbar.show({
                    message: "Registro salvo com sucesso!",
                    color: 'green',
                    timeout: 3000
                })

                this.cancelar()
                this.mounted(this.paginacao)
            }
        } catch (error) {
            // snackbar.show({message: error})
        }
    }

    async copiarToken(event) {
        try {
            await navigator.clipboard.writeText(event);


            snackbar.show({
                message: 'Copiado para a área de transferência!',
                color: 'primary',
                timeout: 3000
            })

        } catch (error) {
            //snackbar.show({message: error})
        }
    }

    async excluirRegistro(item) {
        try {
            const deletar = await alert.confirm({
                message: "Deseja realmente excluir o registro?",
                color: 'red'
            })

            if (deletar) {
                await this.excluiProjetoFirebaseUseCase(item.id)
            }

            this.mounted(this.paginacao)
        } catch (error) {
            // snackbar.show({message: error})
        }
    }

    linhaSelecionada(linha) {
        this.context.$emit('projeto-selecionado', linha[0])
    }
}

export default ProjetoFirebaseController