import { defaultPaginacao } from "@/core/domain/model/paginacao";
import { colunas } from "../const/colunas";
import { defaultCertificadoDigital } from "../domain/model/certificadoDigital";
import _ from "lodash";
import { snackbar } from "@/core/const/devSnackbar";
import { alert } from "@/core/const/devAlert";

class CertificadoDigitalController {
  dialog = false;
  colunas = colunas;
  certificadoDigitalPaginacao = defaultPaginacao();
  clientePaginacao = defaultPaginacao();
  certificadoDigital = defaultCertificadoDigital();
  carregando = false;
  paginacao = {}

  constructor(
    context,
    buscaCertificadoDigitalUseCase,
    criaCertificadoDigitalUseCase,
    alteraCertificadoDigitalUseCase,
    excluiCertificadoDigitalUseCase,
    buscaClientesUseCase
  ) {
    this.context = context;
    this.buscaCertificadoDigitalUseCase = buscaCertificadoDigitalUseCase;
    this.criaCertificadoDigitalUseCase = criaCertificadoDigitalUseCase;
    this.alteraCertificadoDigitalUseCase = alteraCertificadoDigitalUseCase;
    this.excluiCertificadoDigitalUseCase = excluiCertificadoDigitalUseCase;
    this.buscaClientesUseCase = buscaClientesUseCase;
  }

  async mounted(paginacao) {
    try {
      this.carregando = true;
      this.paginacao = paginacao;
      this.certificadoDigitalPaginacao =
        await this.buscaCertificadoDigitalUseCase(paginacao);
    } catch (error) {
      // snackbar.show({message: error})
    } finally {
      this.carregando = false;
    }
  }

  async atualizar() {
    try {
      this.carregando = true
      this.certificadoDigitalPaginacao = await this.buscaCertificadoDigitalUseCase(this.paginacao);
    } finally {
      this.carregando = false
    }
  }

  async adicionarRegistro() {
    try {
      await Promise.all([this.buscaClientes(null)]);
      this.dialog = true;
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  async alterarRegistro(item) {
    try {
      this.certificadoDigital = { ...item };
      this.dialog = true;
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  buscaClientesDebounce = _.debounce(async (search) => {
    this.buscaClientes(search);
  }, 500);

  async buscaClientes(search) {
    this.clientePaginacao = await this.buscaClientesUseCase({
      itemsPerPage: 10,
      page: 1,
      search: search,
      sortBy: ["nome"],
      sortDesc: [false],
      searchFields: "nome",
    });
  }

  cancelar() {
    this.certificadoDigital = defaultCertificadoDigital();
    this.context.$refs.form.resetValidation();
    this.dialog = false;
  }

  async salvar() {
    try {
      if (this.context.$refs.form.validate()) {
        if (this.certificadoDigital.id === 0) {
          await this.criaCertificadoDigitalUseCase(this.certificadoDigital);
        }

        if (this.certificadoDigital.id > 0) {
          await this.alteraCertificadoDigitalUseCase(this.certificadoDigital);
        }

        snackbar.show({
          message: "Registro salvo com sucesso!",
          color: "green",
          timeout: 3000,
        });

        this.cancelar();
        this.mounted(this.paginacao);
      }
    } catch (error) {
      // // snackbar.show({message: error})
    }
  }

  async excluirRegistro(item) {
    try {
      const deletar = await alert.confirm({
        message: "Deseja realmente excluir o registro?",
        color: "red",
      });

      if (deletar) {
        await this.excluiCertificadoDigitalUseCase(item.id);
      }

      this.mounted(this.paginacao);
    } catch (error) {
      // // snackbar.show({message: error})
    }
  }
}

export default CertificadoDigitalController;
