import Handler from "@/core/domain/usecase/handler";

const criaProgramasClienteRepository = (axios) => async (programas) => {
  try {
    await axios.post(
      `/cliente/${programas.idCliente}/liberacao-programa-cliente`,
      programas
    );
  } catch (error) {
    new Handler().trataErro(error);
  }
};

export default criaProgramasClienteRepository;
