const buscaStatusServidoresUseCase = (repository) => async (paginacao) => {
  try {
    paginacao["sort"] = paginacao?.sortBy?.join(",") ?? null;
    paginacao["descending"] = paginacao?.sortDesc?.join(",") ?? null;
    return await repository(paginacao);
  } catch (error) {
    throw error;
  }
};

export default buscaStatusServidoresUseCase;
