<template>
  <div>
    <dev-tabela
      nome-tabela="Proxy"
      agrupar-por="produto"
      mostra-pesquisa
      mostra-paginacao
      :carregar="controller.loading"
      :linhas="controller.proxiesPaginacao.itens"
      :colunas="controller.colunas"
      :total-itens="controller.proxiesPaginacao.total"
      :alterar="false"
      :deletar="false"
      @paginando="controller.mounted($event)"
      @linhas-selecionadas="controller.linhaSelecionada($event)"
    >
      <template v-slot:botoes>
        <v-row>
          <v-col>
            <div class="mr-2">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="controller.carregando"
                    max-height="36"
                    max-width="36"
                    v-on="on"
                    v-bind="attrs"
                    icon
                  >
                    <v-icon @click="controller.atualizar()">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Recarregar</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:autenticado="{ item }">
        <div class="d-flex justify-center">
          <v-checkbox
            dense
            hide-details
            v-model="item.autenticado"
            readonly
          ></v-checkbox>
        </div>
      </template>

      <template #ativo="{ item }">
        <div class="d-flex justify-center">
          <v-checkbox
            dense
            hide-details
            v-model="item.ativo"
            readonly
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:url="{ item }">
        <v-chip :color="item.idProduto === 1 ? 'primary' : 'orange'" small>
          {{ item.url }}
        </v-chip>
      </template>

      <template v-slot:pacific="{ item }">
        <v-chip :color="item.idProduto === 1 ? 'primary' : 'orange'" small>
          {{ item.pacific }}
        </v-chip>
      </template>

      <template v-slot:urlFrontend="{ item }">
        <v-chip
          v-for="(i, index) in item.urlFrontend"
          :key="index"
          :color="item.idProduto === 1 ? 'primary' : 'orange'"
          small
        >
          {{ i }}
        </v-chip>
      </template>
    </dev-tabela>

    <dev-dialog
      v-if="controller.dialog"
      :dialog="controller.dialog"
      titulo="Cadastro"
      @cancelar="controller.cancelar()"
      @salvar="controller.salvar()"
    >
      <template v-slot:form>
        <v-form ref="form">
          <v-switch
            class="pb-4"
            inset
            label="Autenticado"
            dense
            hide-details
            v-model="controller.proxy.autenticado"
          ></v-switch>

          <v-switch
            class="pb-4"
            inset
            label="Ativo"
            dense
            hide-details
            v-model="controller.proxy.ativo"
          ></v-switch>

          <v-text-field
            outlined
            rounded
            dense
            label="URL"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model="controller.proxy.url"
          ></v-text-field>

          <v-text-field
            outlined
            rounded
            dense
            label="URL Frontend"
            v-model="controller.proxy.urlFrontend"
          ></v-text-field>

          <v-text-field
            outlined
            rounded
            dense
            label="Pacific"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model="controller.proxy.pacific"
          ></v-text-field>

          <v-autocomplete
            label="Cliente"
            dense
            :items="controller.clientePaginacao.itens"
            rounded
            outlined
            item-text="nome"
            item-value="id"
            @update:search-input="controller.buscaClientesDebounce($event)"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model="controller.proxy.idCliente"
          ></v-autocomplete>

          <v-autocomplete
            rounded
            outlined
            label="Produto"
            :items="controller.produtoPaginacao.itens"
            item-text="nome"
            item-value="id"
            @update:search-input="controller.buscaProdutosDebounce($event)"
            :rules="[(v) => !!v || 'Preencha o campo']"
            dense
            v-model="controller.proxy.idProduto"
          ></v-autocomplete>
        </v-form>
      </template>
    </dev-dialog>
  </div>
</template>

<script>
import { proxyController } from "../di/di";

export default {
  name: "ProxyVue",
  data: (context) => ({
    controller: proxyController(context),
  }),
};
</script>

<style scoped>
code {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
}
:deep(.token.punctuation) {
  color: #ffffff;
}
</style>
