<template>
  <v-navigation-drawer
    v-model="controller.drawer"
    app
    temporary
    class="rounded-xl rounded-l-0"
    v-if="$vuetify.breakpoint.smAndDown"
  >
    <v-list
      nav
      dense
      class="d-flex flex-column"
    >
      <v-list-item-avatar
        class="justify-center align-center"
        tile
      >
        <v-img
          src="/consultors.png"
          contain
        />
      </v-list-item-avatar>
      <v-divider class="my-4 secondary" />

      <v-list-group
        no-action
        sub-group
        v-for="(item, key) in controller.navItens"
        v-show="hadPermission(item.permission, readActionId)"
        :key="key"
        :prepend-icon="item.icon"
        @click="!item.children ? controller.navigate(item.screen) : null"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="(child, i) in item.children"
          :key="i"
          link
          @click="controller.navigate(child.screen)"
          v-show="hadPermission(child.permission, readActionId)"
        >
          <v-list-item-icon>
            <v-icon v-text="child.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="child.text"></v-list-item-title>

        </v-list-item>
      </v-list-group>

      <!-- <v-list-item-group
          v-model="controller.group"
          active-class="primary text--accent-4 white--text"
        >
          <v-list-item
            v-for="(item, key) in controller.navItens"
            v-show="hadPermission(item.permission, readActionId)"
            :key="key"
            @click="controller.navigate(item.screen)"
          >
            <v-list-item-icon>
              <v-icon>
                {{ item.icon.includes("mdi") ? item.icon : "mdi-home" }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>