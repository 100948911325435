class ClientesPorApp {
  constructor({
    id = 0,
    usuario = "",
    senha = "",
    apiToken = "",
    idProjeto = 0,
    idProxy = 0,
    ativo = false,
    moduloMaisUsados = false,
    moduloAvaliacaoUsados = false,
    moduloMaisNegocios = false,
    moduloMaisPedidos = false,
    moduloVisitas = false,
    moduloDocumentos = false,
    qtdDevices = 0,
    qtdDevicesPorUsuario = 0,
    versaoMinima = 0,
    versaoServico = 0,
    proxyURL = "",
    pacificURL = "",
    nomeCliente = "",
    idCliente = 0,
    nomeProjeto = "",
  }) {
    this.id = id;
    this.usuario = usuario;
    this.senha = senha;
    this.apiToken = apiToken;
    this.idProjeto = idProjeto;
    this.idProxy = idProxy;
    this.ativo = ativo;
    this.moduloMaisUsados = moduloMaisUsados;
    this.moduloAvaliacaoUsados = moduloAvaliacaoUsados;
    this.moduloMaisNegocios = moduloMaisNegocios;
    this.moduloMaisPedidos = moduloMaisPedidos;
    this.moduloVisitas = moduloVisitas;
    this.moduloDocumentos = moduloDocumentos;
    this.qtdDevices = qtdDevices;
    this.qtdDevicesPorUsuario = qtdDevicesPorUsuario;
    this.versaoMinima = versaoMinima;
    this.versaoServico = versaoServico;
    this.proxyURL = proxyURL;
    this.pacificURL = pacificURL;
    this.nomeCliente = nomeCliente;
    this.idCliente = idCliente;
    this.nomeProjeto = nomeProjeto;
  }
}

export default ClientesPorApp;
