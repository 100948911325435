<template>
  <v-card width="500">
    <v-card-title> Filtro </v-card-title>

    <v-card-text>
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col cols="12">
            <v-select
              label="Situação Aplicação do Delta"
              v-model="controller.filtro.status.value"
              :items="controller.filtro.status.items"
              outlined
              rounded
              dense
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions class="d-flex align-center justify-space-between">
      <dev-btn cancelar @click="controller.cancelarFiltro()" />
      <dev-btn
        limpar
        :desabilita-limpar="controller.carregando"
        @click="controller.limparFiltro()"
      />
      <dev-btn
        salvar
        :carregando-salvar="controller.carregando"
        @click="controller.filtrar()"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>
