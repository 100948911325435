import { snackbar } from "@/core/const/devSnackbar";
import { alert } from "@/core/const/devAlert";

import BancoDados from "../domain/model/bancoDados";
import { colunas } from "../const/headers";

class BancoDadosController {
  carregando = false;
  dialogForm = false;
  linhas = [];
  colunas = colunas;
  total = 0;
  paginacao = {};
  bancoDados = new BancoDados({});

  constructor(
    context,
    buscaBancosDadosUseCase,
    criaBancoDadosUseCase,
    alteraBancoDadosUseCase,
    deletaBancoDadosUseCase
  ) {
    this.context = context;
    this.buscaBancosDadosUseCase = buscaBancosDadosUseCase;
    this.criaBancoDadosUseCase = criaBancoDadosUseCase;
    this.alteraBancoDadosUseCase = alteraBancoDadosUseCase;
    this.deletaBancoDadosUseCase = deletaBancoDadosUseCase;
  }

  async paginando(paginacao) {
    try {
      this.carregando = true;
      this.paginacao = paginacao ?? this.paginacao;

      const { itens, total } = await this.buscaBancosDadosUseCase(
        this.paginacao
      );

      this.linhas = itens;
      this.total = total;
    } finally {
      this.carregando = false;
    }
  }

  async salvar() {
    try {
      if (!this.validaFormulario()) {
        return;
      }

      this.carregando = true;

      if (this.bancoDados.id) {
        await this.alteraBancoDadosUseCase(this.bancoDados.toSave());
      } else {
        await this.criaBancoDadosUseCase(this.bancoDados.toSave());
      }

      this.cancelar();
      await this.paginando();
    } finally {
      this.carregando = false;
    }
  }

  async deletar({ id }) {
    try {
      const confirma = await alert.confirm({
        message: "Deseja realmente excluír o banco de dados?",
        color: "red",
      });

      if (confirma) {
        this.carregando = true;
        await this.deletaBancoDadosUseCase(id);
        await this.paginando();

        snackbar.show({
          message: "Registro excluído com sucesso!",
          color: "green",
          timeout: 2000,
        });
      }
    } finally {
      this.carregando = false;
    }
  }

  async resetaValidacaoFormulario() {
    await this.context.$nextTick();
    this.context.$refs.form.$refs.cadastro.resetValidation();
  }

  validaFormulario() {
    return this.context.$refs.form.$refs.cadastro.validate();
  }

  incluir() {
    this.dialogForm = true;
    this.bancoDados = new BancoDados({});
  }

  alterar(item) {
    this.dialogForm = true;
    this.bancoDados = new BancoDados(item);
  }

  cancelar() {
    this.dialogForm = false;
    this.bancoDados = new BancoDados({});
    this.resetaValidacaoFormulario();
  }
}

export default BancoDadosController;
