<template>
  <v-app-bar
    fixed
    app
    elevate-on-scroll
    flat
    class="elevation-3 light rounded-xl rounded-t-0 appbar-menu"
    color="white"
  >
    <v-app-bar-nav-icon
      @click.stop="controller.drawer = !controller.drawer"
      v-if="$vuetify.breakpoint.smAndDown"
    />
    <v-img
      src="/consultors.png"
      max-width="45"
      max-height="45"
      contain
      @click="controller.navigate()"
      style="cursor: pointer"
    />
    <v-spacer />
    <v-tabs
      center-active
      class="d-flex justify-center"
      v-model="controller.tabMenu"
      v-if="!$vuetify.breakpoint.smAndDown"
    >
      <template v-for="(item, key) in controller.navItens">
        <v-menu
          v-if="item.children"
          :key="key"
          offset-y
        >
          <template v-slot:activator="{ attrs, on }">
            <v-tab
              v-if="hadPermission(item.permission, readActionId)"
              :key="key"
              :title="item.title"
              text
              plain
              v-on="on"
              v-bind="attrs"
            >
              <v-icon small left>{{item.icon}}</v-icon>{{ item.text }}
            </v-tab>
          </template>

          <v-list>
            <v-list-item
              v-for="(child, index) in item.children"
              :key="index"
              link
              @click="controller.navigate(child.screen)"
              v-show="hadPermission(child.permission, readActionId)"
            >
              <v-list-item-title><v-icon small left>{{child.icon}}</v-icon>{{child.text}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tab
          v-if="hadPermission(item.permission, readActionId) && !item.children"
          :key="key"
          :title="item.title"
          text
          plain
          @click="controller.navigate(item.screen)"
        >
          {{ item.text }}
        </v-tab>

      </template>
    </v-tabs>
    <v-spacer />
    <drop-down-usuario :controller="controller" />
  </v-app-bar>
</template>

<script>
import DropDownUsuario from "./DropDownUsuario.vue";
export default {
  components: { DropDownUsuario },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.appbar-menu {
  margin: auto;
  border-radius: 0 0 10px 10px;
  background-color: white;
  border-bottom: 1px whitesmoke;
}

.v-menu__content {
  border-radius: 23px !important;
}
</style>