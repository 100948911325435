import Prism from "prismjs";
import { snackbar } from "@/core/const/devSnackbar";

import { defaultPaginacao } from "@/core/domain/model/paginacao"
import { defaultAuditoria } from '../domain/model/auditoria'
import { colunas } from "../const/colunas";

class AuditoriaController {
  colunas = colunas
  auditoria = defaultAuditoria()
  auditoriaPaginacao = defaultPaginacao()
  paginacao = {}
  logAuditoria = {
    header: null,
    body: null
  }
  dialogPreviewLog = false;
  carregando = false

  constructor(
    context,
    buscaAuditoriaUseCase,
  ) {
    this.context = context
    this.buscaAuditoriaUseCase = buscaAuditoriaUseCase
  }

  abreDialogPreviewLog(log) {
    if (this.carregando) {
      return;
    }

    try {
      const [pathLog, methodLog, formDataLog] = log.split("\n");
      const pathMethodLog = `${pathLog}\n${methodLog}`;

      this.logAuditoria.header = Prism.highlight(
        pathMethodLog,
        Prism.languages.txt
      );

      this.logAuditoria.body = Prism.highlight(
        formDataLog,
        Prism.languages.txt
      );

    } catch (error) {
      this.logAuditoria = Prism.highlight(log, Prism.languages.txt);
    } finally {
      this.dialogPreviewLog = true;
    }
  }

  cancelaDialogPreviewLog() {
    this.logAuditoria.header = null;
    this.dialogPreviewLog = false;
  }
  async mounted(paginacao) {
    try {
      this.carregando = true
      this.paginacao = paginacao
      this.auditoriaPaginacao = await this.buscaAuditoriaUseCase({ ...paginacao })
    } catch (error) {
      // snackbar.show({message: error})
    } finally {
      this.carregando = false
    }
  }

  async atualizar() {
    try {
      this.carregando = true
      this.auditoriaPaginacao = await this.buscaAuditoriaUseCase(this.paginacao)
    } finally {
      this.carregando = false
    }
  }

}

export default AuditoriaController