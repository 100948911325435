export const colunas = [
  {
    text: "Sigla",
    align: 'left',
    value: 'login',
  },
  {
    text: "E-mail",
    align: 'left',
    value: 'email',
  },
  {
    text: "Grupo usuário",
    align: 'left',
    value: 'grupoUsuario',
  },
]