import Paginacao from "@/core/domain/model/paginacao"
import Handler from "@/core/domain/usecase/handler"
import Produto from "../../domain/model/produto"

const buscaProdutosRepository = (axios) => async (paginacao) => {
    try {
        const response = await axios.get("/produto", {
            params: paginacao
        })

        return new Paginacao(response.data, Produto)
    } catch (error) {
        throw new Handler().trataErro(error)
    }
}

export default buscaProdutosRepository