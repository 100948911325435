var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticStyle:{"height":"calc(100vh - 120px)"}},[_c('v-col',{attrs:{"lg":"4"}},[(_vm.controller.loading)?_c('v-skeleton-loader',_vm._b({staticClass:"rounded-xl",attrs:{"type":"chip"}},'v-skeleton-loader',{
      class: 'mb-6',
      boilerplate: false,
      elevation: 0,
    },false)):_c('div',{staticClass:"font-weight-bold display-1 my-10"},[_vm._v("Backups")]),(_vm.controller.loading)?_c('v-skeleton-loader',_vm._b({staticClass:"rounded-xl",attrs:{"type":"article"}},'v-skeleton-loader',{
      class: 'mb-6',
      boilerplate: false,
      elevation: 0,
    },false)):_vm._e(),(!_vm.controller.loading)?_c('card-backup',{attrs:{"cor":"orange","data-criacao":_vm.dayjs(_vm.controller.postgres.dataCriacao).format('DD/MM/YYYY [às] HH:mm:ss'),"tamanho":_vm.controller.postgres.tamanho,"titulo":"Banco de dados do dev","bucket":_vm.controller.postgres.bucket,"caminho":_vm.controller.postgres.path}}):_vm._e(),(!_vm.controller.loading)?_c('card-backup',{attrs:{"cor":"blue","data-criacao":_vm.dayjs(_vm.controller.report.dataCriacao).format('DD/MM/YYYY [às] HH:mm:ss'),"tamanho":_vm.controller.report.tamanho,"bucket":_vm.controller.report.bucket,"caminho":_vm.controller.report.path,"titulo":"JSReport"}}):_vm._e()],1),_c('v-divider',{staticClass:"ma-4",attrs:{"vertical":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }